import React, { FC, useCallback } from 'react'
import { Container, OptionButton } from './styled'

export type Option = {
  label: string
  value: string
  dataPath?: string
}

type Props = {
  options: Option[]
  currentOption: Option
  textColor?: string
  onPress: (option: Option) => void
}

export const TabNavigation: FC<Props> = (props) => {
  const { options, currentOption, textColor, onPress } = props

  const renderOption = useCallback(
    (option: Option) => {
      const onPressOption = () => {
        onPress(option)
      }
      return (
        <OptionButton
          key={option.value}
          color={textColor}
          selected={currentOption.value === option.value}
          onClick={onPressOption}
        >
          {option.label}
        </OptionButton>
      )
    },
    [onPress, currentOption, textColor]
  )

  return <Container>{options.map(renderOption)}</Container>
}
