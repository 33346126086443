import { ServiceResponse } from '@types'
import axios from 'axios'

export const getStatus = async (transactionID: string): Promise<ServiceResponse> => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_WOMPI_API}/transactions/${transactionID}`)

    return { ok: true, data }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}
