import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

type ButtonProps = {
  customColor?: string
}

export const ButtonContainer = styled.div<ButtonProps>`
  display: flex;
  flex-direction: column;
  margin-left: 21px;
  padding: 10px 0;
  padding-left: 21px;
  border-left: 1px solid ${({ customColor }) => customColor || 'white'};
  @media screen and (max-width: 450px) {
    margin-left: 8px;
    padding-left: 8px;
  }
`

export const ButtonLink = styled.a<ButtonProps>`
  background-color: transparent;
  border: 1px solid ${({ customColor }) => customColor || '#fcd83a'};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${({ customColor }) => customColor || '#ffd465'};
  padding: 12px;
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 18px;
    padding: 10px;
  }
`

export const Container = styled.div<ColorProps>`
  box-sizing: border-box;
  width: 100%;
  padding: 100px 10% 25px;
  color: white;
  background-color: #0a142f;
  ${color};
  @media screen and (max-width: 1024px) {
    padding: 100px 5% 25px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const CustomLink = styled(Link)<ColorProps>`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  color: #ffd465;
  ${color};
  @media screen and (max-width: 450px) {
    font-size: 13px;
    line-height: 15px;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
`

export const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  margin: 0 0 8px;
  color: inherit;
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 15px;
    font-weight: bold;
  }
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  color: inherit;
  margin: 0;
  word-break: break-all;
  @media screen and (max-width: 450px) {
    font-size: 21px;
    line-height: 30px;
  }
`
