import React, { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, FormError, TextInput, TextInputError } from 'views/components/LoginForm/styled'
import { resetPassword } from 'services/account/resetPassword'
import getParam from 'query-string'
import { ResetPasswordForm } from 'views/components/ResetPasswordForm'
import { Container, Content, ReadonlyContainer, TextInputContainer } from './styled'

const validation = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
})

const initialValues = {
  email: '',
}

export const ForgotPassword: FC = () => {
  const [resetError, setResetError] = useState('')
  const [success, setSuccess] = useState(false)

  const params = getParam.parse(window.location.href.split('?')[1])

  const onSubmit = (values: any) => {
    resetPassword(values.email).then(({ ok, message = '' }) => {
      if (!ok) setResetError(message)
      else setSuccess(true)
    })
  }

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit,
    validateOnChange: false,
    validationSchema: validation,
  })

  const handleClick = () => {
    handleSubmit()
  }

  if (params.token) {
    return (
      <Container>
        <Content>
          <ResetPasswordForm token={`${params.token}`} />
        </Content>
      </Container>
    )
  }

  if (success) {
    return (
      <ReadonlyContainer>
        <h2>Revisa tu bandeja de entrada</h2>
      </ReadonlyContainer>
    )
  }

  return (
    <Container>
      <Content>
        <h2>Recuperar contraseña</h2>
        <FormError>{resetError}</FormError>
        <TextInputContainer>
          <TextInput
            placeholder="Mail"
            name="email"
            value={values.email}
            onChange={handleChange}
            error={errors.email}
          />
          {errors.email && <TextInputError>{errors.email}</TextInputError>}
        </TextInputContainer>
        <Button type="submit" onClick={handleClick}>
          Enviar
        </Button>
      </Content>
    </Container>
  )
}
