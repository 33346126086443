/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { Form } from './Form'
import { Background, Container, Overlay } from './styled'

type Props = {
  backgroundColor?: string // Background color of form container
  backgroundImage?: string // Background image for the section
  buttonColor?: string // Background Button Color
  buttonTextColor?: string // Text Button Color
  buttonText?: string // Button Text Value
  color?: string // Primary Color used
  confirmMessage?: string // Confirm Message displayed beside checkbox.
  email?: string // Email placeholder
  name?: string // Name Placeholder
  titleBottom?: string // Title displayed on the bottom of title block
  titleMiddle?: string // Title displayed on the middle of title block
  titleTop?: string // Title displayed on the top of title block
  url: string // Newsletter URL
}

export const Newsletter: FC<Props> = (props) => {
  const { backgroundImage, url } = props
  return (
    <Container>
      <Background background={`url(${backgroundImage})`} />
      <Overlay />
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status }: { subscribe: any; status: string }) => (
          <Form {...props} subscribe={subscribe} status={status} />
        )}
      />
    </Container>
  )
}
