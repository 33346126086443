import { Store, ActionCreator } from '@types'
import persist from 'ducks/persist/reducer'
import { combineReducers } from 'redux-immutable'
import userReducer from 'ducks/user/reducer'
import { staticContentsReducer } from 'ducks/staticContents/reducers'
import { Reducer } from 'redux'

const appReducer = combineReducers({
  userReducer,
  persist,
  staticContentsReducer,
})

const rootReducer: Reducer<Store, ActionCreator> = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer
