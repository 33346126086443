import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { ActionCreator } from '@types'
import { IStaticContents } from '../models'
import * as TYPES from '../actionTypes'

export const initialState: IStaticContents = fromJS({
  uuid: '',
})

export const staticContentsReducer: Reducer<IStaticContents, ActionCreator> = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.SET:
      return state.merge(payload)
    case TYPES.GET_RESOURCES.REQUEST:
      return state.set('loading', true)
    case TYPES.GET_RESOURCES.FULFILL:
      return state.set('loading', false)
    case TYPES.GET_RESOURCES.FAILURE:
      return state.set('error', payload)
    case TYPES.GET_RESOURCES.SUCCESS:
      return state.set('content', payload)
    case TYPES.RESET:
      return initialState
    default:
      return state
  }
}
