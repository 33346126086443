import styled from 'styled-components'
import { ColorProps, color } from 'styled-system'

export const Container = styled.div<ColorProps>`
  background-color: #ffffff;
  border-radius: 11px;
  color: black;
  padding: 34px 30px;
  width: 390px;
  ${color};
  @media screen and (max-width: 768px) {
    width: calc(100% - 60px);
  }
`

export const Image = styled.img`
  width: 70px;
  height: 35px;
`

export const Text = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 13.9701px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: inherit;
  opacity: 0.3;
  @media screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 17px;
  }
`

export const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: inherit;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 25px;
  }
`
