import { FC } from 'react'
import { Container, Image, Text } from './styled'

export type ItemType = {
  image: string
  name: string
  link: string
  target?: boolean
}

type Props = {
  color?: string
  item: ItemType
}

export const Item: FC<Props> = ({ color, item }) => (
  <Container to={item.link} target={item.target ? 'blank' : undefined}>
    <Image src={item.image} />
    <Text color={color}>{item.name}</Text>
  </Container>
)
