import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { headerSelector } from 'ducks/staticContents'
import { Container, Logo } from './styled'

export const NotFound: FC = () => {
  const headerProps = useSelector(headerSelector)
  return (
    <Container>
      <Logo src={headerProps.logo} />
      <strong>Página no encontrada</strong>
    </Container>
  )
}
