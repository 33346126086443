import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Container = styled.div<ColorProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${color};
`

export const Image = styled.img`
  width: 854px;
  height: 570px;
  align-self: center;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 500px;
  }
  @media screen and (max-width: 450px) {
    height: 250px;
  }
`

export const Subtitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 160%;
  color: inherit;
  opacity: 0.7;
  margin-bottom: 0;
  @media screen and (max-width: 450px) {
    font-size: 24px;
  }
`

export const Title = styled.h1`
  color: inherit;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  margin-bottom: 0;
  @media screen and (max-width: 768px) {
    font-size: 26px;
    line-height: 32px;
  }
`

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 170%;
  color: inherit;
  margin: 20px 50px;
  @media screen and (max-width: 1024px) {
    margin: 20px 25px;
  }
  @media screen and (max-width: 768px) {
    margin: 20px 0;
  }
  @media screen and (max-width: 450px) {
    font-size: 16px;
    margin: 6px 0 20px;
  }
`
