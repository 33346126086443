import React, { FC } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { Button, Container, ContainerText, Text, Title } from './styled'

type Props = {
  button?: {
    path: string // Button URL to redirect
    label: string // Button Text to be displayed
    target?: boolean // Should open in a new tab
  }
  buttonTextColor?: string // Button text color
  buttonBackgroundColor?: string // Button background color
  image?: string // Image URI to display on Banner
  textColor?: string // Color that is used in the text
  text?: string // Text to be displayed if needed
  title?: string // Title to be displayed if needed
}

export const Banner: FC<Props> = (props) => {
  const { button, buttonTextColor, buttonBackgroundColor, image, textColor, text, title } = props
  return (
    <Container backgroundImage={`url(${image})`}>
      <ContainerText>
        <Title color={textColor}>{title}</Title>
        <Text color={textColor}>{text}</Text>
        {button && (
          <Button
            color={buttonTextColor}
            backgroundColor={buttonBackgroundColor}
            to={button?.path || ''}
            target={button?.target ? 'blank' : undefined}
          >
            {button?.label}
            <HiOutlineArrowNarrowRight className="icon" />
          </Button>
        )}
      </ContainerText>
    </Container>
  )
}
