import React, { FC } from 'react'
import { BiCheck } from 'react-icons/bi'
import { Button, Container, Content, Row, Text, Title, Value, ScrollFeatures, FeaturesContainer } from './styled'

export type PlanType = {
  id: number | string
  value: number
  unit: string
  period: string
  name: string
  features?: string[]
  button?: string
  buttonTextColor?: string
  buttonColor?: string
  buttonFeaturedColor?: string
  buttonFeaturedTextColor?: string
  featured?: boolean
  backgroundColor?: string
  featuredBackgroundColor?: string
  textColor?: string
  disableSelectAction?: boolean
  oneTime?: boolean
  onClick: () => void
}

export const Plan: FC<PlanType> = (props) => {
  const {
    value,
    unit,
    period,
    name,
    featured,
    features,
    button,
    buttonColor,
    buttonFeaturedColor,
    buttonFeaturedTextColor,
    buttonTextColor,
    backgroundColor,
    disableSelectAction,
    featuredBackgroundColor,
    textColor,
    onClick,
  } = props
  const formatter = (): string => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const renderFeature = (feature: string) => {
    return (
      <Row key={feature}>
        <BiCheck />
        <Text>{feature}</Text>
      </Row>
    )
  }

  const containerEvents = !disableSelectAction ? onClick : () => {}
  const buttonEvents = disableSelectAction ? onClick : () => {}

  return (
    <Container
      style={{ cursor: disableSelectAction ? 'initial' : 'pointer' }}
      onClick={containerEvents}
      featured={featured}
      color={textColor}
      backgroundColor={featured ? featuredBackgroundColor : backgroundColor}
    >
      <Content>
        <Value>
          <span className="unit">{unit}</span>
          {formatter()}
          <span className="period">/{period}</span>
        </Value>
        <FeaturesContainer>
          <Title>{name}</Title>
          <ScrollFeatures>{features?.map(renderFeature)}</ScrollFeatures>
        </FeaturesContainer>
      </Content>
      <Button
        onClick={buttonEvents}
        backgroundColor={featured ? buttonFeaturedColor : buttonColor}
        color={featured ? buttonFeaturedTextColor : buttonTextColor}
      >
        {button}
      </Button>
    </Container>
  )
}
