import { ServiceResponse } from '@types'
import axios from 'axios'

const query = `
  query {
    lnfPodcasts(where: { state: "Publicado" }) {
      id
      slug
      title
      description
      attachement
      feature_episode_id
      created_at
    }
    lnfEpisodes(where: { state: "Publicado", }) {
      id
      slug
      title
      attachement_featured
      duration
      link_episode
      featured
      order: position_order
      rols: lnf_episode_acces_subsc_rols_id {
        id
        rol: subsc_role_id {
          id
          name
        }
      }
      lnf_podcast_id {
        id
        slug
        title
      }
    }
  }
`

export const getPodcastAndFeaturedEpisodes = async (): Promise<ServiceResponse> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_TFM_API}/graphql`, { query })
    return { ok: true, data }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}
