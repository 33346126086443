import React, { FC } from 'react'
import { CallToActionBanner } from 'views/components/CallToActionBanner'
import { NewsletterForm } from 'views/components/NewsletterForm'
import { useSelector } from 'react-redux'
import { footerSelector, newsLetterComponentsSelector } from 'ducks/staticContents'
import { Background, Container, Separator, Overlay } from './styled'

export const Newsletter: FC = () => {
  const footerProps = useSelector(footerSelector)
  const data = useSelector(newsLetterComponentsSelector)

  return (
    <Container>
      <Background background="url(https://i.imgur.com/2ZAkogj.png)" />
      <Overlay />
      <NewsletterForm
        url={footerProps.newsletterUrl}
        title={data.formTitle?.title}
        text={data.formTitle?.message}
        link={{
          label: data.formTitle?.linkLabel,
          path: data.formTitle?.link,
          target: true,
        }}
      />
      <Separator />
      <CallToActionBanner
        title={data.complice?.title}
        backgroundImage={data.complice?.image}
        textColor="white"
        button={data.complice?.link_label}
        link={data.complice?.link}
      />
    </Container>
  )
}
