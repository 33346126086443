import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { background, BackgroundProps, color, ColorProps } from 'styled-system'

export const Button = styled(Link)<ColorProps>`
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  letter-spacing: 0.217732px;
  padding: 16px 32px;
  color: white;
  background-color: black;
  ${color}
`

export const AButton = styled.a<ColorProps>`
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  letter-spacing: 0.217732px;
  padding: 16px 32px;
  color: white;
  background-color: black;
  ${color}
`

export const Container = styled.div<BackgroundProps & ColorProps>`
  padding: 5% 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${background};
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-color: white;
  ${color};
`

export const defaultContentStyle = css`
  justify-content: space-between;
  align-items: center;
`
export const variantTwoContentStyle = css`
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: stretch;
  }
`

export const Content = styled.div<{ variant?: string }>`
  overflow: hidden;
  display: flex;
  width: 80%;
  margin: 10% 0 5%;
  align-self: center;
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    width: 100%;
  }
  ${({ variant }) => (variant === 'variant 2' ? variantTwoContentStyle : defaultContentStyle)}
`

const variantTwo = css`
  background-color: white;
  padding: 3.7rem 180px 3.7rem 3.7rem;
  margin-right: -160px;

  @media screen and (max-width: 768px) {
    padding: 1rem;
    margin: 2rem 1rem 1rem 1rem;
    align-self: stretch;
    width: unset;
    box-sizing: border-box;
  }
`

export const Description = styled.div<{ variant?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
  min-width: 340px;
  @media screen and (max-width: 768px) {
    width: 60%;
    margin-top: 20px;
  }
  @media screen and (max-width: 450px) {
    width: 90%;
    margin-top: 30px;
    padding: 0 16px;
    min-width: unset;
  }
  ${({ variant }) => (variant === 'variant 2' ? variantTwo : '')}
`
const defaultImageStyle = css`
  width: 65%;
  height: 430px;
  object-fit: cover;
  @media screen and (min-width: 1920px) {
    height: 600px;
  }
  @media screen and (max-width: 1024px) {
    width: 60%;
    height: 375px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 300px;
  }
`

const variantTwoImage = css`
  width: 65%;
  max-width: 600px;
  height: 330px;
  object-fit: cover;
  @media screen and (max-width: 768px) {
    margin: 0 0.2rem;
    max-width: unset;
    align-self: stretch;
    width: initial;
    box-sizing: border-box;
  }
`

export const Image = styled.img<{ variant?: string }>`
  ${({ variant }) => (variant === 'variant 2' ? variantTwoImage : defaultImageStyle)}
`

export const Text = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #000000;
  margin: 5px 0 20px;
  ${color};
  @media screen and (min-width: 1920px) {
    margin: 20px 0 30px;
  }
  @media screen and (max-width: 450px) {
    font-size: 15px;
    line-height: 19px;
    width: 80%;
  }
`

export const Title = styled.h1<ColorProps>`
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  text-align: center;
  color: #000000;
  ${color};
  @media screen and (max-width: 450px) {
    font-size: 38px;
    line-height: 38px;
  }
`
export const SubTitle = styled.h1<ColorProps>`
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #000000;
  ${color};
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
`
