import React, { FC } from 'react'
import { TextContent } from '../styled'
import { Container, Image, Text, Title } from './styled'

export type SimpleDataItem = {
  backgroundColor?: string
  image?: string
  text?: string
  textColor?: string
  title: string
}

export const Item: FC<SimpleDataItem> = ({ backgroundColor, image, text, textColor, title }) => (
  <Container backgroundColor={backgroundColor}>
    <TextContent color={textColor}>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </TextContent>
    {image && <Image src={image} />}
  </Container>
)
