import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Background = styled.div<BackgroundProps>`
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  mix-blend-mode: color-dodge;
  height: 100%;
  width: 100%;
  position: absolute;
  filter: grayscale(0.2);
  top: 0;
  left: 0;
  z-index: 1;
  ${background};
  @media screen and (max-width: 450px) {
    background: transparent;
  }
`

export const Container = styled.div<BackgroundProps>`
  box-sizing: border-box;
  overflow: hidden;
  padding: 10% 0 5%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  @media screen and (max-width: 450px) {
    overflow: visible;
    margin-top: 30px;
  }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #c4c4c4;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 450px) {
    background: transparent;
  }
`
