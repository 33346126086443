import React, { FC } from 'react'
import { CallToActionBanner } from 'views/components/CallToActionBanner'
import { ContactForm } from 'views/components/ContactForm'
import getParam from 'query-string'
import { create } from 'services/contactUs/create'
import { useSelector } from 'react-redux'
import { contactUsSelector } from 'ducks/staticContents'
import { Container, Separator } from './styled'

export const Contact: FC = () => {
  const params = getParam.parse(window.location.href.split('?')[1])

  const data = useSelector(contactUsSelector)

  return (
    <Container>
      <ContactForm
        submitContact={create}
        initialValues={params}
        textColor="#393939"
        title={data.formTitle?.title}
        subtitle={data.formTitle?.message}
        link={{
          label: data.formTitle?.linkLabel,
          path: data.formTitle?.link,
          target: true,
        }}
      />
      <Separator />
      <CallToActionBanner
        title={data.complice?.title}
        backgroundImage={data.complice?.image}
        textColor="white"
        button={data.complice?.link_label}
        link={data.complice?.link}
      />
    </Container>
  )
}
