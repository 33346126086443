import styled from 'styled-components'

type ButtonProps = {
  disabled?: boolean
}

export const FeedbackButton = styled.button<ButtonProps>`
  border: none;
  width: 140px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ disabled }) => (disabled ? '#9b9b9b' : '#000')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: #fff;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.22px;
  margin-top: 16px;
`

export const FeedbackInput = styled.textarea`
  width: 100%;
  border-radius: 1.5px;
  border: 1px solid #393939;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.22px;
  background-color: transparent;
  resize: none;
  color: #393939;
  padding: 14px;
  height: 90%;
  box-sizing: border-box;
  &::placeholder {
    color: #393939;
  }
`

export const FeedbackContainer = styled.div`
  width: 100%;
  max-width: 880px;
  background-color: #fff;
  display: flex;
  box-sizing: border-box;
  position: fixed;
  align-self: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 15;

  @media screen and (max-width: 850px) {
    left: 1rem;
    width: auto;
    right: 1rem;
    flex-direction: column;
  }
  @media screen and (max-height: 500px) {
    top: 0;
    bottom: 0;
    transform: translateY(0%);
    overflow-y: auto;
    flex-direction: column;
    margin: 0 1rem;
  }
`

export const FeedbackLeftContainer = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: 50px 50px 50px 25px;
`

export const FeedbackRightContainer = styled.div`
  flex: 1;
  padding: 50px 25px;
  box-sizing: border-box;
  background-color: #fcd83a;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const FeedbackOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #000000;
  & input[type='checkbox'] {
    color: #000;
  }
`

export const FeedbackOptionInput = styled.input`
  margin-right: 15px;
  cursor: pointer;
  &[type='checkbox']:not(:checked),
  &[type='checkbox']:checked {
    position: absolute;
    left: 0;
    opacity: 0.01;
  }
  &[type='checkbox']:not(:checked) + label,
  &[type='checkbox']:checked + label {
    position: relative;
    padding-left: 2.3em;
    font-size: 1.05em;
    line-height: 1.7;
    cursor: pointer;
  }
  &[type='checkbox']:not(:checked) + label:before,
  &[type='checkbox']:checked + label:before {
    content: '';
    position: absolute;
    left: 4px;
    top: 2.5px;
    width: 18px;
    height: 18px;
    background: #fcd83a;
    border-radius: 4px;
    -webkit-transition: all 0.275s;
    transition: all 0.275s;
  }

  &[type='checkbox']:not(:checked) + label:before {
    background: black;
  }

  /* checked mark aspect */
  &[type='checkbox']:not(:checked) + label:after,
  &[type='checkbox']:checked + label:after {
    content: '✓';
    position: absolute;
    top: 12px;
    left: 4px;
    font-size: 1.375em;
    color: #0a142f;
    background: #fcd83a;
    line-height: 0;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  /* checked mark aspect changes */
  &[type='checkbox']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
  }

  &[type='checkbox']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
`

export const FeedbackOptions = styled.div``

export const FeedbackOrderedList = styled.ol`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #000000;
  padding-left: 15px;
`

export const FeedbackSubtitle = styled.h2`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #000000;
  margin: 8px 0 16px;
`

export const FeedbackTitle = styled.h1`
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  color: #000000;
  margin: 0;
`
