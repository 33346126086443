import { FC } from 'react'
import store from 'lib/redux'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import theme from 'lib/theme'
import StyledApp from 'lib/theme/StyledApp'
import Persist from 'views/components/Persist'
import 'lib/axios'
import smoothscroll from 'smoothscroll-polyfill'
import { SpotifyProvider } from 'hooks/useSpotifyPlayer'
import Router from './Router'

// allow smooth scroll in safari browsers
smoothscroll.polyfill()

const App: FC = () => (
  <Provider store={store}>
    <Persist loading={null}>
      <ThemeProvider theme={theme}>
        <SpotifyProvider>
          <StyledApp />
          <Router />
        </SpotifyProvider>
      </ThemeProvider>
    </Persist>
  </Provider>
)
export default App
