import { FC, useRef } from 'react'
import HtmlRender from './HtmlRender'
import { Button, Container, Content, CoverImage, Footer, Header, Image, Text, TextArea, Title } from './styled'

type Props = {
  coverImage?: string
  image?: string
  title?: string
  subtitle?: string
  textColor?: string
  content: string
  comment?: string
  button?: string
  buttonColor?: string
  buttonTextColor?: string
  onSubmit?: (comment: string) => void
}

export const ExclusiveContent: FC<Props> = (props) => {
  const {
    coverImage,
    image,
    title,
    subtitle,
    textColor,
    content,
    comment,
    button = 'Enviar',
    buttonColor,
    buttonTextColor,
    onSubmit,
  } = props
  const input = useRef<any>(null)
  const handleSubmit = () => {
    const value = input.current?.value
    if (value) {
      onSubmit?.(value || '')
      input.current.value = ''
    }
  }

  return (
    <Container color={textColor}>
      <Header>
        <Title>{title}</Title>
        <Text>{subtitle}</Text>
      </Header>
      {coverImage && <CoverImage background={`url(${coverImage})`} />}
      <Content>
        <HtmlRender>{content}</HtmlRender>
      </Content>
      {image && <Image src={image} />}
      {Boolean(title) && (
        <Footer>
          <TextArea ref={input} placeholder={comment || 'Cuéntanos que piensas'} />
          <Button onClick={handleSubmit} backgroundColor={buttonColor} color={buttonTextColor}>
            {button}
          </Button>
        </Footer>
      )}
    </Container>
  )
}
