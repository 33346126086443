import styled from 'styled-components'

export const Container = styled.div`
  min-height: calc(100vh - 80px);
  display: flex;
  padding: 75px 10%;
  width: 80%;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 1024px) {
    padding: 75px 5%;
    width: 90%;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 450px) {
    padding-bottom: 0;
  }
`

export const Separator = styled.div`
  width: 0;
  height: 100%;
  width: 0px;
  height: 575px;
  border: 1px solid #c8c8c8;
  @media screen and (min-width: 1920px) {
    height: 650px;
  }
  @media screen and (max-width: 768px) {
    height: 0;
    width: 100%;
    margin: 40px 0;
  }
`
