import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'

export const renderSocial = (socialName: string) => {
  switch (socialName.toLowerCase()) {
    case 'facebook':
      return <FaFacebook />
    case 'instagram':
      return <FaInstagram />
    case 'twitter':
      return <FaTwitter />

    default:
      return null
  }
}
