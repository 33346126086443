import { ServiceResponse } from '@types'
import get from 'lodash/get'
import axios from 'axios'
// import { Plans } from 'views/components/Plans'

const query = `
  query {
    subscSubscriptionPlans (sort: "position_order:asc" , where: { status:"Publicado"})  {
      id
      uuid: tfm_id
      status
      slug
      name
      value
      code
      period
      period_value
      oneTime: one_time_charge
      paymentTypes: subsc_subscription_plan_subsc_payment_types_id {
        id
        type: subsc_payment_type_id {
          id
          name
          slug
          uuid: tfm_id
          attachment
        }
      }
      roles: subsc_subscription_plan_subsc_rols_id {
        id
        uuid: tfm_id
        role: subsc_role_id {
          id
          uuid: tfm_id
          slug
          name
          description
        }
      }
      currency: subsc_currency_id {
        id
        uuid: tfm_id
        name
        slug
      }
      features: subsc_plan_features_id {
        id
        uuid: tfm_id
        position_order
        feature: subsc_feature_id {
          description
          order: position_order
          uuid: tfm_id
          id
        }
      }
      categories: subsc_plan_categories {
        id
        uuid: tfm_id
        subsc_category_id {
          id
          uuid: tfm_id
          name
          order: position_order
        }
      }
    }
  }
`

export const getPlans = async (): Promise<ServiceResponse> => {
  try {
    const {
      data: {
        data: { subscSubscriptionPlans: data },
      },
    } = await axios.post(`${process.env.REACT_APP_TFM_API}/graphql`, { query })
    return {
      data: data.map((item: any) => ({
        ...item,
        paymentTypes: item.paymentTypes?.map((e: any) => ({
          id: e.type.id,
          slug: e.type.slug,
          label: e.type.name,
          image: get(e, ['type', 'attachment', 0, 'virtualPath']),
        })),
      })),
      ok: true,
    }
  } catch {
    return {
      data: [],
      message: 'empty',
      ok: false,
    }
  }
}
