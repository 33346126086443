import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 30px;
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 450px) {
    scroll-snap-align: center;
    scroll-snap-stop: always;
  }
`

export const Image = styled.img`
  width: 246px;
  height: 246px;
`

export const Text = styled.p<ColorProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  width: 100%;
  padding-right: 10px;
  word-break: break-all;
  color: #000000;
  margin-top: 5px;
  ${color}
`
