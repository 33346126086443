import styled from 'styled-components'
import { background, BackgroundProps, color, ColorProps } from 'styled-system'

type Background = {
  backgroundColor?: string
}

export const Container = styled.div<Background & BackgroundProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  background-repeat: no-repeat;
  background-size: contain;
  padding-bottom: 5%;
  ${background};
  @media screen and (max-width: 450px) {
    background-size: 100% 50%;
  }
`

export const Collections = styled.div`
  display: flex;
  align-items: flex-start;
  width: 90%;
  margin-top: 32px;
  margin-left: 10%;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  @media screen and (max-width: 1024px) {
    margin-left: 5%;
  }
  @media screen and (max-width: 768px) {
    margin-left: 16px;
    width: calc(100% - 32px);
  }
  @media screen and (max-width: 450px) {
    scroll-snap-type: x mandatory;
    margin-left: 16px;
    width: calc(100% - 16px);
  }
`

export const NavigationContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  padding-right: 5%;
  @media screen and (max-width: 768px) {
    padding-right: 16px;
  }
`

type NavigationButtonProps = {
  disabled?: boolean
  side?: 'left' | 'right'
}

export const NavigationButton = styled.button<NavigationButtonProps & ColorProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background-color: #000;
  border-width: 0;
  color: #fff;
  margin: 0 5px 10px;
  cursor: pointer;
  border-top-left-radius: ${({ side }) => side === 'left' && '8px'};
  border-bottom-left-radius: ${({ side }) => side === 'left' && '8px'};
  border-top-right-radius: ${({ side }) => side === 'right' && '8px'};
  border-bottom-right-radius: ${({ side }) => side === 'right' && '8px'};
  ${color};
  @media screen and (max-width: 768px) {
    margin: 0 8px 10px;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 5%;
`

export const Title = styled.h1<ColorProps>`
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  text-align: center;
  color: #000000;
  margin: 0;
  padding-left: 10%;
  ${color};
  @media screen and (max-width: 1024px) {
    padding-left: 5%;
  }
  @media screen and (max-width: 768px) {
    padding-left: 16px;
  }
  @media screen and (max-width: 450px) {
    font-size: 38px;
    line-height: 38px;
  }
`
