import { ServiceResponse } from '@types'
import axios from 'axios'

export const create = async (values: any): Promise<ServiceResponse> => {
  if (!values.checked) return { ok: false, message: 'Must to accept terms and conditions' }
  try {
    await axios.post(
      `${process.env.REACT_APP_TFM_API}/flow/solution/a414a617-8f8a-4967-b1f5-72f55d7d2a1c/actions/84d4fdab-cdeb-4fb9-81c9-8626c31590fc`,
      {
        data: {
          'eef1db45-8148-42ea-a851-c2febf581fb0': values.name,
          '32e1d1ce-9307-4a93-9675-45b12922a3d6': values.email,
          'c896cc5b-427a-4991-b593-0603e2a1f467': values.message,
        },
      }
    )
    return { ok: true }
  } catch (error) {
    return { ok: false, message: error.message }
  }
}
