import createImmutableSelector from 'lib/immutable/selector'
import { IStore } from '@types'
import { IStaticContents } from '../models'

const getStaticContents = (state: IStore): IStaticContents => state.get('staticContentsReducer')

export const staticContentsSelector = createImmutableSelector(getStaticContents, (staticContents) => staticContents)

const getHeader = (reducer: IStaticContents) => reducer.getIn(['content', 'header'])?.toJS() || {}

export const headerSelector = createImmutableSelector(getStaticContents, getHeader)

const getFooter = (reducer: IStaticContents) => reducer.getIn(['content', 'footer'])?.toJS() || {}

export const footerSelector = createImmutableSelector(getStaticContents, getFooter)

const getHomeComponents = (reducer: IStaticContents): any => reducer.getIn(['content', 'homeComponents'])?.toJS() || {}

export const homeComponentsSelector = createImmutableSelector(getStaticContents, getHomeComponents)

const getAboutUsComponents = (reducer: IStaticContents): any =>
  reducer.getIn(['content', 'aboutUsComponents'])?.toJS() || {}

export const aboutUsSelector = createImmutableSelector(getStaticContents, getAboutUsComponents)

const getTeam = (reducer: IStaticContents): any => reducer.getIn(['content', 'team'])?.toJS() || []

export const teamSelector = createImmutableSelector(getStaticContents, getTeam)

const getContactUs = (reducer: IStaticContents): any => reducer.getIn(['content', 'contactUsComponents'])?.toJS() || {}

export const contactUsSelector = createImmutableSelector(getStaticContents, getContactUs)

const getGeneralSubscription = (reducer: IStaticContents): any => reducer.getIn(['content', 'generalSubscription'])

export const generalSubscriptionSelector = createImmutableSelector(getStaticContents, getGeneralSubscription)

const getNewsLetterComponents = (reducer: IStaticContents): any =>
  reducer.getIn(['content', 'newsLetterComponents'])?.toJS() || {}

export const newsLetterComponentsSelector = createImmutableSelector(getStaticContents, getNewsLetterComponents)

const loginComponents = (reducer: IStaticContents): any => reducer.getIn(['content', 'loginComponents'])?.toJS() || {}

export const loginComponentsSelector = createImmutableSelector(getStaticContents, loginComponents)

const getStatus = (reducer: IStaticContents) => ({
  loading: reducer.get('loading'),
  error: reducer.get('error'),
})

export const contentsStatusSelector = createImmutableSelector(getStaticContents, getStatus)

const getUserBanner = (reducer: IStaticContents): any => reducer.getIn(['content', 'userBanner'])?.toJSON() || {}

export const userBannerSelector = createImmutableSelector(getStaticContents, getUserBanner)

const getSignUpScreenTexts = (reducer: IStaticContents): any =>
  reducer.getIn(['content', 'singupScreen'])?.toJSON() || {}

export const signUpScreenTextsSelector = createImmutableSelector(getStaticContents, getSignUpScreenTexts)
