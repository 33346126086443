import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { background, BackgroundProps, color, ColorProps } from 'styled-system'

export const Background = styled.div<BackgroundProps>`
  ${background};
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  mix-blend-mode: multiply;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`

export const Button = styled(Link)<ColorProps>`
  border: none;
  color: black;
  background-color: #fcd83a;
  border-radius: 2px;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.22px;
  padding: 16px;
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  ${color};
`

export const Container = styled.div`
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 85px;
  width: 360px;
  position: relative;
  z-index: 1;
  margin-top: 20px;
  margin-left: 10px;
  ${background};
  @media screen and (min-width: 1920px) {
    width: 500px;
    height: 580px;
  }
  @media screen and (max-width: 768px) {
    width: calc(90% - 20px);
    margin-top: 0;
  }
  @media screen and (max-width: 450px) {
    height: 366.69px;
  }
  @media screen (max-width: 1024px) and (orientation: landscape) {
    padding: 0 45px;
    width: 80%;
  }
`

export const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #3c64b1;
  height: 100%;
  width: 100%;
`

export const Title = styled.h1<ColorProps>`
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  text-align: center;
  color: black;
  margin: 0;
  ${color};
  @media screen and (max-width: 1024px) and (orientation: landscape) {
    font-size: 25px;
    line-height: 45px;
  }
  @media screen and (max-width: 450px) {
    font-size: 25px;
    line-height: 45px;
  }
`
