/* eslint-disable react/jsx-props-no-spreading */
import { useSpotify } from 'hooks/useSpotifyPlayer'
import { FC } from 'react'
import { Item, MediaGalleryItem } from './Item'
import { Container, Grid, Title } from './styled'

type Props = {
  items: Array<Omit<MediaGalleryItem, 'onClick'>>
  title?: string // Title to be displayed with count of media
  textColor?: string // Text color to be used
  backgroundColor?: string // Section background Color
  cardBackgroundColor?: string // Background Card Color
  cardTextColor?: string // Text Card Color
  playButtonColor?: string // Play Button Color
  playButtonIconColor?: string // Play Button Icon Color
}

export const MediaGallery: FC<Props> = (props) => {
  const {
    items,
    title,
    textColor,
    backgroundColor,
    cardBackgroundColor,
    cardTextColor,
    playButtonIconColor,
    playButtonColor,
  } = props

  const { setEmbed } = useSpotify()

  const renderItem = (item: Omit<MediaGalleryItem, 'onClick'>) => {
    const onClick = () => {
      setEmbed(item.embed)
    }
    return (
      <Item
        {...item}
        dangerHtml
        key={item.embed}
        onClick={onClick}
        textColor={cardTextColor}
        backgroundColor={cardBackgroundColor}
        playButtonColor={playButtonColor}
        playButtonIconColor={playButtonIconColor}
      />
    )
  }

  return (
    <Container backgroundColor={backgroundColor} color={textColor}>
      {title && <Title>{`${items.length} ${title}`}</Title>}
      <Grid>{items.map(renderItem)}</Grid>
    </Container>
  )
}
