import React, { FC, useState } from 'react'
import { FiPlay } from 'react-icons/fi'
import { FaFacebook, FaWhatsapp, FaTwitter } from 'react-icons/fa'
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md'
import { useSpotify } from 'hooks/useSpotifyPlayer'
import { getRateKey } from 'lib/getRateKey'
import {
  ActionButton,
  ActionButtonsContainer,
  Background,
  Container,
  Description,
  FooterContainer,
  Image,
  Label,
  LeftContainer,
  Overlay,
  RateButton,
  RateContainer,
  RateTitle,
  RightContainer,
  SocialContainer,
  SocialContent,
  SocialLink,
  SocialTitle,
  Subtitle,
  TextContent,
  Title,
} from './styled'

export type RateType = {
  label: string
  value: string
  id: any
}

type Props = {
  link?: string
  podcastID?: string
  profileID?: string
  episodeID?: string
  onRateClick?: (rateID: any) => void
  dangerHtml?: boolean
  bgImage: string
  description?: string // Description text to be displayed
  favorite?: boolean // Is favorited
  image: string // Image URL
  rates?: RateType[] // Rate options to be displayed
  rateTitle?: string // Rate title to be displayed above image
  socials?: {
    // Supported social links
    facebook?: string
    whatsapp?: string
    twitter?: string
  }
  title?: string // Title to be displayed
  subtitle?: string // Subtitle to be displayed
  textColor?: string // Text Color to be used
  socialTitle?: string // Text to be displayed in share section
  secondaryColor?: string // Secondary color to be used on subtitle and buttons
  buttonTextColor?: string // Color of button icon
  labels?: string[] // List of labels to be displayed after description
  showFav?: boolean
  handleFavorite?: () => void
}

const socialUrls: any = {
  facebook: `https://www.facebook.com/sharer/sharer.php?u=__LINK__`,
  whatsapp: `whatsapp://send?text=__LINK__`,
  twitter: `https://twitter.com/intent/tweet?url=__LINK__`,
}

const renderSocial = (socialName: string) => {
  const handleClick = () => {
    const link = socialUrls[socialName]
    if (link) {
      window.open(link.replace('__LINK__', window.location.href), '_target')?.focus()
    }
  }

  switch (socialName.toLowerCase()) {
    case 'facebook':
      return <FaFacebook onClick={handleClick} />
    case 'whatsapp':
      return <FaWhatsapp onClick={handleClick} />
    case 'twitter':
      return <FaTwitter onClick={handleClick} />

    default:
      return null
  }
}

export const Overview: FC<Props> = (props) => {
  const {
    description,
    favorite,
    image,
    rates,
    rateTitle,
    socials = {},
    title,
    subtitle,
    socialTitle,
    textColor,
    secondaryColor,
    buttonTextColor,
    labels,
    dangerHtml,
    bgImage,
    link,
    showFav,
    podcastID = '',
    profileID = '',
    episodeID = '',
    onRateClick = () => {},
    handleFavorite,
  } = props
  const [, setRenderToggle] = useState(false)
  const { setEmbed } = useSpotify()

  const handleRateClick = (rate: any) => () => {
    setRenderToggle((v) => !v)
    onRateClick(rate)
  }

  const handleClick = () => {
    if (link) {
      setEmbed(link)
    }
  }
  return (
    <Container color={textColor}>
      <Background bgImage={bgImage} />
      <Overlay />
      <LeftContainer>
        <Image background={`url(${image})`}>
          <ActionButtonsContainer>
            {showFav && (
              <ActionButton backgroundColor={secondaryColor} color={buttonTextColor} onClick={handleFavorite}>
                {favorite ? <MdFavorite /> : <MdFavoriteBorder />}
              </ActionButton>
            )}
            <ActionButton onClick={handleClick} backgroundColor={secondaryColor} color={buttonTextColor}>
              <FiPlay className="icon" />
            </ActionButton>
          </ActionButtonsContainer>
        </Image>
      </LeftContainer>
      <RightContainer>
        <TextContent>
          <Title>{title}</Title>
          <Subtitle color={secondaryColor}>{subtitle}</Subtitle>
          {dangerHtml ? (
            <Description dangerouslySetInnerHTML={{ __html: description || '' }} />
          ) : (
            <Description>{description}</Description>
          )}
          <Label>
            {labels?.map((label) => (
              <React.Fragment key={label}>
                {label}
                <br />
              </React.Fragment>
            ))}
          </Label>
        </TextContent>
        <SocialContent>
          <SocialTitle>{socialTitle}</SocialTitle>
          <SocialContainer>
            {Object.entries(socials).map(([key, value]) => (
              <SocialLink key={key} href={value} target="blank">
                {renderSocial(key)}
              </SocialLink>
            ))}
          </SocialContainer>
        </SocialContent>
      </RightContainer>
      <FooterContainer>
        <RateTitle>{rateTitle}</RateTitle>
        <RateContainer>
          {rates?.map((rate) => {
            const rateSaved = localStorage.getItem(getRateKey(podcastID, episodeID, profileID))
            const shouldHide = Boolean(rateSaved) && rateSaved !== `${rate.id}`
            return (
              <RateButton
                className={`${rateSaved === `${rate.id}` && 'RateIsActive'} ${shouldHide && 'HideRateButton'}`}
                onClick={handleRateClick(rate)}
                key={rate.value}
              >
                {rate.label}
              </RateButton>
            )
          })}
        </RateContainer>
      </FooterContainer>
    </Container>
  )
}
