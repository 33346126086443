import { FC } from 'react'
import { ButtonContainer, ButtonLink, Container, Content, CustomLink, Row, Subtitle, Title } from './styled'

type Link = {
  label: string
  path: string
  target?: boolean
}

type Props = {
  greeting: string
  message?: string
  name?: string
  link?: Link
  buttonLink?: Link
  backgroundColor?: string
  textPrimaryColor?: string
  textSecondaryColor?: string
}

export const Greeting: FC<Props> = (props) => {
  const { greeting, message, name, link, buttonLink, backgroundColor, textPrimaryColor, textSecondaryColor } = props
  return (
    <Container backgroundColor={backgroundColor} color={textPrimaryColor}>
      <Row>
        <Content>
          <Title>
            {greeting}, {name}
          </Title>
          <Subtitle>{message}</Subtitle>
        </Content>
        {buttonLink && (
          <ButtonContainer customColor={textPrimaryColor}>
            <ButtonLink
              customColor={textSecondaryColor}
              target={buttonLink?.target ? 'blank' : undefined}
              href={buttonLink?.path}
            >
              {buttonLink?.label}
            </ButtonLink>
          </ButtonContainer>
        )}
      </Row>
      {link && (
        <CustomLink color={textSecondaryColor} to={link.path} target={link.target ? 'blank' : undefined}>
          {link.label}
        </CustomLink>
      )}
    </Container>
  )
}
