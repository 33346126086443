import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, autoRehydrate } from 'redux-persist-immutable'
import createSagaMiddleware from 'redux-saga'
import immutableTransform from 'redux-persist-transform-immutable'

import reducers from './reducers'
import sagas from './sagas'

const persistConfig = {
  transforms: [immutableTransform()],
  key: 'root',
  whitelist: ['userReducer', 'staticContentsReducer'],
}

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const middlewares = [sagaMiddleware]

const enhancers = [autoRehydrate(), applyMiddleware(...middlewares)]

const store = createStore(reducers, composeEnhancers(...enhancers))

export const persist = persistStore(store, persistConfig)

sagaMiddleware.run(sagas)

export default store
