import ObjectMapper from 'object-mapper'
import { singleFile } from 'lib/propsParser/singleFile'

export const tGalleryMapper = (original: any): any =>
  ObjectMapper.merge(original, original, {
    left_image: {
      key: 'left_image',
      transform: singleFile,
    },
    right_top_image: {
      key: 'right_top_image',
      transform: singleFile,
    },
    right_bottom_image: {
      key: 'right_bottom_image',
      transform: singleFile,
    },
  })
