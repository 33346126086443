import React, { FC } from 'react'
import { ArticleWithImage } from 'views/components/ArticleWithImage'
import { Highlight } from 'views/components/Highlight'
import { CardsFeatured } from 'views/components/CardsFeatured'
import { SimpleDataDisplay } from 'views/components/SimpleDataDisplay'
import { useSelector } from 'react-redux'
import { generalSubscriptionSelector, aboutUsSelector, teamSelector } from 'ducks/staticContents'
import { Container } from './styled'

export const AboutUs: FC = () => {
  const generalText = useSelector(generalSubscriptionSelector)
  const components = useSelector(aboutUsSelector)
  const team = useSelector(teamSelector)
  return (
    <Container>
      <ArticleWithImage
        backgroundImage={components.manifest.background}
        image={components.manifest.image}
        text={components.manifest.content}
        title={components.manifest.title}
      />
      <CardsFeatured title="Equipo" items={team} />
      <SimpleDataDisplay
        title={components.management.title}
        text={components.management.subtitle}
        backgroundImage={components.management.background}
        // items={SIMPLE_DATA_ITEMS}
        link={{
          name: '',
          target: true,
          url: `${components?.management?.link}`,
        }}
        image={components?.management?.image}
      />
      <Highlight
        button={generalText?.get('subscribeButtonText')}
        message={generalText?.get('subscribeText')}
        link={generalText?.get('newsletter_url')}
        buttonColor="black"
        buttonTextColor="white"
        backgroundImage="https://i.imgur.com/QXXBnKY.png"
        backgroundColor="#FCD83B"
        noBottomSpacing
      />
    </Container>
  )
}
