import React, { FC } from 'react'
import ChartPNG from 'assets/images/chart_transparency.png'
import { Chart, ChartImage, ChartLabel, ChartValue, Container, Content, Subtitle, Title } from './styled'

export type ChartType = {
  value: number
  image?: string
  label: string
  color?: string
}

type Props = {
  title?: string
  subtitle?: string
  textColor?: string
  chartTextColor?: string
  charts?: ChartType[]
}

export const ChartDisplay: FC<Props> = (props) => {
  const { charts, title, subtitle, textColor, chartTextColor } = props

  const renderChart = (chart: ChartType) => {
    const color = chart.color?.startsWith('#') ? chart.color : `#${chart.color}`
    return (
      <Chart color={chartTextColor} key={chart.label} backgroundColor={color}>
        <ChartImage src={ChartPNG} chartHeight={chart.value} />
        <ChartValue>
          {chart.value}
          <span className="measure">%</span>
        </ChartValue>
        <ChartLabel>{chart.label}</ChartLabel>
      </Chart>
    )
  }

  return (
    <Container color={textColor}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Content>{charts?.map(renderChart)}</Content>
    </Container>
  )
}
