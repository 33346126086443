import { ServiceResponse } from '@types'
import axios from 'axios'

export const create = async (values: any, nameCard: string): Promise<ServiceResponse> => {
  try {
    const datos = values.expiry.split('/')
    const spacer = values.cardNumber.replace(/\s+/g, '')
    await axios.post(
      `${process.env.REACT_APP_TFM_API}/flow/solution/b8542a30-2949-474b-a649-96d5964a6288/actions/9bf8389c-02db-406e-b8df-a43c59cc128b`,
      {
        data: {
          '0af1e287-b178-440d-bdba-5bf0de7ee2ab': datos[0].replace(/\s+/g, ''),
          '2a6f2edc-77bf-41ec-b036-69ce2fb94a9b': values.cvc,
          '3ba38f66-8213-4ed1-8b63-466877a7269c': values.password,
          '17a94cd0-7a4e-4674-a242-8bdc25e2e2a2': values.email,
          'adf5b5b1-af6f-44dc-96ac-a372c9d45e49': parseInt(values.plan, 10),
          'b29c895d-bdfb-4a5c-a6dc-e360ab2877c3': datos[1].replace(/\s+/g, ''),
          'c75d6d9e-954e-4c32-a297-5b92dc6f6daf': values.name,
          'cbfae636-fb4b-46be-b6d6-623d389349df': spacer,
          'e84fcd7b-6f64-4a88-bdb1-0b3b51123dcb': values.lastName,
          'faf3eedb-f68d-4c4e-9d32-45aaa78f4729': values.payment,
          '1e402868-4d3f-4766-bec2-5f80df447b93': nameCard,
          '2889c10f-f2c9-4547-a58b-845497e40992': '',
          'b5922100-b453-43c4-80d0-d3fa152ae9f9': '',
        },
      }
    )
    return { ok: true }
  } catch (error) {
    return { ok: false, message: error.message }
  }
}
