import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Container = styled.div<ColorProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #000;
  ${color};
`

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`

export const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  color: inherit;
  margin: 30px 0;
  @media screen and (max-width: 450px) {
    font-size: 26px;
    line-height: 32px;
    margin: 15px 0;
  }
`
