import styled from 'styled-components'
import { background, BackgroundProps, color, ColorProps } from 'styled-system'

export const Button = styled.button<ColorProps>`
  width: 170px;
  height: 50px;
  background: #fcd83a;
  border-radius: 2px;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.217732px;
  color: #000000;
  border: none;
  cursor: pointer;
  ${color};
`

export const Container = styled.div<ColorProps>`
  box-sizing: border-box;
  width: 100%;
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  ${color};
  @media screen and (max-width: 450px) {
    padding: 30px 0;
  }
`

export const Content = styled.article`
  margin: 50px 50px 25px;
  padding: 0 20%;
  h1 {
    font-weight: bold;
    font-size: 32px;
    line-height: 51px;
    color: inherit;
    margin: 0;
    @media screen and (max-width: 450px) {
      font-size: 22px;
      line-height: 35px;
    }
  }
  p {
    margin: 0;
    font-size: 20px;
    line-height: 34px;
    color: inherit;
    @media screen and (max-width: 450px) {
      font-size: 14px;
      line-height: 17px;
    }
  }
  @media screen and (max-width: 1024px) {
    padding: 0 15%;
  }
  @media screen and (max-width: 450px) {
    padding: 0 70px;
    margin: 50px 0 25px;
  }
`

export const CoverImage = styled.div<BackgroundProps>`
  width: 100%;
  height: 545px;
  ${background};
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 450px) {
    height: 300px;
  }
`

export const Footer = styled.div`
  width: 65%;
  align-self: center;
  margin-top: 30px;
  @media screen and (max-width: 1024px) {
    width: 75%;
  }
  @media screen and (max-width: 450px) {
    width: 90%;
  }
`

export const Header = styled.div`
  padding: 0 10%;
  margin-bottom: 45px;
  @media screen and (max-width: 1024px) {
    padding: 0 5%;
  }
  @media screen and (max-width: 450px) {
    margin-bottom: 20px;
  }
`

export const Image = styled.img`
  width: 60%;
  height: 570px;
  align-self: center;
  @media screen and (max-width: 1024px) {
    width: 70%;
    height: 470px;
  }
  @media screen and (max-width: 768px) {
    height: 370px;
  }
  @media screen and (max-width: 450px) {
    height: 250px;
    width: 90%;
  }
`

export const Text = styled.p`
  font-size: 20px;
  line-height: 34px;
  color: inherit;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 14px;
    line-height: 17px;
  }
`

export const TextArea = styled.textarea`
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  box-sizing: border-box;
  height: 104px;
  padding: 12px 20px;
  margin: 30px 0;
  width: 100%;
  align-self: center;
  resize: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: inherit;
  opacity: 0.7;
  @media screen and (max-width: 450px) {
    font-size: 15px;
  }
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 32px;
  line-height: 51px;
  color: inherit;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 22px;
    line-height: 35px;
  }
`
