import { FC } from 'react'
import { Container, Image, Text, Title } from './styled'

export type ShowcaseItem = {
  description?: string // Description to be displayed
  image: string // Image URL
  link: string // URL to redirect to
  target?: boolean // Should open in a new tab
  textColor?: string // Color to be used on text
  title?: string // Title to be displayed
  dangerHtml?: boolean
}

export const Item: FC<ShowcaseItem> = ({ description, image, link, target, textColor, title, dangerHtml }) => (
  <Container color={textColor} to={link} target={target ? 'blank' : undefined}>
    <Image src={image} />
    <Title>{title}</Title>
    {dangerHtml ? <Text dangerouslySetInnerHTML={{ __html: description || '' }} /> : <Text>{description}</Text>}
  </Container>
)
