import { createContext, useContext, useState, FC, ReactNode } from 'react'

type EmbedType = null | string

type SpotifyContextType = {
  embed: EmbedType
  setEmbed: (embed: EmbedType) => void
}

type Props = {
  children: ReactNode | ReactNode[]
}

const SpotifyContext = createContext<SpotifyContextType | null>(null)

const SpotifyProvider: FC<Props> = ({ children }: Props) => {
  const [embed, setEmbed] = useState<EmbedType>(null)

  return <SpotifyContext.Provider value={{ embed, setEmbed }}>{children}</SpotifyContext.Provider>
}

const useSpotify = (): SpotifyContextType => {
  const context = useContext(SpotifyContext)
  if (!context) {
    throw new Error('useSpotify should be used inside SpotifyProvider')
  }
  return context
}

export { useSpotify, SpotifyProvider }
