import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(19, 19, 19, 0.8);
`
