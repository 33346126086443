import { FC, useEffect, useRef } from 'react'
import { BrowserRouter, useHistory } from 'react-router-dom'
import { HeaderMenu } from 'views/components/HeaderMenu'
import { useDispatch, useSelector } from 'react-redux'
import { footerSelector, GET_RESOURCES, headerSelector } from 'ducks/staticContents'
import { Footer } from 'views/components/Footer'
import { SpotifyPlayer } from 'views/components/SpotifyPlayer'
import { LOGOUT, SET_PLANS, userSelector } from 'ducks/user'
import { ScrollTop } from 'views/components/ScrollTop'
import { getActive } from 'services/subscription/getActive'
import MainRouter from './Main'

const WithRouter: FC<any> = ({ headerProps }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const userState = useSelector(userSelector)
  const token = userState.get('token')

  const goToHome = () => {
    history.push('/')
  }

  const onLogout = () => {
    dispatch({ type: LOGOUT })
  }
  const email = useRef(userState.get('email'))
  const status = useRef(userState.get('status'))

  useEffect(() => {
    if (email.current && status.current === 'Activo') {
      getActive(email.current).then(({ data: memberships }) => {
        if (!memberships?.length) {
          dispatch({ type: LOGOUT })
        } else {
          dispatch({ type: SET_PLANS, payload: memberships })
        }
      })
    }

    const unregister = history.listen(() => {
      if (email.current && status.current === 'Activo')
        getActive(email.current).then(({ data: memberships }) => {
          if (!memberships?.length) {
            dispatch({ type: LOGOUT })
          } else {
            dispatch({ type: SET_PLANS, payload: memberships })
          }
        })
    })
    return unregister
  }, [dispatch, history])

  return (
    <HeaderMenu
      onClickLogo={goToHome}
      login={headerProps.login}
      backgroundColor={headerProps.backgroundColor}
      logo={headerProps.logo}
      register={headerProps.register}
      resizeLogo={headerProps.resizeLogo}
      options={headerProps.options}
      socials={headerProps.socials}
      handleLogout={onLogout}
      isAuthenticated={!!token}
    />
  )
}

const Router: FC = () => {
  const headerProps = useSelector(headerSelector)
  const footerProps = useSelector(footerSelector)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(GET_RESOURCES.trigger())
  }, [dispatch])

  return (
    <BrowserRouter>
      <ScrollTop />
      <WithRouter headerProps={headerProps} />
      <MainRouter />
      <SpotifyPlayer />
      <Footer
        logo={footerProps.logo}
        footerText={footerProps.footerText}
        newsletterUrl={footerProps.newsletterUrl}
        newsletterTitle={footerProps.newsletterTitle}
        navigationColumns={footerProps.columns}
        contactSocials={footerProps.socials}
        contactLogos={footerProps.contactLogos}
        contactSubtitle={footerProps.contactSubtitle}
        contactTitle={footerProps.contactTitle}
        contactEmail={footerProps.contactEmail}
      />
    </BrowserRouter>
  )
}
export default Router
