import { FC } from 'react'
import { FiPlay } from 'react-icons/fi'
import { Container, Content, Description, DurationLabel, Image, Label, PlayButton, Subtitle, Title } from './styled'

export type MediaGalleryItem = {
  dangerHtml?: boolean
  navigationLink?: string
  image: string // URL Path
  embed: string // Embed to the episode
  title: string // Title to be displayed
  duration?: string
  subtitle?: string // Subtitle to be displayed
  description?: string // Description to be displayed
  onClick: () => void
  backgroundColor?: string // Background Card Color
  textColor?: string // Text Card Color
  playButtonColor?: string // Play Button Color
  playButtonIconColor?: string // Play Button Icon Color
}

export const Item: FC<MediaGalleryItem> = ({
  image,
  title,
  subtitle,
  description,
  onClick,
  textColor,
  backgroundColor,
  playButtonColor,
  playButtonIconColor,
  dangerHtml,
  duration,
  navigationLink = '',
}) => (
  <Container to={navigationLink} backgroundColor={backgroundColor}>
    <Image src={image} />
    <Content>
      <Description color={textColor}>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        {dangerHtml ? <Label dangerouslySetInnerHTML={{ __html: description || '' }} /> : <Label>{description}</Label>}
        <DurationLabel>{duration}</DurationLabel>
      </Description>
      <PlayButton onClick={onClick} backgroundColor={playButtonColor} color={playButtonIconColor}>
        <div>
          <FiPlay className="icon" />
        </div>
      </PlayButton>
    </Content>
  </Container>
)
