import axios from 'axios'
import { tokenSelector } from 'ducks/user/selectors'
import store from 'lib/redux'

axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

axios.interceptors.request.use(
  (config: any) => {
    const state = store.getState()
    const token = tokenSelector(state)
    const isWompi = `${config.url}`.includes('wompi.co')

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.common.Authorization = `Bearer ${token}`
    } else if (process.env.REACT_APP_TFM_TOKEN) {
      // eslint-disable-next-line no-param-reassign
      config.headers.common.Authorization = `Bearer ${process.env.REACT_APP_TFM_TOKEN}`
    } else {
      // eslint-disable-next-line no-param-reassign
      delete config.headers.common.Authorization
    }

    if (isWompi) {
      // eslint-disable-next-line no-param-reassign
      delete config.headers.common.Authorization
    }

    return config
  },
  (error) => Promise.reject(error)
)
