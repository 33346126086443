import { ServiceResponse } from '@types'
import axios from 'axios'

export const getPages = async (): Promise<ServiceResponse> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_TFM_API}/graphql`, {
      query: `
        query {
          cmsPages(where: { status: "Publicado" }) {
            id
            slug
            page_component_ids (sort: "position_order:asc") {
              component
            }
          }
        }
      `,
    })
    return { ok: true, data: data.cmsPages }
  } catch (error) {
    return { ok: false, message: error.message }
  }
}
