import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

type ChartProps = {
  chartHeight: number
}

export const Chart = styled.div<ColorProps>`
  position: relative;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 305px;
  height: 305px;
  color: #ffffff;
  background-color: #54637a;
  overflow: hidden;
  ${color};
  @media screen and (max-width: 1024px) {
    height: 255px;
    width: 255px;
  }
  @media screen and (max-width: 768px) {
    height: 200px;
    width: 200px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 185px;
  }
`

export const ChartImage = styled.img<ChartProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -${({ chartHeight }) => 90 - chartHeight}%;
`

export const ChartValue = styled.h2`
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  display: flex;
  align-items: center;
  text-align: center;
  color: inherit;
  margin: 0;
  z-index: 1;
  @media screen and (max-width: 450px) {
    font-size: 36px;
    line-height: 43px;
  }
  .measure {
    font-weight: 900;
    font-size: 13.6505px;
    line-height: 17px;
    margin-top: 10px;
    margin-left: 5px;
  }
`

export const ChartLabel = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: inherit;
  margin: 0;
  z-index: 1;
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 14px;
    margin-top: 2px;
  }
`

export const Container = styled.div<ColorProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  ${color};
`

export const Content = styled.div`
  width: 80%;
  align-self: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 32px;
  margin-top: 40px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    grid-column-gap: 46px;
  }
  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
    width: 100%;
    grid-row-gap: 16px;
    margin-top: 20px;
  }
`

export const Subtitle = styled.h2`
  color: inherit;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  margin: 0;
  opacity: 0.4;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 36px;
  }
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  color: inherit;
  margin-bottom: 5px;
  @media screen and (max-width: 768px) {
    font-size: 26px;
    line-height: 32px;
  }
`
