import { CompliceProfile, CompliceProfileResponse } from '@types'
import axios from 'axios'

const compliceProfile = async (email: string): Promise<CompliceProfileResponse> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_TFM_API}/graphql`, {
      query: `
        query {
          lnfComplices(where: { email: "${email}" }) {
            id
            name
            status
          }
        }
      `,
    })
    const profile: CompliceProfile = data.lnfComplices[0]
    return { ok: true, data: profile }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}

export default compliceProfile
