import { FC, useCallback, useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { CLEAN_ERROR, LOGIN, userSelector } from 'ducks/user'
import {
  Button,
  Container,
  CustomLink,
  FormError,
  FormTitle,
  Subtitle,
  TextInput,
  TextInputError,
  Title,
} from './styled'

type FormValues = {
  email: string
  password: string
}

type FormErrorValues = {
  email?: string
  password?: string
}

const initialValues: FormValues = {
  email: '',
  password: '',
}

const initialErrors: FormErrorValues = {}

const validationSchema = yup.object({
  email: yup.string().email('Correo electronico es inválido').required('Correo electronico es requerido'),
  password: yup.string().required('Contraseña es requerida'),
})

type Link = {
  label: string
  path: string
  target?: boolean
}

type Props = {
  button?: string
  buttonColor?: string
  buttonTextColor?: string
  formTitle?: string
  subtitle?: string
  textColor?: string
  title?: string
  password?: string
  email?: string
  link?: Link
}

export const LoginForm: FC<Props> = ({
  button,
  buttonColor,
  buttonTextColor,
  formTitle,
  subtitle,
  textColor,
  title,
  password,
  email,
  link,
}) => {
  const user = useSelector(userSelector)

  const dispatch = useDispatch()

  const onSubmit = (values: FormValues) => {
    dispatch(LOGIN.trigger(values))
  }

  useEffect(() => {
    dispatch({ type: CLEAN_ERROR })
  }, [dispatch])

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues,
    initialErrors,
    onSubmit,
    validateOnChange: false,
    validationSchema,
  })

  const submit = useCallback(() => {
    handleSubmit()
  }, [handleSubmit])

  return (
    <Container color={textColor}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <FormTitle>{formTitle}</FormTitle>
      <FormError>{user.get('error')}</FormError>
      <TextInput
        textColor={textColor}
        placeholder={email || 'Mail'}
        name="email"
        value={values.email}
        onChange={handleChange}
        error={errors.email}
      />
      {errors.email && <TextInputError>{errors.email}</TextInputError>}
      <TextInput
        textColor={textColor}
        placeholder={password || 'Contraseña'}
        name="password"
        value={values.password}
        onChange={handleChange}
        error={errors.password}
        type="password"
      />
      {errors.password && <TextInputError>{errors.password}</TextInputError>}
      {link && (
        <CustomLink to={link.path} target={link.target ? 'blank' : undefined}>
          {link.label}
        </CustomLink>
      )}
      <Button
        disabled={user.get('loading')}
        type="submit"
        color={buttonTextColor}
        backgroundColor={buttonColor}
        onClick={submit}
      >
        {`${user.get('loading') ? 'Cargando...' : button || 'Enviar'}`}
      </Button>
    </Container>
  )
}
