// React
import { ChangeEvent, FC, useState } from 'react'
import {
  FeedbackButton,
  FeedbackContainer,
  FeedbackInput,
  FeedbackLeftContainer,
  FeedbackOption,
  FeedbackOptionInput,
  FeedbackOptions,
  FeedbackOrderedList,
  FeedbackRightContainer,
  FeedbackSubtitle,
  FeedbackTitle,
} from './styled'

export type Option = {
  label: string
  value: string
}

type Props = {
  question?: string
  options?: Option[]
  subtitle?: string
  title: string
  submitFeedback: (message: string, options: Option[]) => void
}

const FeedbackForm: FC<Props> = (props) => {
  const { question, options, submitFeedback, subtitle, title } = props
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([])
  const [message, setMessage] = useState('')

  const handleToggleOption = (option: Option) => {
    const filteredOption = selectedOptions.filter((opt) => opt.value === option.value)
    if (filteredOption.length > 0) {
      const newSelectedOptions = selectedOptions.filter((opt) => opt.value !== option.value)
      setSelectedOptions(newSelectedOptions)
    } else {
      setSelectedOptions((prevState) => [...prevState, option])
    }
  }

  const handleChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget
    setMessage(value)
  }

  const handleSubmit = () => {
    submitFeedback(message, selectedOptions)
  }

  const renderOption = (option: Option) => {
    const onClick = () => {
      handleToggleOption(option)
    }

    const filteredOptionsArray = selectedOptions.filter((opt) => opt.value === option.value)

    return (
      <FeedbackOption key={option.value}>
        <FeedbackOptionInput
          id={option.label}
          type="checkbox"
          checked={filteredOptionsArray.length > 0}
          onChange={onClick}
        />
        <label htmlFor={option.label}>{option.label}</label>
      </FeedbackOption>
    )
  }

  return (
    <FeedbackContainer>
      <FeedbackLeftContainer>
        <FeedbackTitle>{title}</FeedbackTitle>
        <FeedbackSubtitle>{subtitle}</FeedbackSubtitle>
        <FeedbackOrderedList>
          <li>{question}</li>
        </FeedbackOrderedList>
        <FeedbackOptions>{options?.map(renderOption)}</FeedbackOptions>
      </FeedbackLeftContainer>
      <FeedbackRightContainer>
        <FeedbackInput placeholder="Escribe tu comentario" value={message} onChange={handleChangeText} />
        <FeedbackButton onClick={handleSubmit} disabled={selectedOptions.length === 0}>
          Enviar
        </FeedbackButton>
      </FeedbackRightContainer>
    </FeedbackContainer>
  )
}

export default FeedbackForm
