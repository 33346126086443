import styled from 'styled-components'

export const Container = styled.div`
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 190px;
  min-height: 190px;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease-in-out;
  @media screen and (min-width: 1024px) {
    &:hover {
      -webkit-box-shadow: 0px 0px 15px 2px rgba(204, 204, 204, 1);
      -moz-box-shadow: 0px 0px 15px 2px rgba(204, 204, 204, 1);
      box-shadow: 0px 0px 15px 2px rgba(204, 204, 204, 1);
    }
  }
  @media screen and (max-width: 450px) {
    min-width: 190px;
  }
`

export const Image = styled.img`
  width: 85px;
  height: 85px;
  margin-bottom: 4px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Label = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: inherit;
  margin: 0;
`

export const Checkbox = styled.div`
  margin-right: 2px;
  cursor: pointer;
  color: inherit;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`
