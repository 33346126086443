import styled from 'styled-components'
import BackgroundPNG from 'assets/images/bg_overview.png'
import { background, BackgroundProps, color, ColorProps } from 'styled-system'

export const ActionButton = styled.button<ColorProps>`
  border: none;
  width: 60px;
  height: 60px;
  background: #fcd83a;
  color: #393939;
  font-size: 21px;
  border-radius: 50%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  margin: 0 10px;
  .icon {
    margin-left: 4px;
  }
  ${color};
  @media screen and (max-width: 450px) {
    width: 50px;
    height: 50px;
    font-size: 16px;
  }
`

export const ActionButtonsContainer = styled.div`
  display: flex;
  width: fit-content;
  justify-content: flex-end;
  align-self: flex-end;
  @media screen and (max-width: 450px) {
    width: 110px;
  }
`
type Props = {
  bgImage: string
}

export const Background = styled.div<Props>`
  background-image: url(${(props: any) => props.bgImage || BackgroundPNG});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  mix-blend-mode: color-burn;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const Container = styled.div<ColorProps>`
  padding: 100px 13% 100px 16%;
  position: relative;
  color: #fff;
  ${color};
  display: grid;
  grid-template-areas:
    'left right'
    'footer footer';
  @media screen and (max-width: 1024px) {
    padding: 100px 8% 100px 10%;
  }
  @media screen and (max-width: 768px) {
    padding: 125px 5% 50px;
    grid-template-areas:
      'left right'
      'footer right';
  }
  @media screen and (max-width: 450px) {
    padding: 125px 16px 50px;
    grid-row-gap: 20px;
    grid-template-areas:
      'left'
      'right'
      'footer';
  }
`

export const Description = styled.p<ColorProps>`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: inherit;
  ${color};
`

export const FooterContainer = styled.div`
  grid-area: footer;
  position: relative;
`

export const Image = styled.div<BackgroundProps>`
  ${background};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: white;
  width: 400px;
  height: 400px;
  padding: 27px;
  @media screen and (max-width: 768px) {
    width: 200px;
    height: 200px;
  }
  @media screen and (max-width: 450px) {
    width: calc(100% - 40px);
    height: 323px;
    padding: 20px;
  }
`

export const Label = styled.p<ColorProps>`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0657692px;
  color: inherit;
  ${color};
`

export const LeftContainer = styled.div`
  position: relative;
  grid-area: left;
  width: 100%;
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #091d43;
  mix-blend-mode: normal;
  height: 100%;
  width: 100%;
  z-index: -1;
`

export const RateButton = styled.button<ColorProps>`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #ffffff;
  padding: 12px 18px;
  border: 1px solid #ffffff;
  background-color: transparent;
  margin-right: 18px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: Mulish, monospace;
  &.RateIsActive {
    background-color: #fff;
    color: #091d43;
  }
  &.HideRateButton {
    visibility: hidden;
  }
  &:hover {
    background-color: #fff;
    color: #091d43;
  }
  ${color};
`

export const RateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`

export const RateTitle = styled.h1<ColorProps>`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.1px;
  color: inherit;
  margin: 8px 0 6px;
  ${color};
`

export const RightContainer = styled.div`
  grid-area: right;
  position: relative;
  margin-left: 44px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 450px) {
    width: 100%;
    margin-left: 0;
  }
`

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 450px) {
    margin-left: 20px;
    margin-top: 4px;
  }
`

export const SocialContent = styled.div`
  @media screen and (max-width: 450px) {
    display: flex;
    align-items: center;
  }
`

export const SocialLink = styled.a<ColorProps>`
  margin-right: 20px;
  color: inherit;
  font-size: 18px;
  ${color}
`

export const SocialTitle = styled.h2<ColorProps>`
  font-weight: bold;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.1px;
  color: inherit;
  ${color};
  @media screen and (max-width: 450px) {
    margin: 0;
  }
`

export const Subtitle = styled.h2<ColorProps>`
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #ffd465;
  ${color};
  @media screen and (max-width: 450px) {
    font-size: 14px;
    line-height: 13px;
  }
`

export const TextContent = styled.div``

export const Title = styled.h1<ColorProps>`
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0.2px;
  color: inherit;
  margin: 0;
  ${color};
  @media screen and (max-width: 450px) {
    font-size: 26px;
    line-height: 29px;
  }
`
