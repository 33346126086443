import styled from 'styled-components'
import { background, BackgroundProps, color, ColorProps } from 'styled-system'

export const Image = styled.img`
  max-width: 445.42px;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 450px) {
    height: 250px;
  }
`

export const Container = styled.div<ColorProps & BackgroundProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  padding: 150px 20% 100px;
  background-color: white;
  ${background};
  background-repeat: no-repeat;
  background-size: cover;
  ${color};
  @media screen and (max-width: 1024px) {
    width: 90%;
    padding: 150px 5% 100px;
  }
  @media screen and (max-width: 768px) {
    padding: 150px 5% 50px;
    flex-direction: column;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 12px;
  margin-left: 70px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
`

export const TextContent = styled.div<ColorProps>`
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${color};
`

export const Text = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: inherit;
  margin: 0;
  @media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 19px;
    margin: 8px 0 24px;
  }
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: inherit;
  margin: 0;
  margin-bottom: 5px;
  @media screen and (max-width: 768px) {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 0;
  }
`
