import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ColorProps, color, FlexboxProps, flexbox, BackgroundProps, background } from 'styled-system'

type AnimatedProps = {
  animate?: boolean
}

type ButtonProps = {
  backgroundColor?: string
  textColor?: string
}

export const BottomContainer = styled.div<AnimatedProps>`
  height: 64px;
  background-color: #fff;
  opacity: ${({ animate }) => (animate ? 0 : 0.7)};
  transition: opacity 400ms ease-in-out;
  bottom: -64px;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
`

export const Container = styled.div`
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 2;
`

export const Logo = styled.div<BackgroundProps & AnimatedProps>`
  cursor: pointer;
  height: 53px;
  transition: width 400ms ease-in-out;
  background-repeat: no-repeat;
  width: ${({ animate }) => (animate ? '18px' : '85px')};
  ${background};
  @media screen and (max-width: 1024px) {
    width: ${({ animate }) => (animate ? '18px' : '100px')};
  }
`

export const LogoutButton = styled.button<ColorProps>`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #000000;
  border: none;
  width: fit-content;
  background-color: transparent;
  cursor: pointer;
  margin: 0 15px;
  ${color}
  &:hover {
    text-decoration: underline;
  }
`

export const LinkContainer = styled.div`
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
`

export const MenuLink = styled(Link)<ColorProps>`
  box-sizing: content-box;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #000000;
  text-overflow: ellipsis;
  padding: 0 8px;
  ${color}
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 950px) {
    font-size: 12px;
  }
`

export const MenuNativeLink = styled.a<ColorProps>`
  box-sizing: content-box;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #000000;
  text-overflow: ellipsis;
  padding: 0 8px;
  ${color}
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 950px) {
    font-size: 12px;
  }
`

export const MenuOptions = styled.div<AnimatedProps>`
  padding: 15px;
  display: flex;
  align-items: center;
  overflow: visible;
  flex-wrap: nowrap;
  flex-grow: 1;
  max-width: 650px;
  justify-content: space-between;
  z-index: 1;
  position: relative;
  transition: transform 400ms ease-in-out;
  transform: ${({ animate }) => (animate ? 'translate(0, 0)' : 'translate(30px, 72px)')};
`

export const ProfileImage = styled.img`
  width: 18px;
  height: 22px;
`

export const RegisterOutline = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: -3px;
  right: -3px;
`

export const RegisterButton = styled(Link)<ButtonProps>`
  position: relative;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  white-space: nowrap;
  padding: 10px 15px;
  color: ${({ textColor }) => textColor || '#fcd83a'};
  background-color: ${({ backgroundColor }) => backgroundColor || '#131313'};
  ${RegisterOutline} {
    border: 1px solid ${({ backgroundColor }) => backgroundColor || '#131313'};
  }
`

export const Row = styled.div<FlexboxProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  ${flexbox}
`

export const SocialsContainer = styled.div<AnimatedProps>`
  display: flex;
  transition: opacity 400ms ease-in-out;
  flex-direction: row;
  position: absolute;
  right: -125px;
  opacity: ${({ animate }) => (animate ? 0 : 1)};
  overflow: hidden;
`

export const SocialLink = styled.a<AnimatedProps & ColorProps>`
  margin: 0 10px;
  color: #000000;
  font-size: 18px;
  transition: height 400ms ease-in-out;
  height: ${({ animate }) => (animate ? '0px' : '18px')};
  ${color}
`

export const TopContainer = styled.div<ColorProps>`
  padding: 15px 5%;
  background-color: #fcd83a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 50px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  ${color};
  @media screen and (max-width: 1024px) {
    padding: 15px 5%;
  }
`
