import React, { FC } from 'react'
import { FiPlay } from 'react-icons/fi'
import { MdFavoriteBorder } from 'react-icons/md'
import {
  Container,
  ContainerView,
  Content,
  ContentLink,
  FavButton,
  Image,
  Label,
  PlayButton,
  Subtitle,
  Text,
  Title,
} from './styled'

export type CardFeaturedItem = {
  description?: string // Description to be displayed
  details?: string // Details to be displayed on modal
  showDetails?: boolean
  unfavColor?: string // No Favorite icon color
  favColor?: string // Favorited Icon color
  favorite?: boolean // Item is favorited
  image: string // Image URL
  link?: string // Link to redirect
  subtitle?: string // Subtitle to be displayed
  target?: boolean // Should open in a new window
  title: string // Title to be displayed
  midiaDisplay?: boolean
  onClick?: () => void
  showFav?: boolean
  handleFav?: () => void
  handlePlay?: () => void
  id?: string
  parent?: string
  keyId?: string
  linkEpisode?: string
}

export const Item: FC<CardFeaturedItem> = ({
  description,
  unfavColor,
  favColor,
  favorite,
  image,
  link,
  subtitle,
  target,
  title,
  midiaDisplay,
  onClick,
  showFav,
  handleFav,
  handlePlay,
}) => {
  return midiaDisplay ? (
    <Container>
      <Image background={`url(${image})`}>
        <PlayButton onClick={handlePlay}>
          <FiPlay className="icon" />
        </PlayButton>
      </Image>
      <Content>
        <ContentLink to={link || ''} target={target ? 'blank' : undefined}>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <Text>{description}</Text>
        </ContentLink>
        {showFav && (
          <FavButton color={unfavColor} onClick={handleFav}>
            {favorite ? <MdFavoriteBorder color={favColor || '#F80000'} /> : <MdFavoriteBorder />}
          </FavButton>
        )}
      </Content>
    </Container>
  ) : (
    <ContainerView onClick={onClick} style={{ cursor: onClick ? 'pointer' : 'none' }}>
      <Image background={`url(${image})`} />
      <Content viewOnly>
        <Title>{title}</Title>
        <Label>{subtitle}</Label>
      </Content>
    </ContainerView>
  )
}
