import { ServiceResponse } from '@types'
import axios from 'axios'

const query = `
  query {
    team: lnfTeams {
      id
      order: position_order
      title: name
      description
      subtitle: job_role
      image
    }
    lnfGeneralTexts {
      titleSignup: title_signup
      subtitleSignup: subtitle_signup
      buttonSignup: text_button_signup
      created_at
      id
      premium_subscri_text
      subscri_text
      newsletter_url
      discord: chat_discord_url
      gratitude: gratitude_subtitle
      loginText: login_text
      loginTitle: login_title
      menu
      footer
    }
    homePage: cmsPages(where: {id: 7}) {
      id
      components: page_component_ids {
       tfm_id
       id
       component
      }
    }
    aboutUsPage: cmsPages(where: {id: 34}) {
      id
      components: page_component_ids {
       tfm_id
       id
       component
      }
    }
    newsletterPage: cmsPages(where: {id: 37}) {
      id
      components: page_component_ids {
       tfm_id
       id
       component
      }
    }
    contactUs: cmsPages(where: {id: 35}) {
      id
      components: page_component_ids {
       tfm_id
       id
       component
      }
    }
    login: cmsPages(where: {id: 38}) {
      id
      components: page_component_ids {
       tfm_id
       id
       component
      }
    }
  }
`

export const getAll = async (): Promise<ServiceResponse> => {
  try {
    const {
      data: {
        data: {
          team,
          contactUs: [{ components: contactUsComponents }],
          login: [{ components: loginComponents }],
          newsletterPage: [{ components: newsletterComponents }],
          homePage: [{ components: homeComponents }],
          aboutUsPage: [{ components: aboutUsComponents }],
          lnfGeneralTexts: [
            {
              menu: { payload: header },
              footer: { payload: footer },
              premium_subscri_text: subscribeText,
              subscri_text: subscribeButtonText,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              newsletter_url,
              discord,
              gratitude,
              loginText,
              loginTitle,
              titleSignup,
              subtitleSignup,
              buttonSignup,
            },
          ],
        },
      },
    } = await axios.post(`${process.env.REACT_APP_TFM_API}/graphql`, { query })

    return {
      ok: true,
      data: {
        team,
        header,
        footer,
        loginComponents,
        contactUsComponents,
        homeComponents,
        aboutUsComponents,
        newsletterComponents,
        userBanner: {
          discord,
          gratitude,
          loginText,
          loginTitle,
        },
        generalSubscription: {
          subscribeText,
          subscribeButtonText,
          newsletter_url,
        },
        singupScreen: {
          titleSignup,
          subtitleSignup,
          buttonSignup,
        },
      },
    }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}
