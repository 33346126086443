import React, { FC } from 'react'
import HtmlRender from './HtmlRender'
import { Container, Text, Title } from './styled'

type Props = {
  title?: string
  text: string
  textColor?: string
}

export const Article: FC<Props> = ({ title, textColor, text }) => (
  <Container color={textColor}>
    <Title>{title}</Title>
    <Text>
      <HtmlRender>{text}</HtmlRender>
    </Text>
  </Container>
)
