import React, { FC } from 'react'
import { Highlight } from 'views/components/Highlight'

export const TextBlock: FC<any> = ({ message, title, linkLabel, link }) => (
  <Highlight
    button={linkLabel}
    message={title}
    content={message}
    link={link}
    buttonColor="black"
    buttonTextColor="white"
    backgroundImage="https://i.imgur.com/QXXBnKY.png"
    noBottomSpacing
  />
)
