import { userSelector } from 'ducks/user'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ExclusiveContent } from 'views/components/ExclusiveContent'
import { Greeting } from 'views/components/Greeting'
import { createComment } from 'services/comment/createComment'
import { useHistory, useRouteMatch } from 'react-router-dom'
import get from 'lodash/get'
import { getExclusiveContentDetails } from 'services/getExclusiveContentDetails'
import { userBannerSelector } from 'ducks/staticContents'
import { Container } from './styled'

export const Exclusive: FC = () => {
  const banner = useSelector(userBannerSelector)

  const [content, setContent] = useState<any>({ ok: true })
  const userState = useSelector(userSelector)
  const name = userState.get('name')
  const id = userState.get('id')

  const history = useHistory()

  const url: any = useRouteMatch('/exclusive/:contentID')
  const { contentID } = get(url, 'params', {})

  useEffect(() => {
    getExclusiveContentDetails(contentID).then(setContent)
  }, [setContent, contentID])

  const handleSubmit = (comment: string) => {
    createComment({
      complice: id,
      content: contentID,
      comment,
    })
  }

  if (!content.ok) {
    history.push('/')
  }

  return (
    <Container>
      <Greeting
        greeting="Hola"
        message={banner.gratitude}
        name={name}
        link={{
          label: 'Gestionar suscripción',
          path: '/subscription-management',
        }}
        buttonLink={{
          label: 'Chat Discord',
          path: banner.discord,
          target: true,
        }}
      />
      <ExclusiveContent
        onSubmit={handleSubmit}
        content={content?.description}
        coverImage={get(content, ['main_image', 0, 'virtualPath'])}
        // image="https://i.imgur.com/XMWqGhk.png"
        title={content?.title}
        subtitle={content?.subtitle}
      />
    </Container>
  )
}
