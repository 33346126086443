import { List } from 'immutable'
import createImmutableSelector from 'lib/immutable/selector'
import { IUser } from '../models'

// TODO: import Store type and add reducer type in Store interface
const getUser = (state: any): IUser => state.get('userReducer')

export const userSelector = createImmutableSelector(getUser, (user) => user)

const getFavoriteEpisodes = (state: any): IUser => state.get('userReducer')

export const favoriteEpisodesSelector = createImmutableSelector(getFavoriteEpisodes, (user) =>
  user.get('favoriteEpisodes')
)

export const membershipsSelector = createImmutableSelector(getFavoriteEpisodes, (user) => user.get('memberships'))

export const tokenSelector = createImmutableSelector(getUser, (user) => user.get('token'))

export const rolsSelector = createImmutableSelector(getUser, (user): any => user.get('rols') || List())
