import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
`

export const CreditCardContainer = styled.div`
  padding: 25px 10%;
  width: 80%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    padding: 25px 5%;
    width: 90%;
  }
`
export const TextInputContainer = styled.div`
  width: 100%;
`

export const TextInput = styled.input`
  border: 1px solid #c8c8c8;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  padding: 11px 12px;
  color: #909090;
`
export const TextInputError = styled.p`
  margin: 0;
  margin-top: 4px;
  margin-left: 12px;
  color: #f2392c;
  font-size: 12px;
`
