import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-self: center;
  @media screen and (max-width: 768px) {
    width: 50%;
    margin: 0 auto;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Image = styled.img`
  height: 50px;
  width: 50px;
  margin-bottom: 20px;
`

export const LinkButton = styled(Link)<ColorProps>`
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  text-decoration-line: underline;
  color: #55aba8;
  text-decoration: underline;
  justify-self: flex-end;
  ${color}
`

export const Message = styled.p<ColorProps>`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #373f41;
  ${color};
  @media screen and (max-width: 450px) {
    width: 70%;
  }
`

export const Title = styled.h2<ColorProps>`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #393939;
  width: 60%;
  margin: 0;
  ${color};
  @media screen and (max-width: 1024px) {
    width: 65%;
  }
  @media screen and (max-width: 450px) {
    width: 55%;
  }
`
