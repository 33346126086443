import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Container = styled.div<ColorProps>`
  padding: 25px 10%;
  width: 80%;
  display: flex;
  flex-direction: column;
  ${color};
  @media screen and (max-width: 768px) {
    padding: 25px 5%;
    width: 90%;
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  margin-top: 20px;
  @media screen and (max-width: 1024px) {
    grid-gap: 12px;
  }
  @media screen and (max-width: 450px) {
    overflow-x: scroll;
    flex-wrap: nowrap;
    padding: 10px 1px;
  }
`

export const Title = styled.h1`
  color: inherit;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 26px;
    line-height: 31px;
  }
`

export const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
  margin: 0;
  color: inherit;
  @media screen and (max-width: 450px) {
    font-size: 15px;
    line-height: 11px;
  }
`
