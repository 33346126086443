import { useSpotify } from 'hooks/useSpotifyPlayer'
import { FC, useCallback, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'

import { CloseButton, Container } from './styled'

export const SpotifyPlayer: FC = () => {
  const { embed, setEmbed } = useSpotify()
  const [showClose, setShowClose] = useState(false)

  const onClose = useCallback(() => {
    setEmbed(null)
    setShowClose(false)
  }, [setEmbed])

  const showCloseButton = () => {
    setShowClose(true)
  }

  return embed ? (
    <Container>
      {showClose && (
        <CloseButton onClick={onClose}>
          <div>
            <AiOutlineClose />
          </div>
        </CloseButton>
      )}
      <iframe
        src={embed}
        title="player"
        width="100%"
        height="152"
        frameBorder="0"
        allow="encrypted-media"
        onLoad={showCloseButton}
      />
    </Container>
  ) : null
}
