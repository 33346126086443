import ObjectMapper from 'object-mapper'
import { singleFile } from 'lib/propsParser/singleFile'

export const cardMapper = (original: any): any =>
  ObjectMapper.merge(original, original, {
    image: {
      key: 'image',
      transform: singleFile,
    },
  })
