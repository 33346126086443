import { ServiceResponse } from '@types'
import axios from 'axios'

const PLAN_FIELD_UUID = 'id'

export const cancel = async (id: string): Promise<ServiceResponse> => {
  try {
    // SUBSCRIPTION SOLUTION
    await axios.post(
      `${process.env.REACT_APP_TFM_API}/flow/solution/b8542a30-2949-474b-a649-96d5964a6288/actions/e07a289f-5bc7-486d-9538-ad671a97b4ea`,
      {
        data: {
          [PLAN_FIELD_UUID]: id,
        },
      }
    )
    return { ok: true }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}
