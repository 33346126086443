import ObjectMapper from 'object-mapper'
import { singleFile } from 'lib/propsParser/singleFile'

export const newsletterMapper = (original: any): any =>
  ObjectMapper.merge(original, original, {
    background: {
      key: 'background',
      transform: singleFile,
    },
  })
