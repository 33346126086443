// React & React Native
import { FC } from 'react'

type Props = {
  color?: string
}

const Loading: FC<Props> = ({ color }) => {
  return (
    <svg
      style={{
        margin: 'auto',
        marginLeft: '10px',
        background: 'transparent',
        display: 'block',
        shapeRendering: 'auto',
      }}
      width="14px"
      height="14px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        r="32"
        strokeWidth="8"
        stroke={color || 'black'}
        strokeDasharray="50.26548245743669 50.26548245743669"
        fill="none"
        strokeLinecap="round"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          keyTimes="0;1"
          values="0 50 50;360 50 50"
        />
      </circle>
    </svg>
  )
}

export default Loading
