import { FC, useEffect, useState } from 'react'
import * as yup from 'yup'
import { MdCheck, MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import { useFormik } from 'formik'

import Loading from './Loading'
import {
  Button,
  Checkbox,
  Container,
  Input,
  Label,
  Row,
  TitleContainer,
  TitleOne,
  TitleTwo,
  TitleThree,
  InputContainer,
  InputError,
} from './styled'

type MailChimpData = {
  EMAIL: string
  NAME: string
}

type Props = {
  backgroundColor?: string // Background color of form container
  buttonColor?: string // Background Button Color
  buttonTextColor?: string // Text Button Color
  buttonText?: string // Button Text Value
  color?: string // Primary Color used
  confirmMessage?: string // Confirm Message displayed beside checkbox.
  email?: string // Email placeholder
  name?: string // Name Placeholder
  status?: string // Subscribe Status
  subscribe: ({ EMAIL, NAME }: MailChimpData) => void // Subscription handler from mailchimp
  titleBottom?: string // Title displayed on the bottom of title block
  titleMiddle?: string // Title displayed on the middle of title block
  titleTop?: string // Title displayed on the top of title block
}

type FormValues = {
  checked: boolean
  email: string
  name: string
}

type FormErrorValues = {
  checked?: string
  email?: string
  name?: string
}

const initialValues: FormValues = {
  checked: false,
  email: '',
  name: '',
}

const initialErrors: FormErrorValues = {}

const validationSchema = yup.object({
  checked: yup.bool().oneOf([true]),
  email: yup.string().email().required('Correo electronico es requerido'),
  name: yup.string().required('Nombre es requerido'),
})

export const Form: FC<Props> = (props) => {
  const {
    backgroundColor,
    buttonColor,
    buttonTextColor,
    buttonText,
    color,
    confirmMessage,
    email,
    name,
    status,
    subscribe,
    titleBottom,
    titleMiddle,
    titleTop,
  } = props
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (status === 'success') {
      setSuccess(true)
    } else {
      setSuccess(false)
    }
  }, [status])

  const onSubmit = (values: FormValues) => {
    subscribe({
      EMAIL: values.email,
      NAME: values.name,
    })
  }

  const { values, errors, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    initialErrors,
    onSubmit,
    validateOnChange: false,
    validationSchema,
  })

  const handleCheck = () => {
    setFieldValue('checked', !values.checked)
  }

  const submit = () => handleSubmit()

  return (
    <Container backgroundColor={backgroundColor} color={color}>
      <TitleContainer>
        <TitleOne>{titleTop}</TitleOne>
        <TitleTwo>{titleMiddle}</TitleTwo>
        <TitleThree>{titleBottom}</TitleThree>
      </TitleContainer>
      <InputContainer>
        <Input
          placeholder={name || 'Nombre'}
          textColor={color}
          onChange={handleChange}
          name="name"
          value={values.name}
          error={errors.name}
        />
        {errors.name && <InputError>{errors.name}</InputError>}
      </InputContainer>
      <InputContainer>
        <Input
          placeholder={email || 'Email'}
          textColor={color}
          onChange={handleChange}
          name="email"
          value={values.email}
          error={errors.email}
        />
        {errors.email && <InputError>{errors.email}</InputError>}
      </InputContainer>
      <Row>
        <Checkbox color={errors.checked ? '#f2392c' : color} onClick={handleCheck}>
          {values.checked ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
        </Checkbox>
        <Label>{confirmMessage}</Label>
      </Row>
      {success ? (
        <Button backgroundColor="#0eb52a" color={buttonTextColor} type="button" disabled>
          suscrito <MdCheck style={{ marginLeft: '10px' }} />
        </Button>
      ) : (
        <Button
          backgroundColor={buttonColor}
          color={buttonTextColor}
          type="button"
          onClick={submit}
          disabled={status === 'sending'}
        >
          {status === 'sending' ? (
            <>
              Enviando
              <Loading color={buttonTextColor} />
            </>
          ) : (
            buttonText || 'suscribirme'
          )}
        </Button>
      )}
    </Container>
  )
}
