import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Button = styled.button<ColorProps>`
  border: none;
  background: #eef2f9;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #131313;
  width: 100%;
  align-self: center;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${color};
  &:hover {
    color: #505050;
  }
`

export const ScrollFeatures = styled.div`
  overflow-y: auto;
  max-height: 100%;
  flex: 1;
`

export const FeaturesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

type ContainerProps = {
  featured?: boolean
}

export const Container = styled.div<ColorProps & ContainerProps>`
  margin-top: 20px;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 40px 24px 24px;
  min-height: 420px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: ${({ featured }) => (featured ? 'scale(1.01) translateY(-16px)' : 'none')};
  ${color};
  transition: all 200ms ease-in-out;
  &:hover {
    -webkit-box-shadow: 0px 0px 15px 2px rgba(204, 204, 204, 1);
    -moz-box-shadow: 0px 0px 15px 2px rgba(204, 204, 204, 1);
    box-shadow: 0px 0px 15px 2px rgba(204, 204, 204, 1);
  }
  @media screen and (max-width: 1024px) {
    transform: none;
    width: 260px;
    scroll-snap-align: center;
    scroll-snap-stop: always;
  }
  @media screen and (max-width: 450px) {
    padding: 24px;
  }
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 450px) {
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    justify-content: space-between;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  color: inherit;
`

export const Text = styled.p`
  margin: 0;
  color: inherit;
  font-size: 16px;
  line-height: 19px;
  margin-left: 12px;
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: inherit;
`

export const Value = styled.h2`
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: inherit;
  opacity: 0.96;
  .unit {
    font-size: 17px;
    line-height: 21px;
  }
  .period {
    margin-left: 4px;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.68;
  }
`
