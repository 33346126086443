import ObjectMapper from 'object-mapper'
import map from 'lodash/map'
import { cardMapper } from 'lib/propsParser/components/Card'

export const inlineCardsMapper = (original: any): any =>
  ObjectMapper.merge(original, original, {
    items: {
      key: 'items',
      transform: (value: any) => map(value, (item: any) => cardMapper(item?.item?.payload)),
    },
  })
