import { FC } from 'react'
import { Card } from './Card'
import { CardContainer, Container, Content, Title } from './styled'

type CardType = {
  image: string // Image URL to display on the card
  name: string // Name to display on the card
  path: string // Link to redirect when clicked
  target?: boolean // Attribute to open in a new tab
}

type Props = {
  backgroundColor?: string // Background Color of the section
  leftCard?: CardType // Card to be displayed vertically
  rightCards?: CardType[] // Cards to be displayed horizontally
  textColor?: string // Text color to be displayed
  title?: string // Title to be displayed
}

export const CardCollection: FC<Props> = (props) => {
  const { backgroundColor, leftCard, rightCards, textColor, title } = props
  return (
    <Container backgroundColor={backgroundColor}>
      <Title color={textColor}>{title}</Title>
      <Content>
        <CardContainer>
          <Card
            backgroundColor={backgroundColor}
            image={leftCard?.image || ''}
            link={leftCard?.path || ''}
            text={leftCard?.name || ''}
            target={!!leftCard?.target}
            textColor={textColor}
          />
        </CardContainer>

        {rightCards?.map((card) => (
          <CardContainer key={card.image}>
            <Card
              backgroundColor={backgroundColor}
              image={card.image}
              link={card.path}
              text={card.name}
              target={card.target}
              textColor={textColor}
            />
          </CardContainer>
        ))}
      </Content>
    </Container>
  )
}
