import { ServiceResponse } from '@types'
import axios from 'axios'

export const changePassword = async (resetToken: string, password: string): Promise<ServiceResponse> => {
  try {
    const resp = await axios.post(`${process.env.REACT_APP_TFM_API}/auth/reset-password`, { resetToken, password })
    if (resp.status === 200) {
      return { ok: true }
    }
    throw new Error('Error in password reset')
  } catch (error) {
    return { ok: false, message: error.message }
  }
}
