import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Button = styled.button<ColorProps>`
  border: none;
  color: black;
  background-color: #fcd83a;
  border-radius: 2px;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.22px;
  padding: 16px;
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  cursor: pointer;
  ${color};
  &:disabled {
    cursor: not-allowed;
  }
`

export const Container = styled.div<ColorProps>`
  display: flex;
  flex-direction: column;
  color: black;
  width: 50%;
  padding-top: 25px;
  ${color};
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const CustomLink = styled(Link)`
  color: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  opacity: 0.6;
`

export const FormTitle = styled.h2`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: inherit;
  opacity: 0.7;
  @media screen and (max-width: 450px) {
    margin: 0;
  }
`

export const FormError = styled.strong`
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.3px;
  opacity: 0.7;
  color: red;
  @media screen and (max-width: 450px) {
    margin: 0;
  }
`

export const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: inherit;
  margin: 0;
  margin-bottom: 19px;
  @media screen and (max-width: 450px) {
    font-size: 14px;
    line-height: 17px;
  }
`

type TextColor = {
  error?: string
  textColor?: string
}

export const TextInput = styled.input<TextColor>`
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  box-sizing: border-box;
  color: ${({ textColor }) => textColor || 'inherit'};
  border: 1px solid ${({ error }) => (error ? '#f2392c' : '#c8c8c8')};
  border-radius: 5px;
  padding: 11px 13px;
  margin: 8px 0;
  @media screen and (max-width: 450px) {
    font-size: 15px;
    line-height: 22px;
  }
`

export const TextInputError = styled.p`
  margin: 0;
  margin-bottom: 6px;
  color: #f2392c;
  font-size: 12px;
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  color: inherit;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 38px;
    line-height: 46px;
  }
`
