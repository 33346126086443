import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Container = styled.div<ColorProps>`
  background-color: white;
  padding: 5% 0 7%;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${color}
`

export const CardContainer = styled.div`
  display: flex;
  overflow: hidden;
  padding: 0.5rem;
`

export const Content = styled.div`
  box-sizing: border-box;
  align-self: center;
  width: 60%;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1.5fr;
  grid-template-rows: 315px 315px;
  grid-template-areas:
    'vertical top'
    'vertical bottom';
  @media screen and (min-width: 1920px) {
    width: 50%;
  }
  @media screen and (max-width: 1024px) {
    width: 83%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 315px 315px 315px;
    grid-template-areas:
      'vertical'
      'top'
      'bottom';
  }

  & > ${CardContainer} {
    &:nth-child(1) {
      grid-area: vertical;
    }
    &:nth-child(2) {
      grid-area: top;
    }
    &:nth-child(3) {
      grid-area: bottom;
    }
  }
`

export const LeftContent = styled.div`
  margin-right: 30px;
  width: 290px;
  height: 630px;
  display: flex;
  box-sizing: border-box;

  @media screen and (min-width: 1920px) {
    width: 320px;
    height: 630px;
  }
  @media screen and (min-width: 1024px) {
    width: 320px;
    height: 630px;
  }
  @media screen and (max-width: 768px) {
    margin: 0;
    width: 100%;
    height: 300px;
    padding: 20px 0;
  }
  @media screen and (max-width: 450px) {
    height: 210px;
  }
`

export const RightContent = styled.div`
  display: flex;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  height: 630px;
`

export const Title = styled.h1<ColorProps>`
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  text-align: center;
  color: #000000;
  align-self: flex-start;
  margin: 0;
  margin-bottom: 30px;
  margin-left: 10%;
  ${color};
  @media screen and (max-width: 1024px) {
    margin-left: 5%;
  }
  @media screen and (max-width: 450px) {
    margin-left: 16px;
    text-align: start;
    font-size: 38px;
    line-height: 38px;
    width: 80%;
  }
`
