/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react'
import { Item, ShowcaseItem } from './Item'
import { Container, Grid, Subtitle, Title } from './styled'

type Props = {
  backgroundColor?: string // Background color to be displayed
  backgroundImage?: string // Background Image URL
  headerTextColor?: string // Color to be used in Title and Subtitle
  items?: ShowcaseItem[] // Item data to be displayed as cards
  textColor?: string // Color to be used in the items cards
  title?: string // Title to be displayed
  subtitle?: string // Subtitle to be displayed
  dangerHtml?: boolean
}

export const Showcase: FC<Props> = (props) => {
  const { backgroundColor, backgroundImage, headerTextColor, items, textColor, title, subtitle, dangerHtml } = props
  return (
    <Container backgroundColor={backgroundColor} background={`url(${backgroundImage})`}>
      <Title color={headerTextColor}>{title}</Title>
      <Subtitle color={headerTextColor}>{subtitle}</Subtitle>
      <Grid>
        {items?.map((item) => (
          <Item dangerHtml={dangerHtml} key={item.link} {...item} textColor={textColor} />
        ))}
      </Grid>
    </Container>
  )
}
