import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Button = styled.button<ColorProps>`
  border: none;
  color: black;
  background-color: #fcd83a;
  border-radius: 2px;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.22px;
  padding: 16px;
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  ${color};
`

export const ErrorStatus = styled.div`
  color: red;
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
`

type TextColor = {
  error?: string
  textColor?: string
}

export const TextInput = styled.input<TextColor>`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: ${({ textColor }) => textColor || 'inherit'};
  border: 1px solid ${({ error }) => (error ? '#f2392c' : '#c8c8c8')};
  border-radius: 5px;
  padding: 11px 13px;
  margin: 8px 0;
  @media screen and (max-width: 450px) {
    font-size: 15px;
    line-height: 22px;
  }
`

export const TextInputError = styled.p`
  margin: 0;
  margin-bottom: 6px;
  color: #f2392c;
  font-size: 12px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  @media screen and (max-width: 1024px) {
    margin: 0px 0;
  }
  @media screen and (max-width: 450px) {
    align-items: flex-start;
  }
`

export const Checkbox = styled.button<ColorProps>`
  margin-right: 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${color}
`

export const Label = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: inherit;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 12px;
  }
`

export const CustomLink = styled(Link)`
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`
