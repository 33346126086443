import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { background, BackgroundProps, color, ColorProps } from 'styled-system'

export const Button = styled(Link)<ColorProps>`
  padding: 15px 25px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  color: #000000;
  background-color: #fcd83a;
  cursor: pointer;
  width: fit-content;
  .icon {
    margin-left: 5px;
  }
  ${color}
`

export const Container = styled.div<BackgroundProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  width: 100%;
  height: calc(100vh - 50px);
  ${background};
  @media screen and (max-width: 1024px) {
    height: 60vh;
  }
  @media screen and (max-width: 768px) {
    height: 55vh;
  }
`

export const ContainerText = styled.div`
  margin: 0 10% 5%;
  width: 60%;
  @media screen and (max-width: 1024px) {
    margin: 0 5% 5%;
  }
  @media screen and (max-width: 768px) {
    margin: 0 16px 5%;
    width: 80%;
  }
`

export const Text = styled.p<ColorProps>`
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.2px;
  color: #ffffff;
  margin: 10px 0 20px;
  ${color};
  @media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 14px;
  }
`

export const Title = styled.h1<ColorProps>`
  font-weight: bold;
  font-size: 48px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: start;
  letter-spacing: 0.2px;
  color: #ffffff;
  margin: 0;
  ${color};
  @media screen and (max-width: 768px) {
    font-size: 38px;
  }
  @media screen and (max-width: 450px) {
    font-size: 38px;
  }
`
