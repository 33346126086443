import { FC, useEffect, useRef, useState, useCallback, useMemo } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Payments } from 'views/components/Payments'
import { PaymentType } from 'views/components/Payments/Payment'
import { Plans } from 'views/components/Plans'
import { PlanType } from 'views/components/Plans/Plan'
import { SmallBanner } from 'views/components/SmallBanner'
import { SubmitWithApproval } from 'views/components/SubmitWithApproval'
import CreditCardInput from 'react-credit-card-input'
import { createCheckoutUrl } from '@AlgortimoTD/wompi'
import { v4 } from 'uuid'
import { useSelector } from 'react-redux'
import { signUpScreenTextsSelector } from 'ducks/staticContents'
import { getPlans } from 'services/plans/get'
import { create } from 'services/subscription/create'
import { useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { LoadingModal } from 'views/components/LoadingModal'
import { userSelector } from 'ducks/user'
import { saveWebSubscription } from 'services/subscription/saveWebSubscription'
import { Container, CreditCardContainer, TextInput, TextInputContainer, TextInputError } from './styled'
import 'react-toastify/dist/ReactToastify.css'

export type SignupValues = {
  plan: number | string | null
  email: string
  payment: number | string | null
  cardNumber?: string
  expiry?: string
  cvc?: string
  nameCard?: string
}

export type SignupErrors = {
  plan?: string
  name?: string
  lastName?: string
  email?: string
  password?: string
  confirmPassword?: string
  payment?: string
}

const initialValues: SignupValues = {
  plan: '',
  email: '',
  payment: '',
  cardNumber: '',
  expiry: '',
  cvc: '',
  nameCard: '',
}

const initialErrors: SignupErrors = {}

const sortFeatures = (a: any, b: any) => {
  if (a?.position_order < b?.position_order) {
    return -1
  }
  if (a?.position_order > b?.position_order) {
    return 1
  }
  return 0
}

const validationSchema = yup.object({
  plan: yup.number().required(),
  email: yup.string().email().required('Email es requerido.'),
  payment: yup.number().required(),
  nameCard: yup.string().when('payment', {
    is: (payment: any) => {
      return `${payment}` === process.env.REACT_APP_CREDIT_CARD_CHECKOUT_ID
    },
    then: yup.string().required('Nombre es requerido.'),
  }),
})

const periods: any = {
  Once: 'Único pago',
  Weekly: 'Semana',
  Daily: 'Día',
  Monthly: 'Mes',
  Yearly: 'Año',
}

const mapPlans = ({ id, value, period, features, name, ...plan }: any) => ({
  id: `${id}`,
  value,
  unit: '$',
  period: periods[period],
  periodValue: period,
  name,
  features: features?.sort(sortFeatures).map((f: any) => f.feature.description),
  ...plan,
})

export const AddSubscription: FC = () => {
  const userState = useSelector(userSelector)
  const userEmail = userState.get('email')
  const subscribedPlans = userState.get('memberships')
  const creditCardInput = useRef<any>(null)
  const history = useHistory()
  const nameCard = useRef<any>(null)
  const [plans, setPlans] = useState<any[]>([])
  const [btnSub, setSub] = useState<boolean>(true)
  const texts = useSelector(signUpScreenTextsSelector)
  const sectionTwo = useRef<any>()

  const showLoading = () => {
    setSub(false)
  }

  const hideLoading = () => {
    setSub(true)
  }

  const showError = (errorMessage: string) => {
    toast.error(errorMessage, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  const onSubmit = async (values: any) => {
    if (creditCardInput?.current?.state?.errorText) return
    showLoading()
    if (`${values.payment}` === process.env.REACT_APP_CREDIT_CARD_CHECKOUT_ID) {
      const namecardtest = nameCard.current.value
      const response = await create(values, namecardtest)
      if (response.ok) {
        history.push('/pages/registro-validado')
      } else {
        showError('El pago no se pudo completar')
        hideLoading()
      }
      return
    }
    const plan = plans.find((e: any) => `${e.id}` === `${values.plan}`)
    if (plan?.value > 0) {
      const reference = v4()

      const checkoutData = {
        reference,
        publicKey: process.env.REACT_APP_WOMPI_PUBLIC_KEY,
        currency: 'COP',
        amountInCents: plan.value * 100,
        redirectUrl: `${window.location.origin}/payment-result`,
      }

      saveWebSubscription({
        name: values.name,
        last_name: values.lastName,
        status: 'Pending',
        email: values.email,
        password: values.password,
        subsc_subscription_plan_id: plan.id,
        payment_method: values.payment,
        transaction_id: reference,
        transaction_payload: JSON.stringify(checkoutData),
      })
        .then(({ ok }) => {
          if (ok) {
            if (values.payment === process.env.REACT_APP_WEB_CHECKOUT_ID) {
              window.open(createCheckoutUrl(checkoutData), '_self')
            }
          } else {
            showError('El pago no se pudo completar')
          }
        })
        .catch(() => {
          showError('El pago no se pudo completar')
        })
        .finally(() => {
          hideLoading()
        })
    }
  }

  const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: { ...initialValues, email: userEmail || '' },
    initialErrors,
    onSubmit,
    validateOnChange: false,
    validationSchema,
  })

  const myplans = useMemo(() => {
    return subscribedPlans?.filter((f) => f.status === 'Activo')?.map((s) => `${s.plan.id}`) || []
  }, [subscribedPlans])

  useEffect(() => {
    getPlans().then(({ data, ok }) => {
      if (ok) {
        setPlans(data.map(mapPlans).filter((m: any) => !myplans?.find((p) => p === m.id)))
      }
    })
  }, [setPlans, myplans])

  const formIsMunted = useRef(false)
  const handleScrollToForm = useCallback(() => {
    if (!formIsMunted.current) {
      setTimeout(() => {
        sectionTwo.current.scrollIntoView({ block: 'center', behavior: 'smooth' })
      }, 500)
    } else {
      sectionTwo.current.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
    formIsMunted.current = true
  }, [])

  const handlePlanSelection = (plan: Omit<PlanType, 'onClick'>) => {
    setFieldValue('plan', plan.id)
    if (formIsMunted.current) {
      handleScrollToForm()
    }
  }

  const handlePaymentSelection = (payment: PaymentType) => {
    setFieldValue('payment', payment.id)
  }

  return (
    <Container>
      <SmallBanner
        title={texts.titleSignup}
        text={texts.subtitleSignup}
        textColor="white"
        backgroundImage="https://i.imgur.com/6qizs2m.png"
      />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {plans?.length > 0 && (
        <Plans
          cardButton={texts.buttonSignup}
          cardTextColor="#181059"
          cardBackgroundColor="#fff"
          featuredCardBackgroundColor="#FCD83A"
          featuredButtonColor="#131313"
          featuredButtonTextColor="#fff"
          title="Paso 1"
          subtitle="Elige una modalidad"
          footerButton="Hazte Cómplice"
          footerTitle="Si no puedes hacer un aporte monetario, también puedes unirte a nuestra comunidad de Cómplices. Aporta tu tiempo, ideas o tu talento."
          footerButtonLink="/contacto?message=complice"
          plans={plans}
          selectPlan={handlePlanSelection}
          selectedPlan={values.plan}
        />
      )}

      {values.plan ? (
        <>
          <div ref={sectionTwo}>
            <Payments
              onMount={handleScrollToForm}
              selectPayment={handlePaymentSelection}
              title="Paso 2"
              subtitle="Forma de pago"
              payments={plans.find((e: any) => `${e.id}` === `${values.plan}`)?.paymentTypes}
              selectedPayment={values.payment}
            />
          </div>
          {values.payment === process.env.REACT_APP_CREDIT_CARD_CHECKOUT_ID && (
            <CreditCardContainer>
              <TextInputContainer>
                <TextInput
                  type="text"
                  required
                  id="nameCard"
                  name="nameCard"
                  ref={nameCard}
                  placeholder="Nombre titular*"
                  onChange={handleChange}
                />
                <TextInputError>{errors.nameCard}</TextInputError>
              </TextInputContainer>
              <CreditCardInput
                ref={creditCardInput}
                cardNumberInputProps={{
                  name: 'cardNumber',
                  value: values.cardNumber,
                  onChange: handleChange,
                }}
                cardExpiryInputProps={{
                  name: 'expiry',
                  value: values.expiry,
                  onChange: handleChange,
                }}
                cardCVCInputProps={{
                  name: 'cvc',
                  value: values.cvc,
                  onChange: handleChange,
                }}
                customTextLabels={{
                  invalidCardNumber: 'El número de la tarjeta es inválido',
                  expiryError: {
                    invalidExpiryDate: 'La fecha de expiración es inválida',
                    monthOutOfRange: 'El mes de expiración debe estar entre 01 y 12',
                    yearOutOfRange: 'El año de expiración no puede estar en el pasado',
                    dateOutOfRange: 'La fecha de expiración no puede estar en el pasado',
                  },
                  invalidCvc: 'El código de seguridad es inválido',
                  invalidZipCode: 'El código postal es inválido',
                  cardNumberPlaceholder: 'Número de tarjeta',
                  expiryPlaceholder: 'MM/AA',
                  cvcPlaceholder: 'CVC',
                  zipPlaceholder: 'C.P.',
                }}
              />
            </CreditCardContainer>
          )}
          {btnSub ? (
            <SubmitWithApproval
              title="Paso 3"
              subtitle="Agregar suscripción"
              message="Acepto los términos y condiciones de"
              link={{ label: 'la no ficción.', path: '/terms', target: true }}
              button="Continuar al pago"
              onSubmit={handleSubmit}
            />
          ) : (
            <LoadingModal />
          )}
        </>
      ) : null}
    </Container>
  )
}
