/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react'
import useMediaQuery from './useMediaQuery'
import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

export type Link = {
  label: string
  path: string
  target?: boolean
}

type Props = {
  onClickLogo?: () => void
  backgroundColor?: string // Background Color for the top bar
  buttonBackgroundColor?: string // Background Color for the button bar
  buttonTextColor?: string // Text Color for the button
  textColor?: string // Color of login and options text
  login?: Link // Login Label to display and link to login page
  logo?: string // Logo URL Image
  options?: Link[] // Navigation options that will appear on the bottom section
  register?: Link // Register Label to display inside the button and link to login page
  resizeLogo?: boolean // Option to resize the logo when scrolled
  socials?: {
    // Supported social links
    facebook?: string
    instagram?: string
    twitter?: string
  }
  handleLogout: () => void
  isAuthenticated?: boolean
  logout?: string
}

export const HeaderMenu: FC<Props> = (props) => {
  const matches = useMediaQuery('(max-width: 768px)')

  return matches ? <Mobile {...props} /> : <Desktop {...props} />
}
