import { RefObject, useCallback, useEffect, useState } from 'react'

type UseScroll = {
  onEnd: boolean
  onStart: boolean
  scrollBack: () => void
  scrollForward: () => void
}

const SCROLL_STEP = 1500

const useScrollPosition = (refElement: RefObject<HTMLDivElement> | null): UseScroll => {
  const [element, setElement] = useState<HTMLDivElement | null | undefined>(null)
  const [onStart, setOnStart] = useState(true)
  const [onEnd, setOnEnd] = useState(false)

  useEffect(() => {
    setElement(refElement?.current)
  }, [refElement])

  const scrollBack = useCallback(() => {
    if (element && element?.scrollLeft - SCROLL_STEP <= 0) {
      setOnStart(true)
    } else {
      setOnStart(false)
    }
    if (element && element?.scrollLeft - SCROLL_STEP < element.scrollWidth - element.clientWidth) {
      setOnEnd(false)
    } else {
      setOnEnd(true)
    }
    element?.scrollTo({
      behavior: 'smooth',
      left: element.scrollLeft - SCROLL_STEP,
    })
  }, [element])

  const scrollForward = useCallback(() => {
    element?.scrollTo({
      behavior: 'smooth',
      left: element.scrollLeft + SCROLL_STEP,
    })
    if (element && element?.scrollLeft + SCROLL_STEP > 0) {
      setOnStart(false)
    } else {
      setOnStart(true)
    }
    if (element && element?.scrollLeft + SCROLL_STEP >= element.scrollWidth - element.clientWidth) {
      setOnEnd(true)
    } else {
      setOnEnd(false)
    }
  }, [element])

  return { onEnd, onStart, scrollBack, scrollForward }
}

export default useScrollPosition
