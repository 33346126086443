/* eslint-disable react/jsx-props-no-spreading */
import { FC, useState } from 'react'
import { Modal } from 'views/components/Modal'
import { Item, CardFeaturedItem } from './Item'
import {
  Container,
  DetailsContainer,
  DetailsContent,
  DetailsImage,
  DetailsLabel,
  DetailsText,
  DetailsTitle,
  Grid,
  Subtitle,
  Title,
} from './styled'

type Props = {
  favColor?: string // Color to be displayed on fav icon
  items?: CardFeaturedItem[] // Items to be displayed
  textColor?: string // Color to be used on text
  title?: string // Title of section
  subtitle?: string
  midiaDisplay?: boolean
  showFav?: boolean
  onFavItem?: (item: CardFeaturedItem) => void
  onPlayItem?: (item: CardFeaturedItem) => void
}

export const CardsFeatured: FC<Props> = (props) => {
  const { favColor, items, textColor, title, midiaDisplay, subtitle, showFav, onFavItem, onPlayItem } = props
  const [visible, setVisible] = useState(false)
  const [currentItem, setCurrentItem] = useState<CardFeaturedItem | null>(null)

  const handleCloseDetails = () => {
    setVisible(false)
  }

  const renderItem = (item: CardFeaturedItem) => {
    const onClick = () => {
      setCurrentItem(item)
      setVisible(true)
    }

    const handleFav = () => {
      if (onFavItem) {
        onFavItem(item)
      }
    }

    const handlePlay = () => {
      if (onPlayItem) {
        onPlayItem(item)
      }
    }

    return (
      <Item
        key={item.title}
        {...item}
        favColor={favColor}
        midiaDisplay={midiaDisplay}
        onClick={onClick}
        showFav={showFav}
        handleFav={handleFav}
        handlePlay={handlePlay}
      />
    )
  }

  return (
    <Container color={textColor}>
      <Modal visible={visible} onClose={handleCloseDetails}>
        <DetailsContainer>
          <DetailsImage background={`url(${currentItem?.image})`} />
          <DetailsContent>
            <DetailsTitle>{currentItem?.title}</DetailsTitle>
            <DetailsLabel>{currentItem?.subtitle}</DetailsLabel>
            <DetailsText>{currentItem?.description}</DetailsText>
          </DetailsContent>
        </DetailsContainer>
      </Modal>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <Grid>{items?.map(renderItem)}</Grid>
    </Container>
  )
}
