import { createRoutine } from 'redux-saga-routines'

export const SET = 'USER/SET'
export const LOGOUT = 'USER/LOGOUT'
export const LOGIN = createRoutine('USER/LOGIN')
export const FAVORITE_EPISODE = 'USER/FAVORITE_EPISODE'
export const UNFAVORITE_EPISODE = 'USER/UNFAVORITE_EPISODE'
export const FAVORITES = createRoutine('USER/FAVORITES')
export const CLEAN_ERROR = 'USER/CLEAN_ERROR'
export const SET_PLANS = 'USER/SET_PLANS'
export const RESET = 'USER/RESET'
