import { FC, ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Container } from './styled'

type Props = {
  children?: ReactNode
  onClose?: () => void
  visible?: boolean
  noBackground?: boolean
}

const modalRoot = document.getElementById('modal')

export const Modal: FC<Props> = ({ children, onClose, visible, noBackground }) => {
  const [element, setElement] = useState<HTMLDivElement>()

  useEffect(() => {
    const el = document.createElement('div')
    el.className = 'flex-column'
    modalRoot?.appendChild(el)
    setElement(el)
  }, [])

  if (noBackground) {
    return element && visible && children ? createPortal(children, element || null) : null
  }

  return element && visible && children
    ? createPortal(<Container onClick={onClose}>{children}</Container>, element || null)
    : null
}
