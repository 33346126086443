import { ServiceResponse } from '@types'
import axios from 'axios'

export const resetPassword = async (email: string): Promise<ServiceResponse> => {
  try {
    const resp = await axios.post(`${process.env.REACT_APP_TFM_API}/auth/forgot-password`, { email, source: 'website' })
    if (resp.status === 200) {
      return { ok: true }
    }
    return { ok: false, message: 'Hubo un error' }
  } catch (error) {
    return { ok: false, message: error.message }
  }
}
