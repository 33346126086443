import { ServiceResponse } from '@types'
import axios from 'axios'

export const getExclusiveContent = async (): Promise<ServiceResponse> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_TFM_API}/graphql`, {
      query: `
        query {
          lnfContentExclusives(where: { status: "Publicado" }) {
            id
            title
            short_text
            thumbnail_image
          }
        }
      `,
    })
    return { ok: true, data: data.lnfContentExclusives }
  } catch (error) {
    return { ok: false, message: error.message }
  }
}
