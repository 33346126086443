import { SagaIterator } from '@redux-saga/core'
import { all, put, call, takeLatest, select, takeEvery } from 'redux-saga/effects'
import login from 'services/login'
import compliceProfile from 'services/compliceProfile'
import { CompliceProfileResponse, ServiceResponse } from '@types'
import { favoriteEpisode, getFavoriteEpisodes, unfavoriteEpisode } from 'services/episodes/favoriteEpisode'
import { get } from 'lodash'
import { getActive } from 'services/subscription/getActive'
import { GET_RESOURCES } from 'ducks/staticContents'
import { FavEpisodeAction, LoginAction } from '../models/actions'
import * as TYPES from '../actionTypes'
import { userSelector } from '../selectors'
import { IUser } from '../models'

function* loginSaga(action: LoginAction) {
  yield put(TYPES.LOGIN.request())
  try {
    const { data } = yield call(login, action?.payload?.email, action?.payload?.password)

    if (data) {
      const compliceResponse: CompliceProfileResponse = yield call(compliceProfile, action.payload.email)

      if (
        (compliceResponse.data && compliceResponse?.data?.status === 'Activo') ||
        (compliceResponse.data && compliceResponse?.data?.status === 'Administrador')
      ) {
        const favoriteEpisodesResponse: ServiceResponse = yield call(getFavoriteEpisodes, compliceResponse.data.id)

        const { data: memberships } = yield call(getActive, action?.payload?.email || '')

        yield put(
          TYPES.LOGIN.success({
            email: action?.payload?.email,
            token: data.token,
            name: `${data.firstName || ''} ${data.lastName || ''} `,
            id: compliceResponse.data?.id,
            status: compliceResponse?.data?.status,
            favoriteEpisodes: favoriteEpisodesResponse.data,
            memberships: memberships || [],
            rols: (memberships || []).flatMap((m: any) => m.plan.roles?.map((r: any) => r?.role?.id)),
          })
        )
      } else {
        yield put(TYPES.LOGIN.failure('No tienes suscripciones activas'))
      }
    } else {
      yield put(TYPES.LOGIN.failure('Correo o contraseña incorrectos'))
    }
  } catch (err: any) {
    yield put(TYPES.LOGIN.failure(err.message))
  } finally {
    yield put(TYPES.LOGIN.fulfill())
  }
}

function* favoriteEpisodeSaga(action: FavEpisodeAction) {
  const userData: IUser = yield select(userSelector)
  const favorites = userData.get('favoriteEpisodes') || []
  try {
    const { podcast, episode, data } = action.payload
    yield put(TYPES.FAVORITES.success([...favorites, data]))
    const id = userData.get('id')
    if (podcast && episode && id) {
      const body = {
        userId: id?.toString() || '',
        podcastId: podcast,
        episodeId: episode,
      }
      const response: ServiceResponse = yield call(favoriteEpisode, body)
      if (response && data) {
        const favoriteId = get(response, ['data', 'result', 0, 'resp'])
        const favoritesToUpdate = favorites.filter(
          (fav) => fav.episode.id.toString() !== episode && fav.podcast.id.toString() !== podcast
        )
        yield put(
          TYPES.FAVORITES.success([
            ...favoritesToUpdate,
            {
              ...data,
              id: favoriteId,
            },
          ])
        )
      }
    }
  } catch (error) {
    yield put(TYPES.FAVORITES.success(favorites))
  }
}
function* unfavoriteEpisodeSaga(action: FavEpisodeAction) {
  const userData: IUser = yield select(userSelector)
  const favorites = userData.get('favoriteEpisodes')
  try {
    const { podcast, episode, favorite } = action.payload
    const favoritesUpdated = favorites?.filter((fav) => fav.id !== favorite)
    yield put(TYPES.FAVORITES.success(favoritesUpdated))
    const id = userData.get('id')
    if (podcast && episode && id && favorite) {
      const body = {
        userId: id?.toString() || '',
        podcastId: podcast,
        episodeId: episode,
        favorite,
      }
      yield call(unfavoriteEpisode, body)
    }
  } catch (error) {
    yield put(TYPES.FAVORITES.success(favorites))
  }
}

function* getUserFavoriteEpisodesSaga() {
  const userData: IUser = yield select(userSelector)
  const userId = userData.get('id')
  if (userId) {
    const { data }: ServiceResponse = yield call(getFavoriteEpisodes, userId)
    if (data) {
      yield put(TYPES.FAVORITES.success(data))
    }
  }
}

function* logoutSagas() {
  yield put({ type: TYPES.RESET })
  yield put(GET_RESOURCES.trigger())
}

export function* userSagas(): SagaIterator {
  yield all([
    takeLatest(TYPES.LOGIN.TRIGGER, loginSaga),
    takeLatest(TYPES.FAVORITE_EPISODE, favoriteEpisodeSaga),
    takeLatest(TYPES.UNFAVORITE_EPISODE, unfavoriteEpisodeSaga),
    takeLatest(TYPES.FAVORITES.TRIGGER, getUserFavoriteEpisodesSaga),
    takeEvery(TYPES.LOGOUT, logoutSagas),
  ])
}
