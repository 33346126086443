import { createGlobalStyle } from 'styled-components'

const StyledApp = createGlobalStyle`
  body {
    margin:0;
    padding:0;
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root {
    width: 100%;
    max-width: 1920px;
    margin: auto;
    font-family: Montserrat, sans-serif;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`
export default StyledApp
