import { ServiceResponse } from '@types'
import axios from 'axios'

export const getExclusiveContentDetails = async (id: string): Promise<ServiceResponse> => {
  try {
    const {
      data: [data],
    } = await axios.get(`${process.env.REACT_APP_TFM_API}/lnf-content-exclusives?status=Publicado&id=${id}`)

    return { ...data, ok: !!data }
  } catch (error) {
    return { ok: false, message: error.message }
  }
}
