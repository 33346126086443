import { all, call } from 'redux-saga/effects'

import { routinePromiseWatcherSaga } from 'redux-saga-routines'
import { userSagas } from 'ducks/user/sagas'
import { staticContentsSagas } from 'ducks/staticContents/sagas'
import { SagaIterator } from 'redux-saga'

export default function* rootSaga(): SagaIterator {
  try {
    yield all([call(routinePromiseWatcherSaga), call(userSagas), call(staticContentsSagas)])
  } catch (error) {
    // eslint-disable-next-line
    console.error(error)
  }
}
