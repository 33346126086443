import { ServiceResponse } from '@types'
import axios from 'axios'

type Args = {
  rateID: string
  podcastID: string
  episodeID: string
  profileID?: string
  comment?: string
  options?: any
}

export const createRate = async ({
  rateID,
  episodeID,
  podcastID,
  profileID,
  comment,
  options,
}: Args): Promise<ServiceResponse> => {
  try {
    await axios.post(
      `${process.env.REACT_APP_TFM_API}/flow/solution/a414a617-8f8a-4967-b1f5-72f55d7d2a1c/actions/08d60c4c-c816-40c4-8ebe-8dca4b707f1b`,
      {
        data: {
          '48623146-7ee4-49b1-a26d-4572f193bc39': podcastID,
          '00ef71c0-1bfa-4101-bf47-4e8716e206ca': episodeID,
          'ece06d7f-0f0e-465d-87ec-08731fd56437': rateID,
          '6e1de702-3633-4355-821f-d259470e53d7': profileID || null,
          'a2405ebc-1f80-4961-9dc2-a8315f25f7ed': comment,
          '9287a737-d4cf-413d-9edd-c663511b1fce': Object.keys(options || {}).map((k) => ({ label: k, value: k })),
        },
      }
    )
    return { ok: true }
  } catch (error) {
    return { ok: false, message: error.message }
  }
}
