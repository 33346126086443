const get = (sources: any[], prop: string) =>
  sources?.find((item: any) => item?.component?.payload?.slug === prop)?.component?.payload

export const componentsMapper = (sources: any[], slugs: string[]): any => {
  const values: any = {}
  slugs.forEach((slug) => {
    values[slug] = get(sources, slug)
  })
  return values
}
