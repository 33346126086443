import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Container = styled.div`
  padding-top: 50px;
  margin: 0 10%;
  width: 80%;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #909090;
  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 0 5%;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    margin: 0;
    overflow-x: scroll;
    padding-top: 25px;
  }
`

type OptionButtonType = {
  selected?: boolean
}

export const OptionButton = styled.button<ColorProps & OptionButtonType>`
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #0a142f;
  background-color: transparent;
  padding: 8px;
  margin: 0 10px;
  border: ${({ selected }) => (selected ? '1px solid #000000' : 'none')};
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  cursor: pointer;
  transition: opacity 300ms ease-in-out;
  ${color};
  &:hover {
    opacity: 1;
  }
`
