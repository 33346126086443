import { ServiceResponse } from '@types'
import axios from 'axios'

export const saveWebSubscription = async (payload: any): Promise<ServiceResponse> => {
  try {
    const { status } = await axios.post(
      `${process.env.REACT_APP_TFM_API}/flow/solution/b8542a30-2949-474b-a649-96d5964a6288/actions/0b349b23-8351-4f74-b88c-0bf6c5bdcc18`,
      { data: payload, source: 'object' }
    )
    return { ok: status === 200 }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}
