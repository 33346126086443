import styled from 'styled-components'
import { color, position, space, layout, LayoutProps, SpaceProps, ColorProps, PositionProps } from 'styled-system'
import { Link } from 'react-router-dom'

export type BlockProps = LayoutProps & SpaceProps & ColorProps & PositionProps

export const Button = styled.div<BlockProps>`
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.main};
  color: #fff;
  padding: 1rem;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${layout}
  ${space}
  ${color}
  ${position}
`

export const LinkButton = styled(Link)<BlockProps>`
  color: ${(props) => props.theme.colors.main};
  padding: 1rem;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${layout}
  ${space}
  ${color}
  ${position}
`
export const MainButton = styled.button`
  border: none;
  color: black;
  background-color: #fcd83a;
  border-radius: 2px;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.22px;
  padding: 16px;
  width: 170px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  ${color};
`
