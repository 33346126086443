import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { background, BackgroundProps, color, ColorProps } from 'styled-system'

type TypeOfContent = {
  viewOnly?: boolean
}

export const Container = styled.div<TypeOfContent>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: ${({ viewOnly }) => (viewOnly ? 'none' : 'translateY(-5px)')};
  }
  @media screen and (max-width: 1024px) {
    min-width: 90%;
    overflow: unset;
    scroll-snap-align: center;
    scroll-snap-stop: always;
  }
`

export const ContainerView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  &:hover {
    -webkit-box-shadow: 0px 0px 15px 2px rgba(204, 204, 204, 1);
    -moz-box-shadow: 0px 0px 15px 2px rgba(204, 204, 204, 1);
    box-shadow: 0px 0px 15px 2px rgba(204, 204, 204, 1);
  }
  @media screen and (max-width: 1024px) {
    min-width: 230px;
    overflow: unset;
  }
`

export const Content = styled.div<TypeOfContent>`
  position: relative;
  display: flex;
  flex-direction: ${({ viewOnly }) => (viewOnly ? 'column' : 'row')};
  padding: ${({ viewOnly }) => (viewOnly ? '23px 23px 46px' : '40px 20px 15px')};
  justify-content: space-between;
  width: calc(100% - 40px);
  @media screen and (max-width: 768px) {
    padding: 40px 15px 10px;
  }
`

export const ContentLink = styled(Link)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const FavButton = styled.div<ColorProps>`
  cursor: pointer;
  font-size: 20px;
  color: #c3cbcd;
  ${color};
`

export const Image = styled.div<BackgroundProps>`
  height: 220px;
  width: 100%;
  ${background};
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    height: 180px;
    border-radius: 8px;
  }
  @media screen and (max-width: 450px) {
    height: 220px;
  }
  @media screen and (min-width: 1920px) {
    height: 275px;
  }
`

export const Label = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: inherit;
  margin: 0;
`

export const PlayButton = styled.button<ColorProps>`
  border: none;
  width: 60px;
  height: 60px;
  background: #fcd83a;
  color: black;
  font-size: 21px;
  border-radius: 50%;
  align-self: center;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  bottom: -30px;
  z-index: 1;
  .icon {
    margin-left: 4px;
  }
  ${color};
`

export const Subtitle = styled.h3`
  font-size: 14px;
  line-height: 17px;
  color: inherit;
  margin: 2px 0 4px;
  font-weight: 400;
`

export const Text = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: inherit;
  opacity: 0.5;
  margin: 0;
`

export const Title = styled.h2`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: inherit;
  margin: 0;
`
