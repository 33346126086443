import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Container = styled.div<ColorProps>`
  display: flex;
  flex-direction: column;
  color: black;
  width: 50%;
  padding-top: 25px;
  ${color};
  position: relative;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 1024px) and (orientation: landscape) {
    padding-top: 0px;
    width: 100%;
  }
`

export const Text = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: inherit;
  margin: 0 0 20px;
  @media screen and (max-width: 450px) {
    margin: 0 0 0px;
    font-size: 14px;
    line-height: 17px;
  }
  @media screen and (max-width: 1024px) and (orientation: landscape) {
    margin: 0 0 0px;
    font-size: 14px;
    line-height: 17px;
  }
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  color: inherit;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 38px;
    line-height: 46px;
  }
`
