import { ServiceResponse } from '@types'
import axios from 'axios'
import { get } from 'lodash'

type Payload = {
  userId: string
  podcastId: string
  episodeId: string
  favorite?: string
}

type QueryEpisode = {
  podcast: {
    lnf_podcast_id: {
      id: string
    }
  }
  episode: {
    id: string
  }
}

export const favoriteEpisode = async ({ userId, podcastId, episodeId }: Payload): Promise<ServiceResponse> => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_TFM_API}/flow/solution/a414a617-8f8a-4967-b1f5-72f55d7d2a1c/actions/c0213518-5e33-43e9-95d2-e38fb579ee43`,
      {
        data: {
          'cadb8f2f-93f3-4ea0-9986-9a25946f2f7d': podcastId || null,
          'b7c176a1-c47c-4904-8bdc-1ab639a0fb42': episodeId || null,
          '19388b00-4e56-457b-a534-495e4880f5d0': userId || null,
        },
      }
    )
    return { ok: true, data }
  } catch (error) {
    return { ok: false, message: error.message }
  }
}

export const unfavoriteEpisode = async ({
  userId,
  podcastId,
  episodeId,
  favorite,
}: Payload): Promise<ServiceResponse> => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_TFM_API}/flow/solution/a414a617-8f8a-4967-b1f5-72f55d7d2a1c/actions/5c957bd0-30a8-4098-956a-34555cd144af`,
      {
        data: {
          lnf_podcast_id: podcastId || null,
          lnf_episode_id: episodeId || null,
          lnf_complice_id: userId || null,
          id: favorite || null,
        },
        source: 'object',
      }
    )
    return { ok: true, data }
  } catch (error) {
    return { ok: false, message: error.message }
  }
}

export const getFavoriteEpisodes = async (userId: number): Promise<ServiceResponse> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_TFM_API}/graphql`, {
      query: `
        query {
          lnfFavoriteEpisodes(where: {
            lnf_complice_id: ${userId}
          }) {
            id
            episode: lnf_episode_id {
              id
              title
              attachement_featured
              duration
              link_episode
            }
            podcast: lnf_episode_id {
              lnf_podcast_id {
                id
                title
              }
            }
          }
        }
      `,
    })
    const formatedData = data.lnfFavoriteEpisodes.map((episodeQuery: QueryEpisode) => ({
      ...episodeQuery,
      podcast: episodeQuery.podcast.lnf_podcast_id,
      episode: {
        ...episodeQuery.episode,
        image: get(episodeQuery.episode, ['attachement_featured', 0, 'virtualPath']),
      },
    }))
    return { ok: true, data: formatedData }
  } catch (error) {
    return { ok: false, message: error.message }
  }
}
