/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { NewsletterForm } from './NewsletterForm'
import {
  ContactContainer,
  ContactDescription,
  ContactLogo,
  ContactLogos,
  ContactSocial,
  ContactSocialsContainer,
  ContactText,
  Container,
  Content,
  FooterContent,
  FooterLogo,
  FooterRow,
  FooterText,
  NavigationColumn,
  NavigationContainer,
  NavigationLink,
  NavigationLinkContainer,
  NavigationTitle,
  NewsletterContainer,
} from './styled'

export type NavigationLinkType = {
  label: string
  path: string
  target?: boolean
}

export type NavigationColumnType = {
  title?: string
  options?: NavigationLinkType[]
}

type Props = {
  backgroundColor?: string // Background color of footer
  contactEmail?: string // Email to display in contact section
  contactLogos?: string[] // Logo URL to be displayed below socials
  contactTitle?: string // Contact Title
  contactSubtitle?: string // Contact Title
  contactSocials?: {
    // Supported social links
    facebook?: string
    instagram?: string
    twitter?: string
  }
  footerText?: string[] // Text to be displayed next to logo on the bottom section
  logo?: string // Logo URL
  navigationColumns?: NavigationColumnType[] // Content to display as a navigation column
  newsletterBackgroundColor?: string // Background color of newsletter box
  newsletterButtonColor?: string // Color of newsletter box button
  newsletterButtonText?: string // Text value of newsletter box button
  newsletterButtonTextColor?: string // Color of newsletter box button text
  newsletterColor?: string // Color of newsletter input
  newsletterEmail?: string // Email placeholder of newsletter input
  newsletterName?: string // Name placeholder of newsletter input
  newsletterTitle?: string // title to be displayed in newsletter box
  newsletterUrl: string // Link of newsletter form
  textColor?: string // Color used on all texts on footer
}

const renderSocial = (socialName: string) => {
  switch (socialName.toLowerCase()) {
    case 'facebook':
      return <FaFacebook />
    case 'instagram':
      return <FaInstagram />
    case 'twitter':
      return <FaTwitter />

    default:
      return null
  }
}

export const Footer: FC<Props> = (props) => {
  const {
    backgroundColor,
    contactEmail,
    contactLogos,
    contactSocials = {},
    contactSubtitle,
    contactTitle,
    footerText,
    logo,
    navigationColumns,
    newsletterBackgroundColor,
    newsletterButtonColor,
    newsletterButtonText,
    newsletterButtonTextColor,
    newsletterColor,
    newsletterEmail,
    newsletterName,
    newsletterTitle,
    newsletterUrl,
    textColor,
  } = props

  const handleNavigation = (option: any) => () => {
    const prefix = option.path.startsWith('/') ? '' : '/'
    if (option.path.startsWith('http')) {
      window.open(`${option.path}`, option.target ? '_blank' : '_self')
      return
    }
    if (option.target) {
      window.open(`${window.location.origin}${prefix}${option.path}`, option.target ? '_blank' : '_self')
      return
    }
    window.location.replace(`${window.location.origin}${prefix}${option.path}`)
  }

  return (
    <Container backgroundColor={backgroundColor} color={textColor}>
      <Content>
        <NavigationContainer>
          {navigationColumns?.map((navColumn) => (
            <NavigationColumn key={navColumn.title}>
              <NavigationTitle>{navColumn.title}</NavigationTitle>
              <NavigationLinkContainer>
                {navColumn.options?.map((option, index) => (
                  <NavigationLink
                    $last={navColumn.options && index === navColumn.options?.length - 1}
                    key={option.path}
                    onClick={handleNavigation(option)}
                  >
                    {option.label}
                  </NavigationLink>
                ))}
              </NavigationLinkContainer>
            </NavigationColumn>
          ))}
        </NavigationContainer>
        <ContactContainer>
          <ContactDescription>
            <NavigationTitle paddingBottom="9px">{contactTitle}</NavigationTitle>
            <ContactText>{contactSubtitle}</ContactText>
            <ContactText $last>{contactEmail}</ContactText>
            <ContactSocialsContainer>
              {Object.entries(contactSocials).map(([key, value]) => (
                <ContactSocial key={key} href={value} target="blank">
                  {renderSocial(key)}
                </ContactSocial>
              ))}
            </ContactSocialsContainer>
          </ContactDescription>
          <ContactLogos>
            {contactLogos?.map((contactLogo) => (
              <ContactLogo key={contactLogo} src={contactLogo} />
            ))}
          </ContactLogos>
        </ContactContainer>
        <NewsletterContainer>
          <MailchimpSubscribe
            url={newsletterUrl}
            render={({ subscribe, status }: { subscribe: any; status: string }) => (
              <NewsletterForm
                {...props}
                backgroundColor={newsletterBackgroundColor}
                buttonColor={newsletterButtonColor}
                buttonText={newsletterButtonText}
                buttonTextColor={newsletterButtonTextColor}
                color={newsletterColor}
                subscribe={subscribe}
                status={status}
                title={newsletterTitle}
                email={newsletterEmail}
                name={newsletterName}
              />
            )}
          />
        </NewsletterContainer>
        <FooterRow borderColor={textColor}>
          <FooterLogo src={logo} />
          <FooterContent>
            {footerText?.map((text) => (
              <FooterText key={text}>{text}</FooterText>
            ))}
          </FooterContent>
        </FooterRow>
      </Content>
    </Container>
  )
}
