import React, { FC } from 'react'
import { Container, Content, Image, LinkButton, Message, Title } from './styled'

export type FeatureType = {
  button?: string // Link Button Value
  image?: string // Image URL
  link?: string // Link to redirect
  message: string // Message to be displayed on feature
  target?: boolean // Should open in new tab
  title?: string // Title to be displayed on feature
}

type Props = {
  feature: FeatureType
  linkColor?: string
  textColor?: string
}

export const Feature: FC<Props> = ({ feature, linkColor, textColor }) => {
  const { button, image, link, message, target, title } = feature
  return (
    <Container>
      <Content>
        <Image src={image} />
        {title && <Title color={textColor}>{title}</Title>}
        <Message color={textColor}>{message}</Message>
      </Content>
      {link && (
        <LinkButton color={linkColor} to={link} target={target ? 'blank' : undefined}>
          {button}
        </LinkButton>
      )}
    </Container>
  )
}
