import { favoriteEpisodesSelector } from 'ducks/user'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

type UseFavoriteType = {
  isFavorite: (episodeId: string, podcastId: string) => boolean
  getFavoriteId: (episodeId: string, podcastId: string) => string | null
}

const useFavorite = (): UseFavoriteType => {
  const favoriteEpisodes = useSelector(favoriteEpisodesSelector)

  const isFavorite = useCallback(
    (episodeId: string, podcastId: string): boolean => {
      const favoriteCheck = favoriteEpisodes?.filter(
        (fav) => fav.episode.id.toString() === episodeId && fav.podcast.id.toString() === podcastId
      )
      if (favoriteCheck && favoriteCheck?.length > 0) {
        return true
      }
      return false
    },
    [favoriteEpisodes]
  )

  const getFavoriteId = useCallback(
    (episodeId: string, podcastId: string): string | null => {
      const favoriteCheck = favoriteEpisodes?.filter(
        (fav) => fav.episode.id.toString() === episodeId && fav.podcast.id.toString() === podcastId
      )
      if (favoriteCheck && favoriteCheck?.length > 0) {
        return favoriteCheck[0].id
      }
      return null
    },
    [favoriteEpisodes]
  )

  return { isFavorite, getFavoriteId }
}

export default useFavorite
