import styled from 'styled-components'
import { background, BackgroundProps, color, ColorProps } from 'styled-system'

export const Container = styled.div<ColorProps>`
  padding: 0 10% 100px;
  ${color};
  @media screen and (max-width: 1024px) {
    padding: 0 5% 75px;
  }
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  background-color: white;
  overflow: hidden;
  width: 460px;
  @media screen and (max-width: 1024px) {
    overflow: unset;
  }
  @media screen and (max-width: 450px) {
    width: 90%;
  }
`

export const DetailsContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 23px 23px 46px;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  @media screen and (max-width: 450px) {
    padding: 23px;
  }
`

export const DetailsImage = styled.div<BackgroundProps>`
  height: 380px;
  width: 100%;
  ${background};
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  position: relative;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  @media screen and (max-width: 450px) {
    height: 360px;
  }
`

export const DetailsLabel = styled.p`
  font-size: 24px;
  line-height: 30px;
  color: inherit;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 19px;
    line-height: 24px;
  }
`

export const DetailsText = styled.p`
  font-size: 15px;
  line-height: 19px;
  color: inherit;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 14px;
    line-height: 17px;
  }
`

export const DetailsTitle = styled.h2`
  font-weight: bold;
  color: inherit;
  margin: 0;
  font-size: 28px;
  line-height: 34px;
  @media screen and (max-width: 450px) {
    font-size: 22px;
    line-height: 27px;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-top: 35px;
  @media screen and (max-width: 1024px) {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    padding: 20px 2px;
  }
  @media screen and (max-width: 450px) {
    margin-top: 10px;
  }
`

export const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: inherit;
  margin: 0;
  opacity: 0.6;
  @media screen and (max-width: 450px) {
    font-size: 15px;
    line-height: 20px;
  }
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 48px;
  line-height: 40px;
  letter-spacing: 0.1px;
  color: inherit;
  margin-bottom: 4px;
  @media screen and (max-width: 450px) {
    font-size: 38px;
  }
`
