import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { IUser } from '../models'
import * as TYPES from '../actionTypes'

export const initialState: IUser = fromJS({
  uuid: '',
  favoriteEpisodes: [],
  memberships: [],
  rols: [],
})

const userReducer: Reducer<IUser> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_PLANS: {
      return state.set('memberships', action.payload).set(
        'rols',
        action.payload?.flatMap((m: any) => m.plan.roles?.map((r: any) => r?.role?.id))
      )
    }
    case TYPES.CLEAN_ERROR:
      return state.set('error', '')
    case TYPES.LOGIN.TRIGGER:
      return state.set('loading', true)

    case TYPES.LOGIN.SUCCESS:
      return state.merge(action.payload)

    case TYPES.LOGIN.FAILURE:
      return state.set('error', action.payload)

    case TYPES.LOGIN.FULFILL:
      return state.set('loading', false)

    case TYPES.SET:
      return state.merge(action.payload)

    case TYPES.RESET:
      return initialState

    case TYPES.FAVORITES.SUCCESS:
      return state.set('favoriteEpisodes', action.payload)
    default:
      return state
  }
}

export default userReducer
