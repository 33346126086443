/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import Form from './Form'
import { Container, Title, Text } from './styled'

type Props = {
  button?: string
  buttonColor?: string
  buttonTextColor?: string
  title?: string
  text?: string
  textColor?: string
  name?: string
  email?: string
  message?: string
  url: string
  link?: {
    label: string
    path: string
    target?: boolean
  }
}

export const NewsletterForm: FC<Props> = (props) => {
  const { title, text, textColor, url } = props

  return (
    <Container color={textColor}>
      <Title>{title}</Title>
      <Text>{text}</Text>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }: { subscribe: any; status: string; message: any }) => (
          <Form {...props} subscribe={subscribe} status={status} errorMessage={message} />
        )}
      />
    </Container>
  )
}
