import React, { FC } from 'react'
import HtmlRender from './HtmlRender'
import { Card, Container, Image, Text, Title } from './styled'

type Props = {
  backgroundColor?: string
  backgroundImage?: string
  cardBackgroundColor?: string
  image?: string
  text: string
  textColor?: string
  title?: string
}

export const ArticleWithImage: FC<Props> = (props) => {
  const { backgroundColor, backgroundImage, cardBackgroundColor, image, text, textColor, title } = props
  return (
    <Container background={`url(${backgroundImage})`} backgroundColor={backgroundColor}>
      <Card backgroundColor={cardBackgroundColor} color={textColor}>
        <Title>{title}</Title>
        <Text>
          <HtmlRender>{text}</HtmlRender>
        </Text>
      </Card>
      <Image src={image} />
    </Container>
  )
}
