import React, { FC } from 'react'
import { Container } from './styled'

type Props = {
  children: string
}

const HtmlRender: FC<Props> = (props: Props) => {
  const { children } = props

  const createContent = (): { __html: string } => {
    return { __html: children }
  }

  return <Container id="html-render" dangerouslySetInnerHTML={createContent()} />
}

export default HtmlRender
