import styled from 'styled-components'
import { background, BackgroundProps, color, ColorProps } from 'styled-system'

export const Container = styled.div<BackgroundProps & ColorProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 100px 10% 50px;
  ${background};
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position-x: center;
  background-color: white;
  ${color};
  @media screen and (max-width: 1024px) {
    padding: 100px 5% 50px;
  }
  @media screen and (max-width: 768px) {
    background-size: 100% 300px;
    padding: 100px 5% 50px;
  }
`

export const Grid = styled.div`
  width: 100%;
  margin-top: 40px;
  align-self: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  @media screen and (max-width: 768px) {
    grid-gap: 20px;
  }
  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`

export const Subtitle = styled.h2<ColorProps>`
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: black;
  margin: 0;
  ${color};
  @media screen and (max-width: 450px) {
    font-size: 14px;
  }
`

export const Title = styled.h1<ColorProps>`
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  text-align: center;
  color: black;
  margin: 0;
  ${color};
  @media screen and (max-width: 450px) {
    font-size: 38px;
    line-height: 40px;
  }
`
