import styled from 'styled-components'
import { background, BackgroundProps, color, ColorProps } from 'styled-system'

export const Container = styled.div<BackgroundProps & ColorProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px 10% 50px;
  ${background};
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position-x: center;
  background-color: white;
  ${color};
  @media screen and (max-width: 1024px) {
    padding: 50px 5% 50px;
  }
  @media screen and (max-width: 768px) {
    background-size: 100% 300px;
    padding: 50px 5% 50px;
  }
`

export const Content = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  @media screen and (max-width: 1024px) {
    padding-bottom: 20px;
  }
  @media screen and (min-width: 1025px) {
    &::-webkit-scrollbar {
      display: none;
    }
    & {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
`

export const Grid = styled.div`
  min-width: 100%;
  margin-top: 20px;
  align-self: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  padding: 0 20px;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  @media screen and (max-width: 768px) {
    grid-gap: 20px;
  }
  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`

export const NavigationContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin-top: 40px;
`

type NavigationButtonProps = {
  disabled?: boolean
  side?: 'left' | 'right'
}

export const NavigationButton = styled.button<NavigationButtonProps & ColorProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background-color: #000;
  border-width: 0;
  color: #fff;
  margin: 0 5px 10px;
  cursor: pointer;
  border-top-left-radius: ${({ side }) => side === 'left' && '8px'};
  border-bottom-left-radius: ${({ side }) => side === 'left' && '8px'};
  border-top-right-radius: ${({ side }) => side === 'right' && '8px'};
  border-bottom-right-radius: ${({ side }) => side === 'right' && '8px'};
  ${color};
  @media screen and (max-width: 768px) {
    margin: 0 8px 10px;
  }
`

export const Subtitle = styled.h2<ColorProps>`
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: black;
  margin: 0;
  ${color};
  @media screen and (max-width: 450px) {
    font-size: 14px;
  }
`

export const Title = styled.h1<ColorProps>`
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  color: black;
  margin: 0;
  ${color};
  @media screen and (max-width: 450px) {
    font-size: 26px;
    line-height: 40px;
  }
`
