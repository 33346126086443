import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Container = styled(Link)<ColorProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: black;
  ${color};
  &:hover {
    text-decoration: underline;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 196px;
  @media screen and (max-width: 768px) {
    height: 140px;
  }
  @media screen and (max-width: 450px) {
    height: 196px;
  }
  @media screen and (min-width: 1920px) {
    height: 275px;
  }
`

export const Title = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: inherit;
  margin: 16px 0 8px;
  @media screen and (max-width: 450px) {
    font-size: 24px;
    line-height: 24px;
  }
`

export const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: inherit;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 20px;
  }
`
