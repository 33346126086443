import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-style: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  h1,
  h2,
  h3,
  span {
    font-family: Montserrat, sans-serif !important;
  }
  p {
    span {
      font-family: Montserrat, sans-serif !important;
    }
    font-family: Montserrat, sans-serif !important;
    a {
      img {
        width: 100% !important;
        height: auto !important;
      }
    }
    img {
      max-width: 700px;
      width: 100% !important;
      height: auto !important;
    }
    iframe {
      width: 100% !important;
      height: 400px !important;
    }
  }
`
