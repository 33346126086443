/* eslint-disable react/jsx-props-no-spreading */
import { FC, useEffect } from 'react'
import { Payment, PaymentType } from './Payment'
import { Container, Content, Title, Subtitle } from './styled'

type Props = {
  title?: string
  subtitle?: string
  textColor?: string
  onMount?: () => void
  payments?: PaymentType[]
  selectedPayment: number | string | null
  selectPayment: (payment: PaymentType) => void
}

export const Payments: FC<Props> = (props) => {
  const { title, subtitle, textColor, payments, selectedPayment, selectPayment, onMount } = props

  useEffect(() => {
    if (onMount) {
      onMount()
    }
  }, [onMount])

  const renderPayment = (payment: PaymentType) => {
    const onClick = () => {
      selectPayment(payment)
    }
    return <Payment key={payment.label} {...payment} onSelect={onClick} selected={selectedPayment === payment.id} />
  }

  return (
    <Container color={textColor}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Content>{payments?.map(renderPayment)}</Content>
    </Container>
  )
}
