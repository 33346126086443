import { FC, useEffect, useState } from 'react'
import * as yup from 'yup'
import { MdCheck } from 'react-icons/md'
import { useFormik } from 'formik'

import Loading from './Loading'
import { Button, Container, Input, Title, InputContainer, InputError } from './styled'

type MailChimpData = {
  EMAIL: string
  NAME: string
}

type Props = {
  backgroundColor?: string // Background color of form container
  buttonColor?: string // Background Button Color
  buttonTextColor?: string // Text Button Color
  buttonText?: string // Button Text Value
  color?: string // Primary Color used
  email?: string // Email placeholder
  name?: string // Name Placeholder
  status?: string // Subscribe Status
  subscribe: ({ EMAIL, NAME }: MailChimpData) => void // Subscription handler from mailchimp
  title?: string // Title displayed on the top of title block
}

type FormValues = {
  email: string
  name: string
}

type FormErrorValues = {
  email?: string
  name?: string
}

const initialValues: FormValues = {
  email: '',
  name: '',
}

const initialErrors: FormErrorValues = {}

const validationSchema = yup.object({
  email: yup.string().email().required('Correo electronico es requerido'),
  name: yup.string().required('Nombre es requerido'),
})

export const NewsletterForm: FC<Props> = (props) => {
  const { backgroundColor, buttonColor, buttonTextColor, buttonText, color, email, name, status, subscribe, title } =
    props
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (status === 'success') {
      setSuccess(true)
    } else {
      setSuccess(false)
    }
  }, [status])

  const onSubmit = (values: FormValues) => {
    subscribe({
      EMAIL: values.email,
      NAME: values.name,
    })
  }

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues,
    initialErrors,
    onSubmit,
    validateOnChange: false,
    validationSchema,
  })

  const submit = () => handleSubmit()

  return (
    <Container backgroundColor={backgroundColor} color={color}>
      <Title>{title}</Title>
      <InputContainer>
        <Input
          placeholder={name || 'Nombre'}
          textColor={color}
          onChange={handleChange}
          name="name"
          value={values.name}
          error={errors.name}
        />
        {errors.name && <InputError>{errors.name}</InputError>}
      </InputContainer>
      <InputContainer>
        <Input
          placeholder={email || 'Email'}
          textColor={color}
          onChange={handleChange}
          name="email"
          value={values.email}
          error={errors.email}
        />
        {errors.email && <InputError>{errors.email}</InputError>}
      </InputContainer>
      {success ? (
        <Button backgroundColor="#0eb52a" color={buttonTextColor} type="button" disabled>
          suscrito <MdCheck style={{ marginLeft: '10px' }} />
        </Button>
      ) : (
        <Button
          backgroundColor={buttonColor}
          color={buttonTextColor}
          type="button"
          onClick={submit}
          disabled={status === 'sending'}
        >
          {status === 'sending' ? (
            <>
              Enviando
              <Loading color={buttonTextColor} />
            </>
          ) : (
            buttonText || 'suscribirme'
          )}
        </Button>
      )}
    </Container>
  )
}
