import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { background, BackgroundProps, color, ColorProps } from 'styled-system'

export const LinkButton = styled(Link)<ColorProps>`
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.217732px;
  color: #000000;
  background-color: #fcd83a;
  padding: 16px 32px;
  text-transform: capitalize;
  ${color};
`

export const LinkNativeButton = styled.a<ColorProps>`
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.217732px;
  color: #000000;
  background-color: #fcd83a;
  padding: 16px 32px;
  text-transform: capitalize;
  ${color};
`

type ContainerProps = {
  noBottomSpacing?: boolean
}

export const Container = styled.div<BackgroundProps & ColorProps & ContainerProps>`
  padding: 5% 0;
  padding-bottom: ${({ noBottomSpacing }) => noBottomSpacing && '30px'};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  ${background};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-color: white;
  background-position-y: center;
  ${color};
  @media screen and (max-width: 1024px) {
    padding-bottom: ${({ noBottomSpacing }) => noBottomSpacing && '15px'};
    padding-top: ${({ noBottomSpacing }) => noBottomSpacing && '2.5%'};
  }
  @media screen and (max-width: 768px) {
    padding-bottom: ${({ noBottomSpacing }) => noBottomSpacing && '15px'};
    background-size: ${({ noBottomSpacing }) => noBottomSpacing && '100% 325px'};
    padding-top: ${({ noBottomSpacing }) => noBottomSpacing && '20%'};
  }
  @media screen and (max-width: 450px) {
    padding: 20% 0;
    padding-bottom: ${({ noBottomSpacing }) => noBottomSpacing && '15px'};
    padding-top: ${({ noBottomSpacing }) => noBottomSpacing && '30%'};
  }
  @media screen and (min-width: 1920px) {
    padding: ${({ noBottomSpacing }) => noBottomSpacing && '5% 0'};
  }
`

export const Title = styled.h1<ColorProps>`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.1px;
  color: black;
  align-self: center;
  width: 60%;
  min-width: 700px;
  ${color};
  @media screen and (max-width: 1024px) {
    font-size: 24px;
  }
  @media screen and (max-width: 768px) {
    min-width: 400px;
  }
  @media screen and (max-width: 450px) {
    font-size: 22px;
    line-height: 29px;
    min-width: unset;
    width: 80%;
  }
`

export const Message = styled.p`
  font-style: normal;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: black;
  align-self: center;
  width: 60%;
  min-width: 700px;
  ${color};

  @media screen and (max-width: 768px) {
    min-width: 400px;
  }
  @media screen and (max-width: 450px) {
    min-width: unset;
    width: 80%;
  }
`
