import { FC, useRef } from 'react'
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs'
import { Item, ItemType } from './Item'
import { Collections, Container, NavigationButton, NavigationContainer, Row, Title } from './styled'
import useScrollPosition from './useScrollPosition'
import useMediaQuery from './useMediaQuery'

type Props = {
  backgroundColor?: string // Background Color
  backgroundImage?: string // Background Image Path
  color?: string // Color used on the gallery title, item text and navigation buttons
  items?: ItemType[] // Item Data to be displayed
  title?: string // Gallery title to display
}

export const Gallery: FC<Props> = (props) => {
  const { backgroundColor, backgroundImage, color, items, title } = props
  const collectionRef = useRef<HTMLDivElement>(null)
  const { onEnd, onStart, scrollBack, scrollForward } = useScrollPosition(collectionRef)
  const matches = useMediaQuery('(max-width: 450px)')

  return (
    <Container backgroundImage={`url(${backgroundImage})`} backgroundColor={backgroundColor}>
      <Row>
        <Title color={color}>{title}</Title>
        {!matches && (
          <NavigationContainer>
            <NavigationButton backgroundColor={onStart ? '#9E9E9E' : color} side="left" onClick={scrollBack}>
              <BsArrowLeftShort />
            </NavigationButton>
            <NavigationButton backgroundColor={onEnd ? '#9e9e9e' : color} side="right" onClick={scrollForward}>
              <BsArrowRightShort />
            </NavigationButton>
          </NavigationContainer>
        )}
      </Row>
      <Collections className="collections" ref={collectionRef}>
        {items?.map((item) => (
          <Item color={color} item={item} key={item.link} />
        ))}
      </Collections>
    </Container>
  )
}
