import { FC, useState } from 'react'
import { BiCheckboxChecked, BiCheckbox } from 'react-icons/bi'
import { Button, Container, Title, Subtitle, Row, Checkbox, Label, CustomLink } from './styled'

type Props = {
  title?: string
  subtitle?: string
  textColor?: string
  message?: string
  link?: {
    label: string
    path: string
    target?: boolean
  }
  button?: string
  buttonColor?: string
  buttonTextColor?: string
  onSubmit?: () => void
}

export const SubmitWithApproval: FC<Props> = (props) => {
  const { title, subtitle, textColor, message, link, button, buttonColor, buttonTextColor, onSubmit } = props
  const [checked, setChecked] = useState(false)

  const onSelect = () => {
    setChecked((prevState) => !prevState)
  }

  const handleSubmit = () => {
    if (checked && onSubmit) {
      onSubmit()
    }
  }

  return (
    <Container color={textColor}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Row>
        <Checkbox onClick={onSelect}>{checked ? <BiCheckboxChecked /> : <BiCheckbox />}</Checkbox>
        <Label>
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: message || '' }} />
          {link && (
            <CustomLink to={link?.path} target={link?.target ? 'blank' : undefined}>
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: ` ${link?.label || ''}` }} />
            </CustomLink>
          )}
        </Label>
      </Row>
      <Button type="submit" color={buttonTextColor} backgroundColor={buttonColor} onClick={handleSubmit}>
        {button}
      </Button>
    </Container>
  )
}
