import { FC } from 'react'
import HtmlRender from './HtmlRender'
import { Container, Image, Subtitle, Title, Text } from './styled'

type Props = {
  title?: string
  subtitle?: string
  text: string
  image?: string
  textColor?: string
}

export const BlogPost: FC<Props> = ({ title, subtitle, text, image, textColor }) => (
  <Container color={textColor}>
    {Boolean(title) && <Title>{title}</Title>}
    {Boolean(subtitle) && <Subtitle>{subtitle}</Subtitle>}
    <Text>
      <HtmlRender>{text}</HtmlRender>
    </Text>
    {image && <Image src={image} />}
  </Container>
)
