import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Container = styled.div<ColorProps>`
  padding: 5% 0;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${color};
`

export const Content = styled.div`
  width: 80%;
  display: grid;
  align-self: center;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10%;
  @media screen and (max-width: 1024px) {
    width: 90%;
    grid-column-gap: 5%;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
  }
`

export const Title = styled.h1<ColorProps>`
  margin: 0;
  margin-left: 10%;
  margin-bottom: 60px;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  color: #000000;
  ${color};
  @media screen and (max-width: 1024px) {
    margin-left: 5%;
  }
  @media screen and (max-width: 450px) {
    font-size: 38px;
    line-height: 38px;
    text-align: center;
    margin-left: 0;
  }
`
