import styled from 'styled-components'

export const StyledContainer = styled.select`
  border-radius: 5px;
  border: 1px solid #c8c8c8;
  padding: 11px 13px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: inherit;
  margin-top: 1rem;
  outline: none;
  background-color: initial;
`
