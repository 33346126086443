import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ColorProps, color, BackgroundProps } from 'styled-system'

type ButtonProps = {
  backgroundColor?: string
  textColor?: string
}

type MenuProps = {
  isOpen?: boolean
}

export const AuthContainer = styled.div<ButtonProps>`
  border-bottom: 1px solid ${({ textColor }) => textColor || 'black'};
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`

export const Bar = styled.div<ColorProps & MenuProps>`
  position: sticky;
  width: 90%;
  top: 0;
  padding: 16px 5%;
  background-color: #fcd83a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  position: relative;
  height: 50px;
  box-shadow: ${({ isOpen }) => (isOpen ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.25)')};
  transition: all 0.3s ease-in-out;
  ${color};
  @media screen and (max-width: 450px) {
    width: calc(100% - 32px);
    padding: 16px;
  }
`

export const Container = styled.div<MenuProps>`
  position: fixed;
  width: 100%;
  z-index: 2;
  height: ${({ isOpen }) => (isOpen ? '100vh' : '50px')};
`

export const Content = styled.div<ColorProps & MenuProps>`
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  color: black;
  background-color: #fcd83a;
  width: 90%;
  padding: 32px 5%;
  height: calc(100vh - 144px);
  position: relative;
  ${color};
  clip-path: circle(${({ isOpen }) => (isOpen ? '200% at 90% -10%' : '0 at 90% -10%')});
  -webkit-clip-path: circle(${({ isOpen }) => (isOpen ? '200% at 90% -10%' : '0 at 90% -10%')});
  transition: all 0.3s ease-in-out;
  @media screen and (max-width: 450px) {
    width: calc(100% - 32px);
    padding: 32px 16px;
  }
`

export const Logo = styled.img<BackgroundProps>`
  height: 40px;
  width: 60px;
`

export const LogoutButton = styled.button<ColorProps>`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 0;
  color: #000000;
  margin: 8px 0;
  background-color: transparent;
  border: none;
  width: fit-content;
  ${color}
  &:hover {
    text-decoration: underline;
  }
`

export const MenuLink = styled(Link)<ColorProps>`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #000000;
  margin: 8px 0;
  ${color}
  &:hover {
    text-decoration: underline;
  }
`

export const MenuOptions = styled.div`
  padding: 15px 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

export const RegisterOutline = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: -3px;
  right: -3px;
`

export const RegisterButton = styled(Link)<ButtonProps>`
  position: relative;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  padding: 10px 15px;
  max-width: 175px;
  margin: 26px 0 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ textColor }) => textColor || '#fcd83a'};
  background-color: ${({ backgroundColor }) => backgroundColor || '#131313'};
  ${RegisterOutline} {
    border: 1px solid ${({ backgroundColor }) => backgroundColor || '#131313'};
  }
`

export const SocialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 32px;
`

export const SocialLink = styled.a<ColorProps>`
  margin-right: 20px;
  color: #000000;
  font-size: 20px;
  ${color};
`

export const ToggleButton = styled.button`
  border: none;
  color: inherit;
  background-color: transparent;
  font-size: 30px;
  display: flex;
  align-items: center;
`
