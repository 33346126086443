import React, { FC, useState } from 'react'
import { Button, FormError, TextInput, TextInputError } from 'views/components/LoginForm/styled'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { changePassword } from 'services/account/changePassword'
import { useHistory } from 'react-router-dom'
import { TextInputContainer } from 'views/screens/ForgotPassword/styled'
import { Container } from './styled'

const initialValues = {
  password: '',
  confirmPassword: '',
}

const validation = yup.object().shape({
  password: yup.string().required('Contraseña es requerida'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Las contraseñas deben coincidir'),
})

interface Props {
  token: string
}

export const ResetPasswordForm: FC<Props> = ({ token }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<{ message?: string }>({})
  const history = useHistory()

  const goToLogin = () => {
    history.push('/login')
  }

  const onSubmit = (values: any) => {
    setLoading(true)
    changePassword(token, values.password)
      .then(goToLogin)
      .catch(setError)
      .finally(() => setLoading(false))
  }

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit,
    validateOnChange: false,
    validationSchema: validation,
  })

  const handleClick = () => {
    handleSubmit()
  }

  return (
    <Container>
      <h2>Recuperar contraseña</h2>
      <FormError>{error.message}</FormError>
      <TextInputContainer>
        <TextInput
          placeholder="Nueva Contraseña"
          name="password"
          value={values.password}
          onChange={handleChange}
          error={errors.password}
          type="password"
        />
        {errors.password && <TextInputError>{errors.password}</TextInputError>}
      </TextInputContainer>
      <TextInputContainer>
        <TextInput
          placeholder="Confirmar contraseña"
          name="confirmPassword"
          value={values.confirmPassword}
          onChange={handleChange}
          error={errors.confirmPassword}
          type="password"
        />
        {errors.confirmPassword && <TextInputError>{errors.confirmPassword}</TextInputError>}
      </TextInputContainer>
      <Button disabled={loading} type="submit" onClick={handleClick}>
        {`${loading ? 'Cargando...' : 'Cambiar contraseña'}`}
      </Button>
    </Container>
  )
}
