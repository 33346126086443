import { FC } from 'react'
import { BiCheckboxChecked, BiCheckbox } from 'react-icons/bi'
import { Container, Row, Label, Checkbox, Image } from './styled'

export type PaymentType = {
  id: number
  label: string
  selected?: boolean
  image?: string
}

type Props = PaymentType & {
  onSelect: () => void
}

export const Payment: FC<Props> = ({ label, selected, image, onSelect }) => (
  <Container onClick={onSelect}>
    {image && <Image src={image} />}
    <Row>
      <Checkbox>{selected ? <BiCheckboxChecked /> : <BiCheckbox />}</Checkbox>
      <Label>{label}</Label>
    </Row>
  </Container>
)
