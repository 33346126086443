import { FC, useRef, useState } from 'react'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  Button,
  Container,
  Subtitle,
  TextArea,
  TextInput,
  TextInputError,
  Title,
  Checkbox,
  Row,
  Label,
  CustomLink,
} from './styled'

type FormValues = {
  name: string
  email: string
  message: string
  checked: boolean
}

type FormErrorValues = {
  name?: string
  email?: string
  message?: string
  checked?: string
}

const initialValues: FormValues = {
  message: '',
  email: '',
  name: '',
  checked: false,
}

const initialErrors: FormErrorValues = {}

type Props = {
  initialValues?: any
  button?: string
  buttonColor?: string
  buttonTextColor?: string
  subtitle?: string
  textColor?: string
  title?: string
  name?: string
  email?: string
  message?: string
  submitContact?: (values: FormValues) => Promise<{ ok: boolean }>
  link?: {
    label: string
    path: string
    target?: boolean
  }
}

const validationSchema = yup.object({
  name: yup.string().required('Nombre es requerido'),
  email: yup.string().email().required('Correo electronico es requerido'),
  message: yup.string().required('Mensaje es requerido'),
  checked: yup.bool().oneOf([true]),
})

export const ContactForm: FC<Props> = ({
  button,
  buttonColor,
  buttonTextColor,
  subtitle,
  textColor,
  title,
  name,
  email,
  message,
  submitContact,
  link,
  initialValues: propsInitialValues,
}) => {
  const [success, setSuccess] = useState(false)
  const reset = useRef<any>(null)
  const onSubmit = async (values: FormValues) => {
    if (submitContact) {
      const response = await submitContact(values)
      if (response.ok) {
        setSuccess(true)
        setTimeout(() => setSuccess(false), 5000)
      }
      reset.current(initialValues)
    }
  }

  const { values, errors, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      ...initialValues,
      ...propsInitialValues,
    },
    initialErrors,
    onSubmit,
    validationSchema,
    validateOnChange: false,
  })

  reset.current = resetForm

  const submit = () => {
    handleSubmit()
  }

  const handleCheck = () => {
    setFieldValue('checked', !values.checked)
  }

  return (
    <Container color={textColor}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <TextInput
        textColor={textColor}
        placeholder={name || 'Nombre'}
        name="name"
        value={values.name}
        onChange={handleChange}
        error={errors.name}
      />
      {errors.name && <TextInputError>{errors.name}</TextInputError>}
      <TextInput
        textColor={textColor}
        placeholder={email || 'Mail'}
        name="email"
        value={values.email}
        onChange={handleChange}
        error={errors.email}
      />
      {errors.email && <TextInputError>{errors.email}</TextInputError>}
      <TextArea
        textColor={textColor}
        placeholder={message || 'Mensaje'}
        name="message"
        value={values.message}
        onChange={handleChange}
        error={errors.message}
      />
      {errors.message && <TextInputError>{errors.message}</TextInputError>}
      <Row>
        <Checkbox color={errors.checked ? '#f2392c' : textColor} onClick={handleCheck}>
          {values.checked ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
        </Checkbox>
        <Label>
          {link && (
            <CustomLink to={link.path} target={link.target ? 'blank' : undefined}>
              {link.label}
            </CustomLink>
          )}
        </Label>
      </Row>
      {success ? (
        <Button color={buttonTextColor} backgroundColor="#0eb52a">
          Enviado!
        </Button>
      ) : (
        <Button color={buttonTextColor} backgroundColor={buttonColor} onClick={submit}>
          {button || 'Enviar'}
        </Button>
      )}
    </Container>
  )
}
