import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Container = styled.div<ColorProps>`
  padding: 25px 10%;
  width: 80%;
  display: flex;
  flex-direction: column;
  ${color};
  @media screen and (max-width: 768px) {
    padding: 25px 5%;
    width: 90%;
  }
`

export const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px 54px;
  margin-top: 26px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }
`

export const TextInputContainer = styled.div`
  width: 100%;
`

export const TextInput = styled.input`
  border: 1px solid #c8c8c8;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  padding: 11px 12px;
  color: #909090;
`

export const TextInputError = styled.p`
  margin: 0;
  margin-top: 4px;
  color: #f2392c;
  font-size: 12px;
`

export const Title = styled.h1`
  color: inherit;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 26px;
    line-height: 31px;
  }
`

export const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
  margin: 0;
  color: inherit;
  @media screen and (max-width: 450px) {
    font-size: 15px;
    line-height: 11px;
  }
`
