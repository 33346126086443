import { userSelector } from 'ducks/user'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { CallToActionBanner } from 'views/components/CallToActionBanner'
import { LoginForm } from 'views/components/LoginForm'
import { loginComponentsSelector } from 'ducks/staticContents'
import { Container, Separator } from './styled'

export const Login: FC = () => {
  const userState = useSelector(userSelector)
  const components = useSelector(loginComponentsSelector)
  const token = userState.get('token')

  if (token) {
    return <Redirect to="/exclusive" />
  }

  return (
    <Container>
      <LoginForm
        title={components.formTitle?.title}
        textColor="#393939"
        subtitle={components.formTitle?.message}
        email="Email"
        password="Contraseña"
        formTitle="Iniciar sesión"
        button="Acceder"
        link={{
          path: '/forgot-password',
          label: 'Olvidé mi contraseña',
        }}
      />
      <Separator />
      <CallToActionBanner
        title={components.complice?.title}
        backgroundImage={components.complice?.image}
        textColor="white"
        button={components.complice?.link_label}
        link={components.complice?.link}
      />
    </Container>
  )
}
