import { singleFile } from 'lib/propsParser/singleFile'
import get from 'lodash/get'
import ObjectMapper from 'object-mapper'

export const headerMapper = (original: any): any =>
  ObjectMapper.merge(original, original, {
    socials: {
      key: 'socials',
      transform: () => ({
        facebook: original.facebook,
        twitter: original.twitter,
        instagram: original.instagram,
      }),
    },
    logo: {
      key: 'logo',
      transform: singleFile,
    },
  })

const getter = (defaults: any) => (target: any, name: any) => {
  if (name === 'socials')
    return {
      facebook: target.facebook,
      twitter: target.twitter,
      instagram: target.instagram,
    }
  if (name in target && (target[name] || target[name] === 0)) {
    switch (name) {
      case 'logo':
        try {
          return get(JSON.parse(target.logo), [0, 'virtualPath'])
        } catch {
          return ''
        }

      default:
        return target[name]
    }
  }
  return defaults[name]
}

export const headerMenuParser = (tfmValues: any, defaults: any = {}) => {
  return new Proxy(tfmValues, {
    get: getter(defaults || {}),
  })
}
