import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { color, ColorProps } from 'styled-system'

export const Button = styled.button<ColorProps>`
  border: none;
  cursor: pointer;
  background: #fcd83a;
  color: black;
  border-radius: 2px;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.217732px;
  padding: 16px;
  width: fit-content;
  ${color};
`

export const Container = styled.div<ColorProps>`
  padding: 25px 10% 50px;
  width: 80%;
  display: flex;
  flex-direction: column;
  ${color};
  @media screen and (max-width: 768px) {
    padding: 25px 5%;
    width: 90%;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
`

export const Label = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: inherit;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 15px;
  }
`

export const CustomLink = styled(Link)``

export const Checkbox = styled.div`
  cursor: pointer;
  color: inherit;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 1024px) {
    width: 30px;
    height: 40px;
  }
  @media screen and (max-width: 450px) {
    align-items: flex-start;
  }
`
export const Title = styled.h1`
  color: inherit;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 26px;
    line-height: 31px;
  }
`

export const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
  margin: 0;
  color: inherit;
  @media screen and (max-width: 450px) {
    font-size: 15px;
    line-height: 11px;
  }
`
