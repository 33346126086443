import React, { FC } from 'react'
import { Container, Image, Text, ImageContainer } from './styled'

type Props = {
  backgroundColor?: string // Background Color of card name
  image: string // Image URL to be displayed.
  link: string // Link to Redirect
  target?: boolean
  text: string // Text to be displayed on card
  textColor?: string // Text color of card name
}

export const Card: FC<Props> = ({ backgroundColor, image, link, target, text, textColor }) => (
  <Container to={link} target={target ? 'blank' : undefined}>
    <ImageContainer>
      <Image src={image} alt={text} />
    </ImageContainer>

    <Text color={textColor} backgroundColor={backgroundColor}>
      {text}
    </Text>
  </Container>
)
