import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0;
  width: 100%;
  height: calc(152px + env(safe-area-inset-bottom));
`

export const CloseButton = styled.button`
  font-size: 20px;
  margin: 0;
  padding: 0;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: white;
  right: 5px;
  top: -10px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  & > div {
    margin-bottom: -3px;
  }
  @media screen and (max-width: 450px) {
    width: 35px;
    height: 35px;
    top: -15px;
    font-size: 16px;
  }
`
