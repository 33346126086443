import React, { FC } from 'react'
import { Container, Title, Text } from './styled'

type Props = {
  title?: string
  text?: string
  textColor?: string
  backgroundColor?: string
  backgroundImage?: string
}

export const SmallBanner: FC<Props> = ({ title, text, textColor, backgroundColor, backgroundImage }) => (
  <Container color={textColor} background={`url(${backgroundImage})`} backgroundColor={backgroundColor}>
    <Title>{title}</Title>
    <Text>{text}</Text>
  </Container>
)
