import { FC } from 'react'
import { Html } from './Html'
import { ImageTextBlock } from './ImageTextBlock'
import { TextBlock } from './TextBlock'

const DEFINITIONS: Record<string, FC<any>> = {
  ContentRichImageBlock: ImageTextBlock,
  ContentTextBlock: TextBlock,
  Html,
}

interface Props {
  items: Array<{ payload: any; type: string }>
}

export const Buildin: FC<Props> = ({ items = [] }) => {
  return (
    <>
      {items?.map(({ payload, type: componentType }) => {
        if (componentType in DEFINITIONS) {
          return DEFINITIONS[componentType]({ ...payload, key: payload.tfm_id })
        }
        return null
      })}
    </>
  )
}
