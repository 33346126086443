import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Button = styled.button<ColorProps>`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.217732px;
  color: #ffffff;
  background: #000000;
  border-radius: 2px;
  padding: 15px 30px;
  align-self: flex-start;
  ${color}
`

export const Checkbox = styled.button<ColorProps>`
  margin-right: 10px;
  cursor: pointer;
  color: white;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${color}
`

export const Container = styled.div<ColorProps>`
  width: 40%;
  min-width: 450px;
  align-self: center;
  padding: 125px 150px 50px;
  background-color: #fcd83a;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  position: relative;
  ${color};
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    min-width: unset;
    width: 100%;
    padding: 80px 32px 32px;
  }
`

type TextColor = {
  error?: string
  textColor?: string
}

export const Input = styled.input<TextColor>`
  color: ${({ textColor }) => textColor || '#393939'};
  border: 1px solid ${({ error, textColor }) => (error ? '#f2392c' : textColor || '#393939')};
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.217732px;
  padding: 10px 25px;
  display: flex;
  width: calc(100% - 50px);
  &::placeholder {
    color: ${({ textColor }) => textColor || '#393939'};
  }
`

export const InputContainer = styled.div`
  margin-bottom: 14px;
  width: 100%;
  align-self: center;
`

export const InputError = styled.p`
  margin: 0;
  margin-top: 4px;
  color: #f2392c;
  font-size: 12px;
`

export const Label = styled.p<ColorProps>`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.217732px;
  color: inherit;
  margin: 0;
  ${color};
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
`

export const TitleContainer = styled.div<ColorProps>`
  position: absolute;
  top: -80px;
  left: -100px;
  color: inherit;
  ${color};
  @media screen and (max-width: 1024px) {
    top: -75px;
    left: -80px;
  }
  @media screen and (max-width: 768px) {
    top: -50px;
    left: 30px;
  }
  @media screen and (max-width: 450px) {
    top: -45px;
  }
`

export const TitleOne = styled.h1`
  margin: 0;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 72px;
  line-height: 40px;
  letter-spacing: 0.2px;
  @media screen and (max-width: 1024px) {
    font-size: 64px;
  }
  @media screen and (max-width: 768px) {
    font-size: 39px;
    line-height: 20px;
  }
`

export const TitleTwo = styled.h2`
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0.2px;
  margin: 5px 0;
  @media screen and (max-width: 1024px) {
    font-size: 28px;
    margin: 0;
  }
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
`

export const TitleThree = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 96px;
  line-height: 40px;
  letter-spacing: 0.2px;
  @media screen and (max-width: 1024px) {
    font-size: 88px;
  }
  @media screen and (max-width: 768px) {
    font-size: 52px;
    line-height: 20px;
  }
`
