import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { BackgroundProps, color, ColorProps } from 'styled-system'

export const Container = styled(Link)<BackgroundProps>`
  flex: 1;
  margin: 1px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const Image = styled.img`
  height: 100%;
  height: 100%;
  pointer-events: none;
  object-fit: cover;
`

export const Text = styled.div<ColorProps>`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-weight: 500;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #000000;
  background-color: white;
  height: 50px;
  ${color}
`
