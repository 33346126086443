import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Container = styled.div<ColorProps>`
  display: flex;
  flex-direction: column;
  color: black;
  ${color};
`

export const Title = styled.h1`
  color: inherit;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  margin-bottom: 5px;
  @media screen and (max-width: 768px) {
    font-size: 26px;
    line-height: 32px;
  }
`

export const Text = styled.p`
  color: inherit;
  font-weight: bold;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.1px;
  margin: 0;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 36px;
  }
`
