import { FC } from 'react'
import { Container, Loader, Modal, Overlay } from './styled'

interface Props {
  message?: string
}

export const LoadingModal: FC<Props> = ({ message }) => {
  return (
    <div className="App">
      <Overlay />
      <Modal>
        <Loader>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </Loader>
        <br />
        <Container>
          <h2>{message || 'Espera un momento por favor, estamos procesando tu pago'}</h2>
        </Container>
      </Modal>
    </div>
  )
}
