import styled from 'styled-components'
import { background, BackgroundProps, color, ColorProps } from 'styled-system'

export const Container = styled.div<ColorProps & BackgroundProps>`
  padding: 100px 10% 80px;
  width: 80%;
  color: black;
  background-color: white;
  ${background};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  ${color};
  @media screen and (max-width: 1024px) {
    padding: 100px 5% 80px;
    width: 90%;
  }
  @media screen and (max-width: 450px) {
    padding: 110px 5% 70px;
  }
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  color: inherit;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 43px;
    line-height: 38px;
    width: 200px;
  }
`

export const Text = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: inherit;
  margin: 0;
  width: 300px;
  @media screen and (max-width: 450px) {
    width: 200px;
    margin-top: 8px;
    font-size: 15px;
    line-height: 17px;
  }
`
