import React, { FC, ChangeEventHandler } from 'react'
import { StyledContainer } from './styled'

interface Props {
  value?: string
  options: any[]
  displayKey?: string
  uniqueKey?: string
  name?: string
  onChange?: ChangeEventHandler<HTMLSelectElement>
}

export const SingleSelect: FC<Props> = ({
  options,
  uniqueKey = 'value',
  displayKey = 'label',
  name,
  onChange,
  value,
}) => {
  return (
    <StyledContainer name={name} onChange={onChange} value={value}>
      {options.map((option) => (
        <option value={option[uniqueKey]} key={option[uniqueKey]}>
          {option[displayKey]}
        </option>
      ))}
    </StyledContainer>
  )
}
