import React, { FC } from 'react'
import { Feature, FeatureType } from './Feature'
import { Container, Content, Title } from './styled'

type Props = {
  features?: FeatureType[] // Features that will be displayed in grid
  linkColor?: string // Color of link
  textColor?: string // Color of all text used
  title?: string // Title to be displayed
}

export const Features: FC<Props> = (props) => {
  const { features, linkColor, textColor, title } = props
  return (
    <Container>
      {title && <Title color={textColor}>{title}</Title>}
      <Content>
        {features?.map((feature) => (
          <Feature key={feature.image} feature={feature} textColor={textColor} linkColor={linkColor} />
        ))}
      </Content>
    </Container>
  )
}
