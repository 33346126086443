import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Container = styled.div`
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Content = styled.div<ColorProps>`
  padding: 0 10% 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #000;
  min-height: 650px;
  ${color};
  @media screen and (max-width: 768px) {
    padding: 0 5% 100px;
  }
`
