import styled from 'styled-components'

export const Container = styled.div`
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ReadonlyContainer = styled(Container)`
  opacity: 0.5;
`

export const TextInputContainer = styled.div`
  width: 100%;
`
