import React, { FC } from 'react'
import { Background, Button, Container, Content, Overlay, Title } from './styled'

type Props = {
  button?: string
  buttonColor?: string
  buttonTextColor?: string
  backgroundImage?: string
  textColor?: string
  title?: string
  link?: string
  target?: boolean
}

export const CallToActionBanner: FC<Props> = ({
  button,
  buttonColor,
  buttonTextColor,
  backgroundImage,
  textColor,
  title,
  link,
  target,
}) => (
  <Container>
    <Background background={`url(${backgroundImage})`} />
    <Overlay />
    <Content>
      <Title color={textColor}>{title}</Title>
      {link && (
        <Button to={link} target={target ? 'blank' : undefined} color={buttonTextColor} backgroundColor={buttonColor}>
          {button}
        </Button>
      )}
    </Content>
  </Container>
)
