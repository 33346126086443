import { FC, useState, useEffect } from 'react'
import { getStatus } from 'services/wompi/getStatus'
import { TextBlock } from 'views/components/buildin/TextBlock'
import { LoadingModal } from 'views/components/LoadingModal'

const MESSAGES: any = {
  APPROVED: {
    title: 'Registro Exitoso',
    description: 'Gracias por su registro se le envió la información de su registro a su correo electrónico',
    label: 'Ingresar',
    path: 'login',
  },
  DECLINED: {
    title: 'Registro rechazado',
    description: 'Algo salió mal durante el pago, puede intentarlo de nuevo',
    label: 'Hazte complice',
    path: 'sign-up',
  },
  VOIDED: {
    title: 'Registro anulado',
    description: 'Algo salió mal durante el pago, puede intentarlo de nuevo',
    label: 'Hazte complice',
    path: 'sign-up',
  },
  ERROR: {
    title: 'Registro fallido',
    description: 'Algo salió mal durante el pago, puede intentarlo de nuevo',
    label: 'Hazte complice',
    path: 'sign-up',
  },
}

export const PaymentResult: FC = () => {
  const [state, setState] = useState('')

  const params: any = new URLSearchParams(window.location.search)
  const transacionID = params.get('id')

  useEffect(() => {
    getStatus(transacionID)
      .then(({ data }) => {
        setState(data?.data?.status || '')
      })
      .catch(() => {
        setState('ERROR')
      })
  }, [transacionID])

  if (!state) return <LoadingModal />

  return (
    <TextBlock
      message={MESSAGES[state].description}
      title={MESSAGES[state].title}
      linkLabel={MESSAGES[state].label}
      link={MESSAGES[state].path}
    />
  )
}
