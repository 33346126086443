import styled from 'styled-components'
import { background, BackgroundProps, color, ColorProps } from 'styled-system'

export const Card = styled.div<ColorProps>`
  padding: 36px 62px;
  color: black;
  background-color: white;
  width: 60%;
  min-height: 550px;
  ${color};
  @media screen and (max-width: 768px) {
    width: calc(90% - 42px);
    padding: 16px 21px 80px;
    margin: 55px 0 0;
    min-height: unset;
  }
  @media screen and (max-width: 450px) {
    padding-bottom: 48px;
  }
`

export const Container = styled.div<ColorProps & BackgroundProps>`
  display: flex;
  align-items: center;
  padding: 100px 10%;
  width: 80%;
  background-color: white;
  position: relative;
  ${color};
  ${background};
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (min-width: 1920px) {
    background-size: 100% 98%;
  }
  @media screen and (max-width: 1024px) {
    width: 90%;
    padding: 100px 5%;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    width: 100%;
    padding: 0 0 100px;
  }
`

export const Image = styled.img`
  width: 480px;
  height: 480px;
  position: absolute;
  right: 10%;
  bottom: 170px;
  @media screen and (min-width: 1920px) {
    bottom: 120px;
    width: 580px;
    height: 580px;
  }
  @media screen and (max-width: 1024px) {
    bottom: 200px;
    width: 400px;
    height: 400px;
    right: 5%;
  }
  @media screen and (max-width: 768px) {
    position: relative;
    bottom: unset;
    width: 100%;
    right: unset;
    height: 700px;
  }
  @media screen and (max-width: 450px) {
    height: 480px;
  }
`

export const Text = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: inherit;
  width: 75%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    font-size: 14px;
    line-height: 23px;
  }
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  color: inherit;
  margin: 0;
  margin-bottom: 5px;
  @media screen and (max-width: 768px) {
    margin-bottom: 16px;
  }
  @media screen and (max-width: 450px) {
    font-size: 38px;
    line-height: 46px;
  }
`
