import { FC, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { BiCheckbox, BiCheckboxChecked } from 'react-icons/bi'
import * as yup from 'yup'
import { MdCheck } from 'react-icons/md'
import Loading from './Loading'
import { Container, Row, Checkbox, Label, CustomLink, Button, TextInput, TextInputError, ErrorStatus } from './styled'

type FormValues = {
  name: string
  email: string
  checked: boolean
}

type FormErrorValues = {
  name?: string
  email?: string
  checked?: string
}

const initialValues: FormValues = {
  email: '',
  name: '',
  checked: false,
}

const initialErrors: FormErrorValues = {}

const validationSchema = yup.object({
  name: yup.string().required('Nombre es requerido'),
  email: yup.string().email().required('Correo electronico es requerido'),
  checked: yup.bool().oneOf([true]),
})

type Props = {
  errorMessage?: any
  button?: string
  buttonColor?: string
  buttonTextColor?: string
  title?: string
  text?: string
  textColor?: string
  name?: string
  email?: string
  url: string
  link?: {
    label: string
    path: string
    target?: boolean
  }
  subscribe: any
  status: string
}

const Form: FC<Props> = (props) => {
  const { link, button, buttonColor, buttonTextColor, textColor, name, email, subscribe, status, errorMessage } = props
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (status === 'success') {
      setSuccess(true)
    } else {
      setSuccess(false)
    }
  }, [status])

  const onSubmit = (values: FormValues) => {
    subscribe({
      NAME: values.name,
      EMAIL: values.email,
    })
  }

  const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    initialErrors,
    onSubmit,
    validationSchema,
    validateOnChange: false,
  })

  const submit = () => {
    handleSubmit()
  }

  const onSelect = () => {
    setFieldValue('checked', !values.checked)
  }

  return (
    <Container>
      <TextInput
        textColor={textColor}
        placeholder={name || 'Nombre'}
        name="name"
        value={values.name}
        onChange={handleChange}
        error={errors.name}
      />
      {errors.name && <TextInputError>{errors.name}</TextInputError>}
      <TextInput
        textColor={textColor}
        placeholder={email || 'Mail'}
        name="email"
        value={values.email}
        onChange={handleChange}
        error={errors.email}
      />
      {errors.email && <TextInputError>{errors.email}</TextInputError>}
      <Row>{status === 'error' ? <ErrorStatus dangerouslySetInnerHTML={{ __html: errorMessage }} /> : ''}</Row>
      <Row>
        <Checkbox onClick={onSelect}>
          {values.checked ? <BiCheckboxChecked /> : <BiCheckbox color={errors.checked ? '#f2392c' : 'inherit'} />}
        </Checkbox>
        <Label>
          {link && (
            <CustomLink to={link?.path} target={link?.target ? 'blank' : undefined}>
              {link?.label}
            </CustomLink>
          )}
        </Label>
      </Row>
      {success ? (
        <Button backgroundColor="#0eb52a" color={buttonTextColor} type="button" disabled>
          Suscrito <MdCheck style={{ marginLeft: '10px' }} />
        </Button>
      ) : (
        <Button
          backgroundColor={buttonColor}
          color={buttonTextColor}
          type="button"
          onClick={submit}
          disabled={status === 'sending'}
        >
          {status === 'sending' ? (
            <>
              Enviando
              <Loading color={buttonTextColor} />
            </>
          ) : (
            button || 'Suscribirme'
          )}
        </Button>
      )}
    </Container>
  )
}

export default Form
