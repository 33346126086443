import { ServiceResponse } from '@types'
import axios from 'axios'

export const getPodcast = async (): Promise<ServiceResponse> => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_TFM_API}/lnf-podcasts?state=Publicado`)

    return { ok: true, data }
  } catch (error) {
    return { ok: false, message: error.message }
  }
}
