import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'
import { Link, LinkProps } from 'react-router-dom'

export const Container = styled(Link)<ColorProps & LinkProps>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 5px;
  background-color: white;
  overflow: hidden;
  min-height: 112px;
  transition: all 0.2s ease-in-out;
  &:hover {
    -webkit-box-shadow: 0px 0px 15px 2px rgba(204, 204, 204, 1);
    -moz-box-shadow: 0px 0px 15px 2px rgba(204, 204, 204, 1);
    box-shadow: 0px 0px 15px 2px rgba(204, 204, 204, 1);
  }
  ${color};
  @media screen and (max-width: 450px) {
    position: relative;
    padding: 10px;
  }
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 16px 8px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`

export const Description = styled.div<ColorProps>`
  color: #373f41;
  flex: 1;
  margin-right: 20px;
  ${color};
  @media screen and (max-width: 450px) {
    margin-right: unset;
  }
`

export const Image = styled.img`
  width: 150px;
  height: 100%;
  object-fit: cover;
  @media screen and (max-width: 450px) {
    width: 100px;
    height: 100px;
  }
`

export const Label = styled.p`
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  opacity: 0.5;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 13px;
    line-height: 16px;
  }
`

export const DurationLabel = styled.div`
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: inherit;
  opacity: 0.5;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 13px;
    line-height: 16px;
  }
`

export const PlayButton = styled.button<ColorProps>`
  width: 60px;
  height: 60px;
  background: #fcd83a;
  color: black;
  font-size: 21px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  bottom: -30px;
  z-index: 1;
  border: none;
  .icon {
    margin-left: 4px;
  }
  & > div {
    margin-bottom: -3px;
  }
  ${color};
  @media screen and (max-width: 450px) {
    width: 40px;
    height: 40px;
    font-size: 16px;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
`

export const Subtitle = styled.h3`
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: inherit;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 13px;
    line-height: 13px;
  }
`

export const Title = styled.h2`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: inherit;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 15px;
    line-height: 15px;
  }
`
