/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react'
import { Item, SimpleDataItem } from './Item'
import { Container, Grid, TextContent, Text, Title, Image } from './styled'

type Props = {
  backgroundColor?: string
  backgroundImage?: string
  cardBackgroundColor?: string
  image?: string
  items?: SimpleDataItem[]
  text?: string
  textColor?: string
  title?: string
  link?: {
    target: boolean
    name: string
    url: string
  }
}

export const SimpleDataDisplay: FC<Props> = (props) => {
  const { backgroundColor, backgroundImage, cardBackgroundColor, items, text, textColor, title, image, link } = props
  return (
    <Container backgroundImage={`url(${backgroundImage})`} backgroundColor={backgroundColor}>
      <TextContent color={textColor}>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </TextContent>
      <Grid>
        <a href={link?.url}>{Boolean(image) && <Image src={image} />}</a>
        {items?.map((item) => (
          <Item key={item.title} backgroundColor={cardBackgroundColor} textColor={textColor} {...item} />
        ))}
      </Grid>
    </Container>
  )
}
