import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Article } from 'views/components/Article'
import { BlogPost } from 'views/components/BlogPost'
import { ChartDisplay } from 'views/components/ChartDisplay'
import { Highlight } from 'views/components/Highlight'
import { MediaGallery } from 'views/components/MediaGallery'
import { Overview } from 'views/components/Overview'
import { TabNavigation, Option } from 'views/components/TabNavigation'
import { useDispatch, useSelector } from 'react-redux'
import { generalSubscriptionSelector } from 'ducks/staticContents'
import { details } from 'services/podcast/details'
import { useHistory, useRouteMatch } from 'react-router-dom'
import get from 'lodash/get'
import { FavoriteEpisode, FAVORITE_EPISODE, rolsSelector, UNFAVORITE_EPISODE, userSelector } from 'ducks/user'
import { createRate } from 'services/rates/createRate'
import { getRateKey } from 'lib/getRateKey'
import useFavorite from 'hooks/useFavorite'
import { Helmet } from 'react-helmet'
import { Modal } from 'views/components/Modal'
import FeedbackForm from 'views/components/FeedbackForm'
import { isImmutable } from 'immutable'
import { Container, Content } from './styled'

const SOCIALS = {
  facebook: 'https://www.facebook.com',
  whatsapp: 'https://www.whatsapp.com',
  twitter: 'https://www.twitter.com',
}

const filterTabOptions = (data: any) => (item: any) => 'dataPath' in item ? Boolean(get(data, item.dataPath)) : true

const TAB_OPTIONS: Option[] = [
  {
    label: 'Episodios',
    value: 'episodios',
  },
  {
    label: 'Financiación',
    value: 'financiación',
  },
  {
    label: 'Contenido Adicional',
    value: 'blog',
    dataPath: 'blog',
  },
  {
    label: 'Agradecimientos',
    value: 'agradecimientos',
    dataPath: 'gratitudes',
  },
]

type RateOption = {
  id: string
  description: string
  position_order: number
}

type Rate = {
  id: string
  title: string
  subtitle: string
  question: string
  options: RateOption[]
}

const sortRates = (a: RateOption, b: RateOption) => {
  if (a.position_order < b.position_order) {
    return -1
  }
  if (a.position_order > b.position_order) {
    return 1
  }
  return 0
}

export const Podcast: FC = () => {
  const generalText = useSelector(generalSubscriptionSelector)
  const user = useSelector(userSelector)
  const [currentTab, setCurrentTab] = useState<Option>(TAB_OPTIONS[0])
  const [featuredIsFavorite, setFeaturedIsFavorite] = useState(false)
  const [showFeedback, setShowFeedback] = useState(false)
  const [selectedRate, setSelectedRate] = useState<Rate | null>(null)
  const [data, setData] = useState({
    rateTypes: [] as any,
    featured: {} as any,
    episodes: [] as any,
    podcast: {} as any,
  })
  const url: any = useRouteMatch('/podcast/:slugPodcast/episode/:slugEpisode')
  const { slugPodcast, slugEpisode } = get(url, 'params', {})
  const userState = useSelector(userSelector)
  const isAuthenticated = !!userState.get('token')
  const userId = userState.get('id')
  const { isFavorite, getFavoriteId } = useFavorite()
  const dispatch = useDispatch()
  const history = useHistory()
  const rols = useSelector(rolsSelector)
  const status = useRef(userState.get('status'))
  status.current = userState.get('status')

  useEffect(() => {
    const episodeId = data.featured.id
    const podcastId = data.podcast.id
    const episodeIsFavorite = isFavorite(episodeId, podcastId)
    setFeaturedIsFavorite(episodeIsFavorite)
  }, [data.featured.id, data.podcast.id, isFavorite])

  useEffect(() => {
    const safeRol = isImmutable(rols) ? rols.toJS() : rols
    details(slugPodcast, slugEpisode, safeRol, status).then((response) => {
      if (response.redirect) {
        history.push('/')
      } else {
        setData(response)
      }
    })
  }, [slugPodcast, setData, slugEpisode, history, rols])

  const renderContent = useCallback(() => {
    const currentTabValue = currentTab.value
    switch (currentTabValue) {
      case 'episodios':
        return <MediaGallery items={data.episodes || []} title="Episodios" />
      case 'agradecimientos':
        return <Article title="Agradecimientos" text={data.featured.gratitudes} />
      case 'financiación':
        return (
          <ChartDisplay
            charts={data.featured.finance}
            title="Financiación"
            subtitle="Este episodio fue posible gracias a:"
          />
        )
      case 'blog':
        return <BlogPost text={data?.featured?.blog} />
      default:
        return null
    }
  }, [currentTab.value, data])

  const submitRate = (comment: string, options: any[], rateObject?: any) => {
    if (data.featured.id && (rateObject || selectedRate)) {
      if (
        localStorage.getItem(getRateKey(data.podcast.id, data.featured.id, `${user.get('id')}`)) ===
        `${(rateObject || selectedRate).id}`
      ) {
        return
      }
      localStorage.setItem(
        getRateKey(data.podcast.id, data.featured.id, `${user.get('id')}`),
        (rateObject || selectedRate).id
      )
      const formatedOptions: {
        [key: string]: any
      } = {}
      options.forEach((opt: { label: string }) => {
        formatedOptions[opt.label] = true
      })

      createRate({
        rateID: (rateObject || selectedRate).id,
        podcastID: data.podcast.id,
        episodeID: data.featured.id,
        profileID: userId?.toString(),
        comment,
        options: formatedOptions,
      })
      setShowFeedback(false)
    }
  }

  const handleRate = (rateID: any) => {
    if (localStorage.getItem(getRateKey(data.podcast.id, data.featured.id, `${user.get('id')}`)) === `${rateID.id}`) {
      return
    }
    if (rateID.feedback) {
      setSelectedRate(rateID)
      setShowFeedback(true)
    } else {
      submitRate('', [], rateID)
      setSelectedRate(rateID)
    }
  }

  const handleFavorite = useCallback(() => {
    const episodeId = data.featured.id
    const podcastId = data.podcast.id
    if (featuredIsFavorite) {
      const favoriteId = getFavoriteId(episodeId, podcastId)
      const payload = {
        episode: episodeId,
        podcast: podcastId,
        favorite: favoriteId,
      }
      dispatch({ type: UNFAVORITE_EPISODE, payload })
    } else {
      const { title, duration } = data.featured
      const image = get(data.featured, ['thumbnail', 0, 'virtualPath'])
      const episodeObject: FavoriteEpisode = {
        id: '',
        episode: {
          // eslint-disable-next-line radix
          id: parseInt(episodeId || ''),
          title,
          duration: parseFloat(duration || ''),
          image,
        },
        podcast: {
          // eslint-disable-next-line radix
          id: parseInt(podcastId || ''),
          title: data?.podcast.title || '',
        },
      }
      const payload = {
        podcast: podcastId,
        episode: episodeId,
        data: episodeObject,
      }
      dispatch({ type: FAVORITE_EPISODE, payload })
    }
  }, [data.featured, data.podcast.id, data.podcast.title, dispatch, featuredIsFavorite, getFavoriteId])

  const handleFeedbackClose = () => {
    setShowFeedback(false)
  }

  return (
    <Container>
      <Helmet>
        <meta name="description" content={data.featured.description} />
        <meta property="og:title" content={data.featured.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={data.featured.description} />
        <meta property="og:image" content={data.featured.featureImage} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={data.featured.title} />
        <meta name="twitter:description" content={data.featured.description} />
        <meta name="twitter:image" content={data.featured.featureImage} />
      </Helmet>
      <Modal visible={showFeedback} onClose={handleFeedbackClose}>
        <div />
      </Modal>
      <Modal visible={showFeedback} noBackground>
        <FeedbackForm
          submitFeedback={submitRate}
          question={selectedRate?.question || ''}
          title={selectedRate?.title || '¿Qué nós faltó?'}
          subtitle={
            selectedRate?.subtitle ||
            'Tu opinión nos ayuda, cuentanos con toda confianza sobre eso que podemos mejorar:'
          }
          options={selectedRate?.options.sort(sortRates).map((opt) => ({
            label: opt.description,
            value: opt.id,
          }))}
        />
      </Modal>
      <Overview
        podcastID={data.podcast.id}
        profileID={`${user.get('id')}`}
        episodeID={`${data.featured.id}`}
        onRateClick={handleRate}
        link={data.featured.link}
        dangerHtml
        bgImage={data.podcast.cover_image ? data.podcast.cover_image[0].virtualPath : ''}
        image={data.featured.featureImage}
        title={data.featured.title}
        subtitle={data.podcast.title}
        rates={data.rateTypes}
        rateTitle="¿Cómo te pareció?"
        socials={SOCIALS}
        socialTitle="Compartir"
        labels={data.featured.credits}
        description={data.featured.description}
        showFav={isAuthenticated}
        handleFavorite={handleFavorite}
        favorite={featuredIsFavorite}
      />
      <TabNavigation
        options={TAB_OPTIONS.filter(filterTabOptions(data?.featured))}
        currentOption={currentTab}
        onPress={setCurrentTab}
      />
      <Content>{renderContent()}</Content>
      <Highlight
        button={generalText?.get('subscribeButtonText')}
        message={generalText?.get('subscribeText')}
        link={generalText?.get('newsletter_url')}
        buttonColor="black"
        buttonTextColor="white"
        backgroundImage="https://i.imgur.com/QXXBnKY.png"
        noBottomSpacing
      />
    </Container>
  )
}
