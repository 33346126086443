import { ServiceResponse } from '@types'
import axios from 'axios'

type Props = {
  complice: any
  content: any
  comment: string
}

export const createComment = async (payload: Props): Promise<ServiceResponse> => {
  try {
    await axios.post(
      `${process.env.REACT_APP_TFM_API}/flow/solution/a414a617-8f8a-4967-b1f5-72f55d7d2a1c/actions/eae89667-a2c6-42a3-a664-b4f894cb7e8f`,
      {
        data: {
          'e562244c-2f90-4a2d-ae1f-702091ee5ec1': payload.complice,
          '94c7f715-2449-4a74-bfe9-dec11aa2351b': payload.content,
          'a3b46944-8821-4b13-b944-bc965f332cb0': payload.comment,
        },
      }
    )

    return { ok: true }
  } catch (error) {
    return { ok: false, message: error.message }
  }
}
