import { signUpScreenTextsSelector } from 'ducks/staticContents'
import { membershipsSelector, LOGOUT, SET_PLANS, userSelector } from 'ducks/user'
import { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cancel as cancelSuscription } from 'services/subscription/cancel'
import { getActive } from 'services/subscription/getActive'
import { Modal } from 'views/components/Modal'
import { Plans } from 'views/components/Plans'
import { SmallBanner } from 'views/components/SmallBanner'
import { MainButton } from 'views/components/UI/Buttons'
import { LoadingModal } from 'views/components/LoadingModal'
import { Container, ModalContainer } from './styled'

const periods: any = {
  Once: 'Único pago',
  Weekly: 'Semana',
  Daily: 'Día',
  Monthly: 'Mes',
  Yearly: 'Año',
}

export const SubscriptionManagement: FC = () => {
  const [temporalMembershipID, setTemporal] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const userState = useSelector(userSelector)
  const dispatch = useDispatch()
  const texts = useSelector(signUpScreenTextsSelector)

  const memeberships = useSelector(membershipsSelector)
  const plans = useMemo(() => {
    return memeberships
      ?.filter((f) => f.status === 'Activo')
      ?.map(({ plan: { id, value, period, features, name, ...plan }, id: membershipID }) => ({
        id: `${id}`,
        value,
        unit: '$',
        period: periods[period],
        periodValue: period,
        name,
        membershipID,
        features: features.map((f: any) => f.feature.description),
        ...plan,
      }))
  }, [memeberships])

  const handleCancel = () => {
    const membershipID = temporalMembershipID
    if (!membershipID) return
    setLoading(true)
    const copyMemberships = [...(memeberships || [])]
    dispatch({ type: SET_PLANS, payload: memeberships?.filter((m) => m.id !== membershipID) })
    cancelSuscription(membershipID)
      .then(({ ok }) => {
        if (ok) {
          if (plans?.length === 1) {
            dispatch({ type: LOGOUT })
          }
        } else {
          dispatch({ type: SET_PLANS, payload: copyMemberships })
        }
      })
      .catch(() => {
        dispatch({ type: SET_PLANS, payload: copyMemberships })
      })
      .finally(() => {
        setTemporal(undefined)
        setLoading(false)
      })
  }

  const handleConfirmation = ({ membershipID }: any) => {
    setTemporal(membershipID)
  }

  const handleCloseModal = () => {
    setTemporal(undefined)
  }

  const email = userState.get('email')
  useEffect(() => {
    setLoading(true)
    getActive(email)
      .then(({ data: memberships }) => {
        dispatch({ type: SET_PLANS, payload: memberships })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [email, dispatch])

  return (
    <Container>
      <SmallBanner
        title="Gestionar suscripciones"
        text={texts.subtitleSignup}
        textColor="white"
        backgroundImage="https://i.imgur.com/6qizs2m.png"
      />

      <Plans
        cardButton="Cancelar"
        cardTextColor="#181059"
        cardBackgroundColor="#fff"
        featuredCardBackgroundColor="#FCD83A"
        featuredButtonColor="#131313"
        featuredButtonTextColor="#fff"
        title="Tus suscripciones"
        footerButton="Suscribirse"
        footerTitle="Buscar otras suscripciones disponibles"
        footerButtonLink="/add-subscription"
        plans={plans}
        selectPlan={handleConfirmation}
        disableSelectAction
        selectedPlan=""
        hideFilter
      />
      <Modal visible={!!temporalMembershipID} onClose={handleCloseModal}>
        <ModalContainer>
          <h3>Confirma esta acción</h3>
          <MainButton onClick={handleCancel}>
            {loading ? 'Cancelando...' : 'Quiero cancelar mi subscripción'}
          </MainButton>
        </ModalContainer>
      </Modal>
      {loading ? <LoadingModal message="Espera un momento por favor" /> : null}
    </Container>
  )
}
