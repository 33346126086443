import { SagaIterator } from '@redux-saga/core'
import * as TYPES from 'ducks/staticContents/actionTypes'
import { fromJS } from 'immutable'
import { all, put, takeLatest, call } from 'redux-saga/effects'
import get from 'lodash/get'
import { getAll } from 'services/staticResources/getAll'
import { headerMapper } from 'lib/propsParser/components/headerMenu'
import { footerMapper } from 'lib/propsParser/components/footer'
import { contentRichImageBlockMapper } from 'lib/propsParser/components/ContentRichImageBlock'
import { tGalleryMapper } from 'lib/propsParser/components/TGallery'
import { newsletterMapper } from 'lib/propsParser/components/newsletter'
import { inlineCardsMapper } from 'lib/propsParser/components/inlineCards'
import { componentsSlugs as slugs } from 'lib/constants/componentsSlugs'
import { componentsMapper } from 'lib/propsParser/components/componentsMapper'

const mappers = {
  [slugs.MAIN_SLIDER]: contentRichImageBlockMapper,
  [slugs.STORE]: contentRichImageBlockMapper,
  [slugs.ABOUT]: tGalleryMapper,
  [slugs.NEWSLETTER]: newsletterMapper,
  [slugs.ADVANTAGES]: inlineCardsMapper,
  [slugs.SUBSCRIPTION]: contentRichImageBlockMapper,
}

function* loadResourceSaga(): SagaIterator {
  yield put(TYPES.GET_RESOURCES.request())

  const { ok, data, error } = yield call(getAll)

  if (!ok) {
    yield put(TYPES.GET_RESOURCES.failure(error))
  }

  const homeComponents: any = {}

  if (Array.isArray(data.homeComponents)) {
    Object.values(slugs).forEach((cd) => {
      const cmp = data.homeComponents.find((item: any) => get(item, ['component', 'payload', 'slug']) === cd)
      homeComponents[cd] = mappers[cd](get(cmp, ['component', 'payload'], {}))
    })
  }

  homeComponents[slugs.ADVANTAGES].items = homeComponents[slugs.ADVANTAGES].items.map((item: any) => ({
    button: item.link_label,
    image: item.image,
    title: item.title,
    message: item.description,
    link: item.link,
  }))

  const aboutUsValues = componentsMapper(data.aboutUsComponents, ['manifiesto', 'gestion'])
  const contactUsValues = componentsMapper(data.contactUsComponents, ['form_title', 'complice'])
  const newsletterValues = componentsMapper(data.newsletterComponents, ['form_title', 'complice'])
  const loginValues = componentsMapper(data.loginComponents, ['form_title', 'complice'])

  yield put(
    TYPES.GET_RESOURCES.success(
      fromJS({
        header: headerMapper(data.header),
        footer: footerMapper(data.footer),
        homeComponents,
        newsLetterComponents: {
          formTitle: newsletterValues.form_title,
          complice: contentRichImageBlockMapper(newsletterValues.complice),
        },
        loginComponents: {
          formTitle: loginValues.form_title,
          complice: contentRichImageBlockMapper(loginValues.complice),
        },
        contactUsComponents: {
          formTitle: contactUsValues.form_title,
          complice: contentRichImageBlockMapper(contactUsValues.complice),
        },
        aboutUsComponents: {
          manifest: contentRichImageBlockMapper(aboutUsValues.manifiesto),
          management: contentRichImageBlockMapper(aboutUsValues.gestion),
        },
        singupScreen: data.singupScreen,
        team: data.team
          ?.map((item: any) => ({ ...item, image: get(item, ['image', 0, 'virtualPath'], '') }))
          ?.sort((a: any, b: any) => {
            if (Number(a.order || 0) < Number(b.order || 0)) return -1
            if (Number(a.order || 0) > Number(b.order || 0)) return 1
            return 0
          }),
        userBanner: data.userBanner,
        generalSubscription: data.generalSubscription,
      })
    )
  )

  yield put(TYPES.GET_RESOURCES.fulfill())
}

export function* staticContentsSagas(): SagaIterator {
  yield all([takeLatest(TYPES.GET_RESOURCES.TRIGGER, loadResourceSaga)])
}
