/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, useEffect, useRef, useState } from 'react'
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs'
import { Item, ShowcaseItem } from './Item'
import { Container, Content, Grid, NavigationContainer, NavigationButton, Subtitle, Title } from './styled'
import useScrollPosition from './useScrollPosition'
import useMediaQuery from './useMediaQuery'

type Props = {
  backgroundColor?: string // Background color to be displayed
  backgroundImage?: string // Background Image URL
  headerTextColor?: string // Color to be used in Title and Subtitle
  items?: ShowcaseItem[] // Item data to be displayed as cards
  textColor?: string // Color to be used in the items cards
  title?: string // Title to be displayed
  subtitle?: string // Subtitle to be displayed
  dangerHtml?: boolean
}

const PAGE_SIZE = 6

const generateContentByPage = (items: ShowcaseItem[], pageSize?: number): ShowcaseItem[][] => {
  const itemsPerPage = pageSize || PAGE_SIZE
  const pages = items.length / itemsPerPage
  const contentByPages = []
  for (let i = 0; i < pages; i++) {
    const page = items.slice(i * itemsPerPage, i * itemsPerPage + itemsPerPage)
    contentByPages.push(page)
  }
  return contentByPages
}

export const PaginatedShowcase: FC<Props> = (props) => {
  const { backgroundColor, backgroundImage, headerTextColor, items, textColor, title, subtitle, dangerHtml } = props
  const [pages, setPages] = useState<ShowcaseItem[][]>([])
  const collectionRef = useRef<HTMLDivElement>(null)
  const { onEnd, onStart, scrollBack, scrollForward } = useScrollPosition(collectionRef)
  const matches = useMediaQuery('(max-width: 1024px)')
  const mobileMatches = useMediaQuery('(max-width: 450px)')

  useEffect(() => {
    if (items) {
      if (mobileMatches) {
        const contentByPage = generateContentByPage(items, 1)
        setPages(contentByPage)
      } else {
        const contentByPage = generateContentByPage(items)
        setPages(contentByPage)
      }
    } else {
      setPages([])
    }
  }, [items, mobileMatches])

  const renderPage = (pageItems: ShowcaseItem[], index: number) => {
    return (
      <Grid key={index}>
        {pageItems?.map((item) => (
          <Item dangerHtml={dangerHtml} key={item.link} {...item} textColor={textColor} />
        ))}
      </Grid>
    )
  }

  return (
    <Container backgroundColor={backgroundColor} background={`url(${backgroundImage})`}>
      <Title color={headerTextColor}>{title}</Title>
      <Subtitle color={headerTextColor}>{subtitle}</Subtitle>
      <Content ref={collectionRef}>{pages.map(renderPage)}</Content>
      {!matches && (
        <NavigationContainer>
          <NavigationButton backgroundColor={onStart ? '#9E9E9E' : textColor} side="left" onClick={scrollBack}>
            <BsArrowLeftShort />
          </NavigationButton>
          <NavigationButton backgroundColor={onEnd ? '#9e9e9e' : textColor} side="right" onClick={scrollForward}>
            <BsArrowRightShort />
          </NavigationButton>
        </NavigationContainer>
      )}
    </Container>
  )
}
