import { singleFile } from 'lib/propsParser/singleFile'
import React, { FC } from 'react'
import { Promote } from 'views/components/Promote'

export const ImageTextBlock: FC<any> = ({ title, subtitle, content, background, image, link_label, link, variant }) => (
  <Promote
    variant={variant}
    backgroundImage={singleFile(background)}
    message={content}
    subtitle={subtitle}
    title={title}
    button={link_label}
    link={link}
    image={singleFile(image)}
  />
)
